//* External Imports
import React, { useEffect, useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { globalErrorHandler } from "../axios/globalErrorHandler"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

type Props = {
    examType: boolean
    writingText: string,
    id_user: number,
    setWritingText: React.Dispatch<React.SetStateAction<string>>
}

/** Generic Text Editor */
const TextArea = (props: Props) => {
    const {t} = useTranslation("translation", {keyPrefix: "textArea"})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [language, setLanguage] = useState<string>("")
    const textHandler = (value: string) => {
        props.setWritingText(props.writingText + value)
    }
    useEffect(() => {
        axios.get<string>(`${process.env.REACT_APP_API_URL}/language/${props.id_user}`).then((response) => {
            setLanguage(response.data)
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])
    return (
        <TextContainer onPaste={(e) => {
            e.preventDefault()
            return false;
        }}>
            {language === "Español" &&
                <ButtonContainer>
                    <div>
                        <Button onClick={() => textHandler("á")}>á</Button>
                        <Button onClick={() => textHandler("é")}>é</Button>
                        <Button onClick={() => textHandler("í")}>í</Button>
                        <Button onClick={() => textHandler("ó")}>ó</Button>
                        <Button onClick={() => textHandler("ú")}>ú</Button>
                        <Button onClick={() => textHandler("ü")}>ü</Button>
                        <Button onClick={() => textHandler("ñ")}>ñ</Button>
                    </div>
                    <div>
                        <Button onClick={() => textHandler("Á")}>Á</Button>
                        <Button onClick={() => textHandler("É")}>É</Button>
                        <Button onClick={() => textHandler("Í")}>Í</Button>
                        <Button onClick={() => textHandler("Ó")}>Ó</Button>
                        <Button onClick={() => textHandler("Ú")}>Ú</Button>
                        <Button onClick={() => textHandler("Ü")}>Ü</Button>
                        <Button onClick={() => textHandler("Ñ")}>Ñ</Button>
                    </div>
                    <div>
                        <Button onClick={() => textHandler("¡")}>¡</Button>
                        <Button onClick={() => textHandler("¿")}>¿</Button>
                    </div>
                </ButtonContainer>
            }
            {props.examType && <WordCount>{t("Words")}: <span>{props.writingText.length > 0 ? props.writingText.split(" ").length : 0}</span></WordCount>}
            <TextAreaStyle value={props.writingText} placeholder={t("Text") ?? "Text"} onChange={(e) => props.setWritingText(e.target.value)} />
        </TextContainer>
    )
}

export default TextArea

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 95%;
    gap: 30px;
`
const Button = styled.button`
    background: transparent;
    border: 1px solid transparent;
    width: fit-content;
    height: fit-content;
    font-size: 17px;
    margin-right: 2px;
    &:hover {
        border: 1px solid ${props => props.theme.langooRed};
        border-radius: 5px;
        box-shadow: ${props => props.theme.basicBoxShadow};
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 20px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    width: 100%;
    height: 100%;
    min-height: 300px;
    margin-top: 30px;
    padding: 10px;
    gap: 10px;
    font-family: 'Familjen Grotesk', sans-serif;
    box-sizing: border-box;
`
const WordCount = styled.div`
    text-align: left;
    width: 95%;
    font-weight: 700;
    font-size: 17px;
    span {
        color: ${props => props.theme.langooRed};
    }
`
const TextAreaStyle = styled.textarea`
    font-family: 'Familjen Grotesk', sans-serif;
    background: #F6F7F8;
    width: 95%;
    height: 80%;
    min-height: 240px;
    resize: none;
    margin-top: 10px;
    border-radius: 20px;
    border: transparent;
    font-weight: 700;
    padding: 15px;
    box-sizing: border-box;
    &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: transparent;
    }
`