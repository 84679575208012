//* External Imports
import React from "react"
import styled from "styled-components"

type AudioSlideProps = {
	max: number;
	value: number;
	onChange: (value: string) => void;
	onScrubEnd: () => void
}

const AudioSlide = (props: AudioSlideProps) => (
	<Input
		type="range"
		max={props.max ? props.max : `${props.max}`}
		min={0}
		value={props.value}
		step={1}
		onChange={(e) => props.onChange(e.target.value)}
		onMouseUp={props.onScrubEnd}
		onKeyUp={props.onScrubEnd}
	/>
)

export default AudioSlide

const Input = styled.input`
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 48%;
    margin: 0;
    padding: 0;
    height: 19px;
    margin: auto;
    float: left;
    outline: none;
    background: transparent;
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        //background: linear-gradient(to right, rgba(0, 125, 181, 0.6) var(--buffered-width), rgba(0, 125, 181, 0.2) var(--buffered-width));
        background: ${props => props.theme.langooLightBlueRgb};
    }
    &::before {
        position: absolute;
        content: "";
        top: 8px;
        left: 0;
        width: ${props => props.width};
        height: 3px;
        background-color: #b50000;
        cursor: pointer;
    }
    &::-webkit-slider-thumb {
        position: relative;
        -webkit-appearance: none;
        box-sizing: content-box;
        border: 1px solid ${props => props.theme.langooBlue};
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: ${props => props.theme.langooBlue};
        cursor: pointer;
        margin: -7px 0 0 0;
    }
    &:active::-webkit-slider-thumb {
        transform: scale(1.2);
        background: ${props => props.theme.langooBlue};
    }
    &::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        //background: linear-gradient(to right, rgba(0, 125, 181, 0.6) 100, rgba(0, 125, 181, 0.2) 100);
        //background: linear-gradient(to right, rgba(0, 125, 181, 0.6) var(--buffered-width), rgba(0, 125, 181, 0.2) var(--buffered-width));
    }
    &::-moz-range-progress {
        background-color: #007db5;

    }
    &::-moz-focus-outer {
        border: 0;
    }
    &::-moz-range-thumb {
      box-sizing: content-box;
      border: 1px solid #007db5;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
    }
    &:active::-moz-range-thumb {
      transform: scale(1.2);
      background: #007db5;
    }
    &::-ms-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: transparent;
      border: solid transparent;
      color: transparent;
    }
    &::-ms-fill-lower {
      background-color: #007db5;
    }
    &::-ms-fill-upper {
      //background: linear-gradient(to right, rgba(0, 125, 181, 0.6) 100, rgba(0, 125, 181, 0.2) 100);
      //background: linear-gradient(to right, rgba(0, 125, 181, 0.6) var(--buffered-width), rgba(0, 125, 181, 0.2) var(--buffered-width));
    }
    &::-ms-thumb {
      box-sizing: content-box;
      border: 1px solid #007db5;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
    }
    &:active::-ms-thumb {
      transform: scale(1.2);
      background: #007db5;
    }
`
