//* External imports
import React from "react";
import styled from "styled-components";

//? Internal Imports
//? JSX Components
import { Desktop, Mobile, Tablet } from "../responsive/mediaQueriesComponents";

//? Very simple progress bar.

type Props = {
    completeTasks: number;
    color: string;
}

/** Generic JSX Progress Bar */
const ProgressBar = (props: Props): JSX.Element => (
    <>
        <Desktop>
            <ContainerDiv width={"98%"}>
                <FillerDiv completeTask={props.completeTasks} color={props.color}>
                    <span>{`${props.completeTasks}%`}</span>
                </FillerDiv>
            </ContainerDiv>
        </Desktop>
        <Mobile>
            <ContainerDiv width={"100%"}>
                <FillerDiv completeTask={props.completeTasks} color={props.color}>
                    <span>{`${props.completeTasks}%`}</span>
                </FillerDiv>
            </ContainerDiv>
        </Mobile>
        <Tablet>
            <ContainerDiv width={"100%"}>
                <FillerDiv completeTask={props.completeTasks} color={props.color}>
                    <span>{`${props.completeTasks}%`}</span>
                </FillerDiv>
            </ContainerDiv>
        </Tablet>
    </>
)

export default ProgressBar;

const ContainerDiv = styled.div<{width: string}>`
    height: 20;
    width: ${props => props.width};
    background-color: #e0e0de;
    border-radius: 20px;
    margin: 5px;
`
const FillerDiv = styled.div<{ completeTask: number, color: string }>`
    height: 100%;
    width: ${props => props.completeTask}%;
    background-color: ${props => props.color};
    border-radius: 20px;
    color: white;
    animation: progress 3s; 
    @keyframes progress {
        0% {
            width: 0%;
        }
        ${props => props.completeTask} {
            width: ${props => props.completeTask}%;
            background-color: ${props => props.color};
        }
    }
`
