import React from 'react';
import './App.css';
import Login from './pages/Login';
import { persistor } from "./redux/configureStorage"
import { PersistGate } from 'redux-persist/integration/react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
//* External Imports
import { Route, Routes } from 'react-router-dom';

// Type Imports
import { StorageInfo } from './redux/types/ReduxTypes';

//? Internal Imports
//? JSX Components
import ResetPassword from './pages/ResetPassword';
import PrivateZone from './pages/privateZone';
//? Styled-Components
import { langooTheme } from './styles/langooTheme';
import ErrorComponent from './components/ErrorComponent';


function App() {
	const storedInfo: StorageInfo = useSelector<StorageInfo, StorageInfo>((state: StorageInfo) => state)
	return (
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={langooTheme}>
				<div className="App">
					<Routes>
						<Route path="*" element={<div>nono</div>}/>
						<Route path='/error' element={<ErrorComponent/>}/>
						{((storedInfo.logInfo.stayLogged) || (storedInfo.logInfo.isLogged)) ?
							<Route path="/" element={<PrivateZone authToken={storedInfo.authToken!}/>}>
								<Route path="/landing" element={<div>a</div>}/>
								<Route path="/dashboard" element={<div>a</div>}/>
								<Route path="/classroom" element={<div>a</div>}/>
								<Route path="/mockexam" element={<div>a</div>}/>
								<Route path="/studymaterial" element={<div>a</div>}/>
								<Route path="/proficiencyresults" element={<div>a</div>}/>
								<Route path="/proficiencytest" element={<div>a</div>}/>
								<Route path="/paymentPlatform" element={<div>a</div>}/>
								<Route path="/profile" element={<div>a</div>}/>
							</Route>
							:
							<>
								<Route path="/" element={<Login/>}/>
								<Route path="/resetpassword/:token" element={<ResetPassword/>}/>
								<Route path="/ohno" element={<div>nono</div>}/>
							</>
							
						}
					</Routes>
				</div>
			</ThemeProvider>
		</PersistGate>
	);
}

export default App;
