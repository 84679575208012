//* External imports
import React from "react";

//? Internal Imports
//? Styled-components
import { Button } from "../../styles/buttons/Button";

const TryAgain = (props: {onClick: () => void, text?: string}) => <Button onClick={props.onClick}> {props.text ? props.text : "Retry"} </Button>

export default TryAgain
