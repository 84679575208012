//* External imports
import React from "react";
import { useTranslation } from "react-i18next";
import { VictoryAxis, VictoryChart, VictoryGroup, VictoryLine, VictoryScatter} from "victory";

//? Line chart template. Simple line chart.

type Props = {
    data: Array<{
        x: number,
        y: number
    }>
    color: string;
    timeSelector?: boolean
}

const LineChart = (props: Props) => {
    const data: {x: number, y: number}[] = props.data && props.data.length > 0 ? props.data : [{x:1, y:0}]
    const {t} = useTranslation("translation", {keyPrefix: "lineCharts"})
    return(
        <svg viewBox="0 0 1000 250">
            <VictoryChart
                standalone={false}
                width={1000}
                height={250}
            >
                <VictoryAxis
                    dependentAxis
                    domain={[10, 100]}
                    tickValues={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    tickFormat={(elem) => `${elem}%`}
                />
                <VictoryAxis
                    domain={[data[0].x,data[data.length-1].x]}
                    tickValues={data.map((elem) => elem.x).filter((elem)=> Number.isInteger(elem))}
                    tickFormat={data.filter((elem) => Number.isInteger(elem.x)).map((elem2) => `${props.timeSelector ? t("Month") : t("Week")} ${elem2.x}`)}
                
                />
                <VictoryGroup
                    data={data}
                >
                    <VictoryLine
                        style={{data: {stroke: props.color, strokeWidth: 5 ,fontFamily: "'Familjen Grotesk', sans-serif"}}}
                    />
                    <VictoryScatter
                        style={{data: {fill: ({datum}) => Number.isInteger(datum.x) ? props.color : "transparent"}}}
                        size={7}
                    />
                </VictoryGroup>
            </VictoryChart>
        </svg>
    )
}

export default LineChart;