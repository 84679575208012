//* External imports
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Types Imports
import { CompletedFullExams, UserType } from "../types/UserTypes";
import { ExamType } from "../types/GeneralTypes";
import { CompletedExercise } from "../types/UserTypes";
import { ExercisesType, QuestionSizeType } from "../types/GeneralTypes";

//? Internal Imports
import MockExamReport from "../components/reports/mockExamReports";
import ExamTemplate from "../components/examTemplate";
//? Hooks
import { setMainStates } from "../redux/hook/useReducer";
//? Methods
import { isMobile } from "../responsive/mediaQueriesStates";
//? Utils
import { changeBooleanArrayValueByIndex } from "../utils/changeBooleanArrayValueByIndex";
//? Styled-Componentes
import { SimpleTitle } from "../styles/SimpleTitle";
import Loader from "../components/loader";
import { globalErrorHandler } from "../axios/globalErrorHandler";
import { useNavigate } from "react-router-dom";

//? Main component for Study Plan, competence test subdivision.

Modal.setAppElement("body")

type Props = {
    user: UserType;
    exam: ExamType[]
    studenExercise: CompletedExercise[]
    exercises: ExercisesType
    mainColor: string
    aux: boolean;
    screenState: boolean[]
    setArrayStatesOnClick: (index: number, arr?: boolean[], setState?: React.Dispatch<React.SetStateAction<boolean[]>>, setAdicional?: React.Dispatch<React.SetStateAction<boolean>>) => void
}
type ExamStatus = {
    chart?: {
        data: Array<number>
        pointsArray?: Array<number>
        xText?: Array<string>
        levels?: Array<string>
    }
    info: {
        testDate: Date
        timeSpent: number
        passed?: string
    }
}

const ModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "60%",
        height: "60%",
        padding: "40px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderBottomRightRadius: "50px 50px",
        borderTopLeftRadius: "50px 50px",
        backgroundColor: "white",
        border: "2px solid #A2D9E7",
    },
    overlay: {
        zIndex: 1000
    }
}

const MockExam = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileState = isMobile()
    const { t } = useTranslation("translation", { keyPrefix: "mockExam" })
    const [competencyTest, setCompetencyTest] = useState<boolean>(false)
    const [mockExamsStates, setMockExamsStates] = useState<boolean[]>(new Array(props.exam.filter((elem) => elem.id_exam !== 8).length).fill(false))
    const [questions, setQuestions] = useState<QuestionSizeType>();
    const [modal, setModal] = useState<boolean>(false)
    const [chartInfo, setChartInfo] = useState<ExamStatus>()
    const [completedMock, setCompletedMock] = useState<CompletedFullExams>()
    const [loading, setLoading] = useState<boolean>(true)
    const maxTimes: number[] = (props.exam.map((elem) => elem.time_listening + elem.time_reading + elem.time_speaking + elem.time_writing))

    //Use for Modal component
    /** Changes modal state to true */
    const openModal = () => {
        setModal(true)
    }
    /** Changes modal state to false */
    const closeModal = () => {
        setModal(false)
    }

    /** Change an specific element or all the elements from the mockExamStates state */
    const setMockExamStatesValues = (index?: number) => {
        setMockExamsStates(
            mockExamsStates.map((_, i) => {
                if ((index !== undefined) && (index === i)) {
                    return true;
                }
                return false;
            })
        )
    }
    useEffect(() => {
        dispatch(setMainStates(changeBooleanArrayValueByIndex(props.screenState, 3)))
        axios.post<CompletedFullExams>(`${process.env.REACT_APP_API_URL}/exams/completed`, { userId: props.user.id, examType: "Mock" }).then((response) => {
            setCompletedMock(response.data)
            setLoading(false)
        }).catch(() => globalErrorHandler(dispatch, navigate))
        closeModal();
    }, [])

    useEffect(() => {
        const stateTrue = mockExamsStates.find((elem) => elem)
        if (stateTrue) {
            setLoading(true)
            axios.get<ExamStatus>(`${process.env.REACT_APP_API_URL}/mock/${props.exam[mockExamsStates.indexOf(true)].id_exam}/${props.user.id}`).then((response) => {
                if (!response.data.info.timeSpent) {
                    if (mockExamsStates.findIndex((elem) => elem === true) !== -1) {
                        axios.post<QuestionSizeType>(`${process.env.REACT_APP_API_URL}/questions`, {
                            id: props.exam[mockExamsStates.indexOf(true)].id_exam
                        }).then((response) => {
                            setQuestions(response.data)
                            setLoading(false)
                        }).catch(() => globalErrorHandler(dispatch, navigate))
                    }
                } else {
                    setChartInfo(response.data)
                    setLoading(false)
                    setCompetencyTest(true)
                }
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }
    }, [mockExamsStates])
    return (
        <MainDiv>
            <Loader loading={loading} />
            <Modal
                isOpen={modal}
                onRequestClose={closeModal}
                style={ModalStyle}
                contentLabel="stop"
            >
                <ModalDiv>
                    {t("Before you continue, make sure that you have practised the different tasks sufficiently. With the tasks on the platform you are already practising exam exercises.")} <br /><br />
                    {t("Are you sure you want to take the mock exam now?")} <br /><br />
                    {t("You have not yet practised")} <br /><br />
                    {props.exercises.flat(1).filter((elem) => !props.studenExercise.find((elem2) => elem2.id_exercise === elem.id_exercise)) &&
                        <div>
                            <DivTable>
                                {props.exercises.map((elem, index) => {
                                    const completedParts = props.exercises.map((elem2) => elem2.filter((elem3) => props.studenExercise.find((elem4) => elem3.id_exercise === elem4.id_exercise))).map((elem2) =>
                                        elem2.map((elem3) => elem3.task_part).filter((v, i, a) => a.indexOf(v) === i))
                                    const allParts = props.exercises[props.exercises.map((elem2) => elem2.length).indexOf(Math.max(...props.exercises.map((elem2) => elem2.length)))].map((_, i) =>
                                        elem[i] ? elem[i].task_part : 0
                                    ).filter((v, i, a) => a.indexOf(v) === i).filter((elem3) => !completedParts[index].find((elem4) => elem4 === elem3))
                                    return (
                                        <DivTableColumn>
                                            <DivTableTh>{t(elem[0].exercise_type)}</DivTableTh>
                                            <DivTableTd>
                                                {allParts.map((elem4) => <div>{elem4 === 0 ? "" : `${t("Part")} ${elem4}`}</div>)}
                                            </DivTableTd>
                                        </DivTableColumn>
                                    )
                                })}
                            </DivTable>
                            <br /><br />
                        </div>
                    }
                    <ModalButtonContainer>
                        <button onClick={() => [closeModal(), setCompetencyTest(true)]}>{t("Take exam")}</button>
                        <button onClick={() => [closeModal(), props.setArrayStatesOnClick(2)]}>{t("Take me back")}</button>
                    </ModalButtonContainer>
                </ModalDiv>
            </Modal>
            {!competencyTest &&
                <>
                    <ClassroomDiv>
                        <SimpleTitle>{t("Mock exams")}</SimpleTitle>
                        <span>
                            {t("Get ready to practice like a pro with our super tool!")}
                            <br /><br />
                            {t("Designed to give you a realistic exam experience with timed questions, it's the perfect way to prepare for your test. Your tutor will give you the green light once you are ready to do it, just wait for it! And trust in us, it'll be totally worth it!")}
                            <br /><br />
                            {t("But trust us, it'll be totally worth the wait!")}
                        </span>
                    </ClassroomDiv>
                    <ButtonContainer>
                        <span>{t("Choose a Mock")}</span>
                        <div>
                            {mockExamsStates.map((_, i) => <MockButton state={(completedMock !== undefined && completedMock.data.find((elem) => props.exam[i].id_exam === elem.id) !== undefined)} onClick={() => setMockExamStatesValues(i)} key={i}>
                                {(completedMock !== undefined && completedMock.data.find((elem) => props.exam[i].id_exam === elem.id) !== undefined) ?
                                    `${t("Mock Exam Result")} ${i + 1}`
                                    :
                                    `${t("Mock Exam")} ${i + 1}`
                                }
                            </MockButton>)}
                        </div>
                    </ButtonContainer>
                    {completedMock && (mockExamsStates.find((elem) => elem) && !completedMock.data.find((elem) => props.exam[mockExamsStates.indexOf(true)].id_exam === elem.id)) &&
                        <CardsContainer state={isMobileState}>
                            <CardBackground>
                                <ContentCardDiv >
                                    <strong>{t("Put yourself to the test")}</strong>
                                    <br /><br />
                                    <DecorationLine />
                                    <div>{t("Now it’s time for you to participate in real-life conditions. To get the most out of this tool, follow these instructions")}:</div>
                                    <Ul>
                                        <li>{`${t("The exam lasts approximately")} ${(maxTimes[mockExamsStates.indexOf(true)] / 60).toFixed(1)} ${t("hours")}. ${t("Make sure you have enough time to do it in one go.")}`}</li>
                                        <li>{t("If you choose to exit the test without completing it, you will be marked as “fail” and will have to repeat the entire test later.")}</li>
                                        <li>{t("Once you start this test you will have to finish it. So we recommend that you find a quiet and comfortable place to take it, with a stable internet connection.")}</li>
                                        <li>{t("At the beginning of each test you can read the instructions. Pay close attention to the time and to what you are asked for.")}</li>
                                        <li>{t("The timer will be visible at all times, allowing you to monitor how much time you have used and how much time is left.")}</li>
                                        <li>
                                            {t("Durning the test you will receive two alerts")}:
                                            <ul>
                                                <li>{t("The first one will appear once you have used half of the available time.")}</li>
                                                <li>{t("The second one when you have 10 minutes left.")}</li>
                                            </ul>
                                        </li>
                                        <li>{t("As soon as the available time is up, the test will take you to the next skill.")}</li>
                                        <li>{t("You can go to the next skill without waiting for the time to be over.")}</li>
                                        <li>
                                            {t("The order of the tests is")}:
                                            <ul>
                                                <li>{t("Reading")}</li>
                                                <li>{t("Listening")}</li>
                                                <li>{t("Writing")}</li>
                                                <li>{t("Speaking")}</li>
                                            </ul>
                                        </li>
                                        <li>{t("When you finish the test, press the FINISH button.")}</li>
                                    </Ul>
                                    <div>{t("This test will measure your language proficiency, time management skills, and knowledge of the exam. After completion, your teacher will review the results; you'll see a dashboard with your score.")}</div>
                                </ContentCardDiv>
                            </CardBackground>
                            <CardBackground style={{ height: "100%" }}>
                                <ContentCardDiv>
                                    <strong>{t("Mock Exam")}</strong>
                                    <br /><br />
                                    <DecorationLine />
                                    <div>
                                        {`${t("In this mock exam we will evaluate your ability in the four skills assessed in the")} ${t(props.user.certification.toLowerCase())}`}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}><CardButton onClick={() => openModal()}>{t("Let's start")}</CardButton></div>
                                </ContentCardDiv>
                            </CardBackground>
                        </CardsContainer>
                    }
                </>
            }
            {competencyTest && questions &&
                <div style={{ width: "80%" }}>
                    <ExamTemplate exam={props.exam[mockExamsStates.findIndex((elem) => elem === true)]} mainColor={props.mainColor} userData={props.user} exerciseQuestions={questions} />
                </div>
            }
            {chartInfo && competencyTest &&
                <MockExamReport
                    userData={props.user}
                    examStatus={chartInfo}
                    goBack={competencyTest}
                    setGoBack={setCompetencyTest}
                />
            }
        </MainDiv>
    )
}

export default MockExam;

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #F5F5F5;
    padding-bottom: 60px;
    gap: 20px;
`
const ModalDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 17px;
`
const ClassroomDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 80%;
    height: auto;
    gap: 10px;
    span {
        font-size: 18px;
        font-weight: 700;
    }
    margin-bottom: 20px;
`
const ModalButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    button {
        width: 150px;
        height: 45px;
        background: ${props => props.theme.langooBlue};
        border: ${props => props.theme.langooBlue};
        color: white;
        box-shadow: ${props => props.theme.basicBoxShadow};
        border-radius: 8px;
        margin: 10px;
        font-size: 17px;
        padding: 5px;
        &:hover {
            background: #B2AAAA;
        }
    }
`
const Ul = styled.ul`
    li {
        margin-top: 5px;
    }
`
const DivTable = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`
const DivTableColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const DivTableTd = styled.div`
`
const DivTableTh = styled.div`
    font-weight: 700;
    
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: 80%;
    background: white;
    box-shadow: ${props => props.theme.basicBoxShadow};
    border-radius: 8px; 
    margin-bottom: 40px;
    padding-bottom: 20px;
    padding-top: 5px;
    span {
        width: 94.5%;
        align-self: center;
        text-align: left;
        font-size: 17px;
        font-weight: 600;
        padding: 5px;
        margin-bottom: 5px;
    }
    div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
`
const MockButton = styled.button<{ state: boolean }>`
    width: 28%;
    height: 45px;
    background: ${props => props.state ? "#0b8eab8d" : props.theme.langooBlue};
    border: ${props => props.state ? "#0b8eab8d" : props.theme.langooBlue};
    color: white;
    box-shadow: ${props => props.theme.basicBoxShadow};
    border-radius: 8px; 
`
const CardsContainer = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    gap: 20px;
    width: 90%;
    padding: 0 5%;
    box-sizing: border-box;
    height: 70%;
`
const CardBackground = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: ${props => props.theme.basicBoxShadow};
    border-radius: 10px;
    box-sizing: border-box;
`
const ContentCardDiv = styled.div`
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: left;
    box-sizing:border-box;
`
const CardButton = styled.button`
    color: white;
    background: ${props => props.theme.langooBlue};
    border: 1px solid ${props => props.theme.langooBlue};
    box-shadow: ${props => props.theme.basicBoxShadow};
    border-radius: 5px;
    padding: 3% 15%;
`
const DecorationLine = styled.div`
    border-bottom: 1.5px solid #2122225a;
    min-width: 250px;
    margin-bottom: 15px;
    text-decoration: underline;
`
