//* External Imports
import React, { createSlice } from "@reduxjs/toolkit";

// Type Imports
import { StorageInfo } from "../types/ReduxTypes";

//? Internal Imports
//? Variables
import reducers from "../reducers";

const initialState: StorageInfo = {
    mainStates: [],
    authToken: undefined,
    logInfo: {
        stayLogged: false,
        isLogged: false
    },
    loadError: false,
    sendError: false
}

const useSlice = createSlice({
    name: "useSlice",
    initialState,
    reducers: reducers
})

export const {logIn, logOut, setMainStates, setErrorStatus, sendError, closeError} = useSlice.actions;
export default useSlice.reducer;