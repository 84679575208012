//* External imports
import React from "react"
//* Hooks
import { useTranslation } from "react-i18next"

const TrackErrorDialog = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>
                {t("Can't start your camera or microphone")}
            </div>
            <div>
                {t("Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might already be using your webcam. Please turn off other cameras before proceeding.")}
            </div>
        </div>
    )
}

export default TrackErrorDialog