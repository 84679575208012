//* External imports
import React from "react";
import { VictoryBar, VictoryChart, VictoryContainer, VictoryLabel, VictoryStack } from "victory";

type Props = {
    color: string;
    data: Array<{
        x: number,
        y: number
    }>
    textColor?: string;
    categories?: {
        x: Array<string>
        y: Array<string>
    }
    categoriesX: Array<string>
    domainPadding?: {
        x?: number
        y?: number
    } | number
}

const StackBarLabelChart = (props: Props) => {
    const exception = [" Uso de la lengua"]
    const categories = props.categories ? props.categories : {
        x: props.categoriesX.map((elem) => exception.includes(elem) ? elem : elem.length >= 17 ? elem.split(" ").map((elem2, index, array) => (array.length > 3 ? `${elem2}\n` : ((index+1 / 2) === (array.length / 2) ? `${elem2}\n` : elem2))).join(" "): elem),
        y: new Array(100/props.data.length).fill(1).map((_,index) => `${index+1}`)
    }
    return (
        <svg viewBox="0 0 1000 270">
                <VictoryChart
                    categories={props.categories ? props.categories : categories}
                    standalone={false}
                    horizontal
                    domain={{ x: [1, props.categoriesX.length], y: [1, (100/props.categoriesX.length)] }}
                    domainPadding={{ x: 20 }}
                    padding={{ top: 40, left: 140 }}
                    width={950}
                    height={220}
                    containerComponent={<VictoryContainer responsive={false}/>}
                >
                    <VictoryStack
                        colorScale={["#D9D9D9", props.color]}
                    >
                        <VictoryBar //colored data
                            padding={{ top: 20, left: 200, right: 0 }}
                            style={{
                                data: {
                                    fill: ({ datum }) => datum.fill
                                },
                                labels: {
                                    fill: props.textColor ? props.textColor : "white"
                                }
                            }}
                            labels={({ datum }) => datum.y + 'pts'}
                            labelComponent={<VictoryLabel dy={0} dx={-40} angle={0} />}
                            data={
                                props.data.map((elem) => (
                                        { x: elem.x, y: (elem.y > (100/props.categoriesX.length) ? (100/props.categoriesX.length) : elem.y), fill: props.color }
                                    ))
                            }
                        />
                        <VictoryBar //left to end bar
                            style={{
                                data: {
                                    fill: ({ datum }) => datum.fill
                                }
                            }}
                            data={
                                props.data.map((elem) => (
                                        { x: elem.x, y: Number(categories.y[categories.y.length - 1]) - elem.y, fill: "#D9D9D9" }
                                    ))
                            }
                        />
                    </VictoryStack>
                </VictoryChart>
        </svg>
    )
}

export default StackBarLabelChart
