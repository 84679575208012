//* External imports
import React from "react"
import {VictoryLabel, VictoryPie} from "victory"

//? Donut chart (pie chart without middle) template. Simple look with two data values and % label on the middle.
//! It's called simple because how it's looks.

type Props = {
    colorScale: Array<string>
    data: Array<{
        x: string | number,
        y: number
    }>
    labelPos?: Array<{
        x: number,
        y: number
    }>
    height?: number;
    width?: number
    textCompetency?: string;
    text?: Array<string> | string
    text2?: Array<string> | string
    text3?: Array<string> | string
}

const DonutChartSimple = (props: Props): JSX.Element => {
    const width = props.height ? props.height : 700;
    const height = props.width ? props.width : 550;
    const labelPos = props.labelPos ? props.labelPos : [
        {x:350 , y: 370},
        {x:350 , y: 420}
    ]
    return(
        <svg viewBox={`0 0 ${width} ${height}`} style={{fontFamily: "'Familjen Grotesk', sans-serif"}}>
            <g transform="translate(0, 90)">
                <VictoryPie
                    standalone={false}
                    width={width} height={height-150}
                    data={props.data}
                    colorScale={props.colorScale}
                    padAngle={2}
                    style={{labels: {fill: "transparent"}}}
                    innerRadius={120}
                    animate={{duration: 2000}}
                />
                <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={props.textCompetency ? props.textCompetency : "Reading"}
                    x={350}
                    y={-20}
                    style={[
                        {fontSize: 45, fontWeight: 700 ,fontFamily: "'Familjen Grotesk', sans-serif"},

                    ]}
                />
                <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={props.text ? props.text : [`${Math.floor(props.data[1].y)}%`, "progress"]}
                    x={width/2}
                    y={(height/2)-75}
                    style={[
                        {fontSize: 55, fontFamily: "'Familjen Grotesk', sans-serif"},
                        {fontSize: 40, fontFamily: "'Familjen Grotesk', sans-serif"},
                    ]}
                />
                <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={props.text2 ? props.text2 : ""}
                    x={labelPos[0].x}
                    y={labelPos[0].y}
                    style={[
                        {fontSize: 40, fontFamily: "'Familjen Grotesk', sans-serif"},

                    ]}
                />
                <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={props.text3 ? props.text3 : undefined}
                    x={labelPos[1].x}
                    y={labelPos[1].y}
                    style={[
                        {fontSize: 45, fontFamily: "'Familjen Grotesk', sans-serif", fill:"white", background: props.colorScale[1]}
                    ]}
                    backgroundStyle={{fill: props.colorScale[1]}}
                    backgroundPadding={10}
                />
            </g>
        </svg>
    )
}

export default DonutChartSimple;