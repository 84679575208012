export const MediaPermissionErrorType = {
    /** (macOS) browser does not have permission to access cam/mic */
	SystemPermissionDenied: "SystemPermissionDenied",
	/** user denied permission for site to access cam/mic */
	UserPermissionDenied: "UserPermissionDenied",
	/** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
	CouldNotStartVideoSource: "CouldNotStartVideoSource",
	/** all other errors */
	Generic: "Generic",
}

export const DialogType = {
    explanation: "explanation",
	systemDenied: "systemDenied",
	userDenied: "userDenied",
	trackError: "trackError"
}

export type MediaType = "audio" | "video";

export type DialogType = typeof DialogType[keyof typeof DialogType]

export type MediaPermissionErrorType = typeof MediaPermissionErrorType[keyof typeof MediaPermissionErrorType]

export type MediaPermissionError = {
    type?: MediaPermissionErrorType,
    name: string,
    message?: string
}
