//* External Imports
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import parse from "html-react-parser"
//* Hooks
import { useTranslation } from "react-i18next";
//* Methods
import { format } from "date-fns";

// Type Imports
import { CompletedExercise } from "../../types/UserTypes";

//? Internal Imports
//? JSX Components
import StackBarLabelChart from "../charts/barCharts/stackBarLabelChart";
import TitleComponent from "../TitleComponent";
import Loader from "../loader";
import StackBarLabelResponsiveChart from "../charts/barCharts/stackBarLabelResponsiveChart";
//? Methods
import { isDesktop, isMobile } from "../../responsive/mediaQueriesStates";
//? Styled-Components
import { WhiteCard } from "../../styles/WhiteCard";
import Feedback from "../feedback";
import { globalErrorHandler } from "../../axios/globalErrorHandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


type Props = {
    exercise: CompletedExercise,
    competency: string,
    task: string,
    id_user: number
    goBack: boolean,
    notificationRender?: boolean;
    setGoBack: React.Dispatch<React.SetStateAction<boolean>>;
}

type BarStackData = {
    info: {
        blocks: string[]
        data: Array<{
            x: number,
            y: number
        }>
    }
}

/** Single exercise Report - Writing & Speaking  */
const TReportWS = (props: Props): JSX.Element => {
    const { t } = useTranslation("translation", { keyPrefix: "wsReport" })
    const isMobileState = isMobile()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [doOvers, setDoOvers] = useState<{ times: number, passed: boolean | null, written: string, correction_notes: string, score: number }>({ times: 0, passed: null, written: "", correction_notes: "", score: 0 })
    const [stackBarData, setStackBarData] = useState<BarStackData>({ info: { blocks: [], data: [{ x: 0, y: 0 }] } })
    const color = props.competency === "Writing" ? "#F93E63" : "#FFC152"
    const [loading, setLoading] = useState<boolean>(false)
    const [teacher, setTeacher] = useState<{name: string}>({name: ""})
    const isDesktopState = isDesktop()
    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/doOver/${props.exercise.id_exercise}/${props.id_user}`).then((response) => {
            setDoOvers(response.data)
            axios.get<{name: string}>(`${process.env.REACT_APP_API_URL}/teacher/${props.id_user}`).then((response) => {
                setTeacher(response.data)
            }).catch(() => globalErrorHandler(dispatch, navigate))
            if (props.exercise.completed && props.exercise.corrected) {
                axios.get(`${process.env.REACT_APP_API_URL}/stackBar/${props.exercise.id_exercise}/${props.id_user}`).then((response) => {
                    setStackBarData(response.data)
                    setLoading(false)
                }).catch(() => globalErrorHandler(dispatch, navigate))
            } else {
                setLoading(false)
            }
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])
    if (loading) {
        return <Loader loading={loading} />
    }
    return (
        <MainDiv state={isMobileState} state2={props.notificationRender}>
            <CompetencyDiv>
                <TitleComponent style={{ color: color }} setGoBack={props.setGoBack} goBack={props.goBack} text={`${t(`${props.competency}`)} ${t("Report")}`} />
            </CompetencyDiv>
            <UpperContainer color={color}>
                <SubTitle style={{ fontSize: "20px", alignSelf: "flex-start", fontWeight: 700 }}>{props.task}</SubTitle>
                {!props.exercise || !props.exercise.corrected && 
                    <TeacherText>
                        {`${t("Your teacher")} ${teacher.name} ${t("is correcting your exercise")}`}
                    </TeacherText>
                }
                <UpperCard>
                    <UpperCardElem style={{ borderLeft: "transparent" }}>
                        <UpperInfoCard>{t("Completion Date")}</UpperInfoCard>
                        <DownInfoCard>{format(new Date(), "dd/MM/yyyy")}</DownInfoCard>
                    </UpperCardElem>
                    {props.exercise && props.exercise.corrected &&
                        <>
                            <UpperCardElem>
                                <UpperInfoCard>{t("Task Do-over")}</UpperInfoCard>
                                <DownInfoCard>{doOvers.times}</DownInfoCard>
                            </UpperCardElem>
                            <UpperCardElem>
                                <UpperInfoCard>{t("Score")}</UpperInfoCard>
                                <DownInfoCard>{`${doOvers.score}/100`}</DownInfoCard>
                            </UpperCardElem>
                        </>
                    }
                </UpperCard>
            </UpperContainer>
            {props.exercise && props.exercise.text_written &&
                <TextContainer>
                    {<TextArea>
                        {parse(doOvers.written)}
                    </TextArea>}
                </TextContainer>
            }
            {props.exercise && props.exercise.file_speaking &&
                <audio style={{marginTop: "20px"}} controls controlsList="nodownload" src={props.exercise.file_speaking} />
            }
            {props.exercise && props.exercise.corrected &&
                <ChartCard>
                    {isDesktopState ? <StackBarLabelChart
                        data={stackBarData.info.data}
                        color={color}
                        categoriesX={stackBarData.info.blocks}
                    />
                        :
                        <StackBarLabelResponsiveChart
                            data={stackBarData.info.data}
                            color={color}
                            categoriesX={stackBarData.info.blocks}
                        />
                    }
                </ChartCard>
            }
            {doOvers.correction_notes && props.exercise && props.exercise.corrected &&
                <Feedback text={doOvers.correction_notes}/>
            }
        </MainDiv>
    )
}

export default TReportWS

const MainDiv = styled.div<{state: boolean, state2?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state2 ? "80%" : props.state ? "95%" : "80%"};
    background: #F5F5F5;
    margin-top: 20px;
`
const CompetencyDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    div {
        font-size: 25px;
        font-weight: 700;
    }
`
const UpperContainer = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${props => props.color};
`
const SubTitle = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    font-weight: 700;
    font-size: 20px;
    margin-left: 33px;
    color: ${props => props.theme.fontColor};
    margin-bottom: 30px;
`
const TeacherText = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    font-weight: 700;
    font-size: 17px;
    color: ${props => props.theme.fontColor};
    margin-bottom: 30px;
`
const UpperCard = styled(WhiteCard)`
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
`
const UpperCardElem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.3%;
    height: 100%;
    border-left: 0.5px solid #D3CFCF;
`
const UpperInfoCard = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
    margin-top: 10px;
`
const DownInfoCard = styled.div`
    font-weight: 600;
    margin-bottom: 10px;
    color: ${props => props.theme.fontColor};
`
const TextContainer = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 300px;
    margin-top: 30px;
`
const TextArea = styled.div`
    background: #F6F7F8;
    width: 95%;
    height: 100%;
    overflow-y: auto;
    min-height: 240px;
    max-height: 500px;
    resize: none;
    margin-top: 10px;
    border-radius: 20px;
    border: transparent;
    padding: 15px;
    text-align: left;
    box-sizing: border-box;
    div {
        word-break: break-word;
        word-wrap: break-word;
    }
    &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: transparent;
    }
`
const ChartCard = styled(WhiteCard)`
    width: 100%;
    height: 100%;
    margin-top: 30px;
`