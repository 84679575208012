//* External Imports
import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

//? Internal Imports
//? Hooks
import userReducer from "./hook/useReducer";

const persistConfig = {
    key: "root",
    storage,
}

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk]
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof persistReducer>
export type AppStore = typeof store