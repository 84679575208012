//* External Imports
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

//? Internal Imports
//? JSON
import en from "./translations/en.json"
import es from "./translations/es.json"

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: en,
            es: es
        }
    })

export default i18n