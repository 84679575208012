//* External Imports
import React, { useState, useEffect } from "react"
import axios from "axios"
import styled from "styled-components"
//* Hooks
import { useTranslation } from "react-i18next"

// Type Imports
import { PlanType } from "../types/GeneralTypes"

//? Internal Imports
//? JSX Components
import Loader from "../components/loader"
//? Styled-Components
import { WhiteCard } from "../styles/WhiteCard"
import { ButtonTransition } from "../styles/buttons/ButtonTransition"
import { isMobile } from "../responsive/mediaQueriesStates"
import { globalErrorHandler } from "../axios/globalErrorHandler"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

type Props = {
    id_user: number
}

type UserInfo = {
    userLevel: string | undefined,
    userPlan: string | undefined
}

const defaultUserInfo: UserInfo = {
    userLevel: undefined,
    userPlan: undefined
}

const PaymentPlatform = (props: Props) => {
    const { t } = useTranslation()
    const isMobileState = isMobile()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // Input States
    const [planType, setPlanType] = useState<PlanType | undefined>(2)
    const [promoCode, setPromoCode] = useState<string | undefined>(undefined)
    const [planSelector, setPlanSelector] = useState<boolean[]>([false, true, false])
    const [levelSelect, setLevelSelect] = useState<string>("")
    // API Request Data
    const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo)
    const [plans, setPlans] = useState<{ name: string, price: number }[]>([])
    const [levels, setLevels] = useState<string[] | undefined>(undefined)
    // Error states
    const [isValidPromoCode, setIsValidPromoCode] = useState<boolean | undefined>(undefined)
    // Others
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        axios.get<{ level: string }>(`${process.env.REACT_APP_API_URL}/userLevel/${props.id_user}`).then((response) => {
            const userLevel = response.data.level
            axios.get<{ name: string }>(`${process.env.REACT_APP_API_URL}/userPlan/${props.id_user}`).then((response) => {
                setUserInfo({ userLevel: userLevel, userPlan: response.data.name })
                axios.get<{ plans: { name: string, price: number }[] }>(`${process.env.REACT_APP_API_URL}/plans`).then((response) => {
                    setPlans(response.data.plans)
                    axios.get<{ names: string[] }>(`${process.env.REACT_APP_API_URL}/certificationLevels/${props.id_user}`).then((response) => {
                        setLevels(response.data.names)
                        setLoading(false)
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }).catch(() => globalErrorHandler(dispatch, navigate))
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])

    const handlePayment = async () => {
        if (planType) {
            const redirect = await axios.post(`${process.env.REACT_APP_API_URL}/payment`, {
                idPlan: planType,
                idUser: props.id_user,
                promoCode: isValidPromoCode === true ? promoCode : undefined
            })
            window.open(redirect.data.url, "_self")
        }
    }

    const handlePromoCode = async () => {
        if (promoCode && promoCode.length > 0) {
            const isValid = await axios.post<{ valid: boolean }>(`${process.env.REACT_APP_API_URL}/validPromoCode`, {
                promoCode: promoCode
            })
            setIsValidPromoCode(isValid.data.valid)
        }
    }

    const handleInputRadio = (index: number) => {
        setPlanSelector(planSelector.map((_, i) => i === index ? true : false))
        setPlanType(index + 1 as PlanType)
    }

    return (
        <MainDiv state={isMobileState}>
            {loading && <Loader loading={loading} />}
            <LevelContainer>
                <LevelTitle>{t("Levels")}:</LevelTitle>
                {levels && userInfo && userInfo.userLevel &&
                    <Select value={levelSelect} onChange={(e) => setLevelSelect(e.target.value)}>
                        {levels.filter((elem) => elem !== userInfo.userLevel).map((elem) => <Option>{elem}</Option>)}
                    </Select>
                }
            </LevelContainer>
            <LevelTitle style={{ alignSelf: "flex-start" }}>{t("Select a Plan")}</LevelTitle>
            <BottomDiv>
                <PlanContainer>
                    {plans && plans.map((elem, index) =>
                        <PlanCard>
                            <LabelRadio>
                                <CircularInputCheckbox type="radio" checked={planSelector[index]} onChange={() => handleInputRadio(index)} />
                            </LabelRadio>
                            <PlanTextContainer>
                                <PlanText>{elem.name}</PlanText>
                                <PlanPrice>{elem.price} €</PlanPrice>
                            </PlanTextContainer>
                        </PlanCard>
                    )}
                </PlanContainer>
                {plans && plans.length > 0 &&
                    <SummaryContainer>
                        <SummaryTitle>{t("Summary")}</SummaryTitle>
                        <DecorationLine/>
                        <SummaryTitle>{plans[planType ? planType-1 : 1].name}</SummaryTitle>
                        <DecorationLine/>
                        <TotalRow>
                            {t("Total")}
                            <span>{plans[planType ? planType-1 : 1].price}€</span>
                        </TotalRow>
                        <div>{t("Pay comfortably! Now you can select Klarna as the payment method and divide the amount into three installments.")}</div>
                        <ButtonSummary onClick={handlePayment}>{t("Order")}</ButtonSummary>
                        <PromoContainer>
                            <input type="text" placeholder={t("Promotional code").toString()} value={promoCode} onChange={(e) => setPromoCode(e.target.value)}/>
                            <ButtonSummary onClick={handlePromoCode}>{t("Apply code")}</ButtonSummary>
                        </PromoContainer>
                    </SummaryContainer>
                    }
            </BottomDiv>
        </MainDiv>
    )
}

export default PaymentPlatform

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    width: ${props => props.state ? "95%" : "80%"};
    height: 100%;
    gap: 30px;
    background: #F5F5F5;
`
const LevelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
`
const LevelTitle = styled.div`
    color: ${props => props.theme.langooBlue};
    font-size: 20px;
    font-weight: 700;
`
const SummaryTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
`
const Select = styled.select`
    width: 200px;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
`
const Option = styled.option`
    
`
const BottomDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`
const PlanContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    gap: 20px;
`
const PlanCard = styled.label`
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: ${props => props.theme.basicBoxShadow};
    border-radius: 20px;
    gap: 20px;
    padding: 20px;
`
const LabelRadio = styled.label`
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    height: 50%;
    //margin-top: 0.5%;
    input[type=radio] {
        -webkit-appearance: none;
        appearance: none;
        background: #FFFFFF;
        border: 0.15em solid #AAAAAA;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin: 0;
        width: 1.5em;
        height: 1.5em;
        display: grid;
        place-content: center;
        transform: translateY(-0.075em);
        ::before {
            content: "";
            width: 0.80em;
            height: 0.80em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            background: ${props => props.theme.langooBlue};
            border: 0.15em solid ${props => props.theme.langooBlue};
        }
        :checked::before{
            transform: scale(1.40);
        }
    }
`
const CircularInputCheckbox = styled.input`
    border-radius: 999px;
`
const PlanTextContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
`
const PlanText = styled.div`
    font-size: 18px;
    font-weight: 700;
`
const PlanPrice = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: ${props => props.theme.langooBlue};
`
const SummaryContainer = styled(WhiteCard)`
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    width: 40%;
    text-align: left;
`
const TotalRow = styled.div`
    font-size: 21px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    span {
        color: ${props => props.theme.langooBlue};
        font-size: 22px;
    }
`
const ButtonSummary = styled(ButtonTransition)`
    background: ${props => props.theme.langooBlue};
    border: 1px solid ${props => props.theme.langooBlue};
    width: 100%;
    &:hover {
        color: ${props => props.theme.langooBlue};
    }
`
const PromoContainer = styled.div`
    width: 100%;
    input {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 10px;
        outline: none;
        border-radius: 5px;
        border: 1px solid #2122225a;
        height: 35px;
        padding: 10px;
    }
`
const DecorationLine = styled.div`
    border-bottom: 1.5px solid #2122225a;
    width: 100%;
    text-decoration: underline;
`