//* External Imports
import React from "react"
import Bowser from "bowser"
//* Hooks
import { useTranslation } from "react-i18next"

// Type Imports
import { MediaType } from "../../mediaPermissions/types/mediaTypes"

//? Internal Imports
//? Utils
import { toFirstLetterUpperCase } from "../../utils/toFirstLetterUpperCase"

const UserDeniedDialog = (props: { media?: MediaType }) => {
    const { t } = useTranslation()
    const browser = Bowser.getParser(window.navigator.userAgent);
    const mediaTranslation = t(props.media ? `${props.media}` : "camera and microphone")
    const isAreTranslation = t(props.media ? "is" : "are")
    return (
        <div>
            <div>
                {`${toFirstLetterUpperCase(mediaTranslation)} ${isAreTranslation}`}  {t("blocked")}
            </div>
            <div>
                {t("App requieres access to your ")} {mediaTranslation}.{' '}
                {browser.getBrowserName() !== "Safari" && (
                    <>
                        {t("Click the camera blocked icon")}
                        <img alt="icon" src="https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg" />
                        {t("in your browser's address bar")}
                    </>
                )
                }
            </div>
        </div>
    )
}

export default UserDeniedDialog