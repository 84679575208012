//* External imports
import React, { useState, useEffect } from "react";
import styled from "styled-components"
import parse from "html-react-parser";
import axios, { AxiosResponse } from "axios";

// Type Imports
import { CompletedExercise } from "../types/UserTypes";
import { Answer, ExerciseType, CustomQuestionSize, LearningPathExercises, LearningPathCompetencyType } from "../types/GeneralTypes";
import { UseRecorder } from "../hooks/VoiceRecorder/types/recorder";

//? Internal Imports
//? JSX Components
import TaskReport from "./reports/taskReport";
import Loader from "./loader";
import TextArea from "./TextArea";
//? Hooks
import useRecorder from "../hooks/VoiceRecorder/use-recorder";
//? Methods
import { formatMinutes, formatSeconds } from "../hooks/VoiceRecorder/utils/format-time";
import uploadAudio from "../aws/query/uploadAudio";
import { isMobile } from "../responsive/mediaQueriesStates";
//? Styled-Components
import { Button } from "../styles/buttons/ButtonRound";
import { WhiteCard } from "../styles/WhiteCard";
import TReportWS from "./reports/TReportWS";
import { useTranslation } from "react-i18next";
import TitleComponent from "./TitleComponent";
import LangooChronometer from "./LangooChronometer";
import { objectToArrayByCertificactionAndLevel } from "../utils/objectToArrayByCertificationAndLevel";
import { globalErrorHandler } from "../axios/globalErrorHandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//! Images

type Props = {
    exercise: ExerciseType;
    mainColor: string;
    id_user: number;
    certification?: string
    certification_level?: string
    questions?: CustomQuestionSize;
    answers?: Answer[][];
    setToExam: React.Dispatch<React.SetStateAction<boolean>>
    setStudentExe: React.Dispatch<React.SetStateAction<CompletedExercise[] | undefined>>
    setLearningPathPercent?: React.Dispatch<React.SetStateAction<{
        reading: number;
        listening: number;
        speaking: number;
        writing: number;
    }>>
    setLearningPathExercises?: React.Dispatch<React.SetStateAction<LearningPathCompetencyType[] | undefined>>
}

const defaultCompletedExercise = {
    completed: false,
    start_date: new Date(Date.now()),
    end_date: new Date("1900-01-01"),
    hits: null,
    failed: null,
    text_written: null,
    file_speaking: null,
    answers_todas: {
        answerText: undefined,
        answers: []
    },
    corrected: false,
    passed: false,
    score: null,
    score_global: 0,
    id_exam: 0
}

/** Single exercise JSX Component */
const TaskExam = (props: Props): JSX.Element => {
    const isMobileState = isMobile()
    const { t } = useTranslation("translation", { keyPrefix: "taskExam" })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(true)
    const [questions, setQuestions] = useState<CustomQuestionSize | undefined>(props.questions ? props.questions : undefined)
    const [answers, setAnswers] = useState<Answer[][] | undefined>(props.answers ? props.answers : undefined)
    const [inputValue, setInputValue] = useState<(boolean[] | string)[]>([[true], "hola"])
    const [writingText, setWritingText] = useState<string>("")
    const [exerciseCompleted, setExerciseCompleted] = useState<boolean>(false)
    const [exerciseResults, setExerciseResults] = useState<CompletedExercise>({ id_user: props.id_user, id_exercise: props.exercise.id_exercise, ...{ ...defaultCompletedExercise, start_date: new Date(Date.now()) } })
    const [nextSolution, setNextSolution] = useState<boolean[]>()

    const { recorderState, ...handlers }: UseRecorder = useRecorder();
    const { startRecording, saveRecording } = handlers;
    const { recordingMinutes, recordingSeconds } = recorderState;
    const { audio, rawBlob } = recorderState

    useEffect(() => {
        if (!props.questions && !props.answers && props.exercise.exercise_type !== "Speaking" && props.exercise.exercise_type !== "Writing") {
            axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
                id_user: props.id_user,
                id_exercise: props.exercise.id_exercise,
                start_date: new Date(Date.now()),
                id_exam: 0
            }).then(() =>
                axios.get<CustomQuestionSize>(`${process.env.REACT_APP_API_URL}/questions/exercise/${props.exercise.id_exercise}`).then((response) => {
                    setQuestions(response.data)
                    const data = response.data;
                    axios.get<Answer[][]>(`${process.env.REACT_APP_API_URL}/answers/exercise/${props.exercise.id_exercise}`).then((response) => {
                        setAnswers(response.data)
                        setInputValue(response.data.map((elem, i) => elem[0].type === "fill" ? "" : new Array(data.answerSize[i]).fill(false)))
                        setLoading(false)
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }).catch(() => globalErrorHandler(dispatch, navigate))
            ).catch(() => globalErrorHandler(dispatch, navigate))
        } else if (props.questions) {
            setQuestions({ ...props.questions, questions: props.questions.questions.sort((a, b) => a.order - b.order) })
            axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
                id_user: props.id_user,
                id_exercise: props.exercise.id_exercise,
                start_date: new Date(Date.now()),
                id_exam: 0
            }).then((_) => {
                if(props.answers){
                    setInputValue(props.answers.map((elem, i) => elem[0].type === "fill" ? "" : new Array(props.questions!.answerSize[i]).fill(false)))
                }else{
                    setInputValue({ ...props.questions, questions: props.questions!.questions.sort((a, b) => a.order - b.order) }.questions.map((_, i) => {
                        const answerSizeI = { ...props.questions!, questions: props.questions!.questions.sort((a, b) => a.order - b.order) }?.answerSize[i]
                        return answerSizeI === 1 ? "" : new Array(answerSizeI).fill(false)
                    }))
                }
                setLoading(false)
            }).catch(() => globalErrorHandler(dispatch, navigate))
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
                id_user: props.id_user,
                id_exercise: props.exercise.id_exercise,
                start_date: new Date(Date.now()),
                id_exam: 0
            }).then((_) => {
                setLoading(false)
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }
    }, [])

    useEffect(() => {
        if (rawBlob && audio) {
            setLoading(true)
            uploadAudio(rawBlob, audio).then((response) => {
                axios.post(`${process.env.REACT_APP_API_URL}/completed`, {
                    id_user: props.id_user,
                    id_exercise: props.exercise.id_exercise,
                    completed: true,
                    start_date: exerciseResults.start_date,
                    end_date: new Date(Date.now()),
                    hits: null,
                    failed: null,
                    text_written: null,
                    file_speaking: response,
                    corrected: false,
                    passed: null,
                    score: null,
                    id_exam: 0,
                    score_global: 0
                }).then(() => {
                    axios.get<CompletedExercise[]>(`${process.env.REACT_APP_API_URL}/exercises/completed/${props.id_user}`).then((response) => {
                        props.setStudentExe(response.data)
                        refetchLearningPath(response)
                        setLoading(false)
                    })
                }).catch(() => globalErrorHandler(dispatch, navigate))
            })
        }
    }, [rawBlob, audio])

    /** Fetch new Learning Path exercises */
    const callLearningPathExercises = async (setPercent?: React.Dispatch<React.SetStateAction<{ reading: number; listening: number; speaking: number; writing: number; }>>) => {
        axios.get<LearningPathExercises>(`${process.env.REACT_APP_API_URL}/learningPath/${props.id_user}`).then((response) => {
            if (props.setLearningPathExercises && props.certification && props.certification_level) {
                const competencyToArray = objectToArrayByCertificactionAndLevel(response.data, props.certification, props.certification_level) as LearningPathCompetencyType[]
                props.setLearningPathExercises(competencyToArray)
            }
            if (setPercent) {
                setPercent({
                    reading: response.data.reading.percent,
                    listening: response.data.listening.percent,
                    speaking: response.data.speaking.percent,
                    writing: response.data.writing.percent
                })
            }
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }

    /** Refetch Learning Path exercises  */
    const refetchLearningPath = async (responseVar: AxiosResponse<CompletedExercise[], any>) => {
        if (props.setLearningPathExercises) {
            await callLearningPathExercises(props.setLearningPathPercent)
            setExerciseResults(responseVar.data.find((elem) => elem.id_exercise === props.exercise.id_exercise)!)
            setLoading(false)
            setExerciseCompleted(true)
        } else {
            setExerciseResults(responseVar.data.find((elem) => elem.id_exercise === props.exercise.id_exercise)!)
            setLoading(false)
            setExerciseCompleted(true)
        }
    }

    /** Change input values */
    const changeCheckValues = (i: number, j: number, e?: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(inputValue.map((elem, index) => i !== index ? elem : (
            Array.isArray(elem) ? elem.map((_, index2) => j === index2 ? true : false) : (e !== undefined ? e.target.value : "")
        )))
    }
    /** Format & storege user answers into database*/
    const sendAnswers = async () => {
        if (questions && answers) {
            const preRequest: { idArr: number[] | undefined, answers: (string | boolean[])[] } = {
                idArr: questions ? questions.questions.map((elem) => elem.id_question).flat(1) : [],
                answers: inputValue
            }
            axios.post<{ results: boolean[] }>(`${process.env.REACT_APP_API_URL}/answers`, {
                idArr: preRequest.idArr,
                answers: preRequest.answers,
            }).then(async (response) => {
                setNextSolution(response.data.results)
                const answersValues = (inputValue.filter((elem) => Array.isArray(elem)) as boolean[][]).flat(1).map((elem2, index) => elem2 ? index : -1)
                const auxAnswers = questions.answerSize.map((elem, i) => new Array(elem).fill(undefined).map((_, j) => answersValues[((i * elem) + j)]))
                const answerText = inputValue.filter((elem) => (typeof elem === "string")) as string[]
                const hits = response.data.results.filter((elem) => elem === true).length
                const typeExercise = (props.exercise.exercise_type === "Reading" || props.exercise.exercise_type === "Listening");
                const auxResult = {
                    id_user: props.id_user,
                    id_exercise: props.exercise.id_exercise,
                    completed: true,
                    start_date: exerciseResults.start_date,
                    end_date: new Date(Date.now()),
                    hits: typeExercise ? hits : null,
                    failed: typeExercise ? questions ? questions.questions.length - hits : null : null,
                    text_written: props.exercise.exercise_type === "Writing" ? writingText : null,
                    file_speaking: null,
                    answers_todas: {
                        answerText: answerText.length !== 0 ? answerText : undefined,
                        answers: preRequest.idArr ? preRequest.idArr.map((elem, i) => ({
                            id_question: elem,
                            answerPos: auxAnswers[i].find((elem2) => elem2 !== -1),
                        })) : []
                    },
                    corrected: typeExercise ? true : false,
                    passed: typeExercise ? hits > (questions ? (questions.questions.length / 2) : 0) : null,
                    score: typeExercise ? (hits > (questions ? (questions.questions.length / 2) : 0) ? "APTO" : "NO APTO") : null,
                    id_exam: 0,
                    score_global: typeExercise ? ((100 * hits) / questions.questions.length) : 0
                }
                if ((auxResult.answers_todas.answers.length !== 0) && (auxResult.answers_todas.answers[0].id_question)) {
                    setLoading(true)
                    axios.post(`${process.env.REACT_APP_API_URL}/completed`, auxResult).then(() => {
                        axios.get<CompletedExercise[]>(`${process.env.REACT_APP_API_URL}/exercises/completed/${props.id_user}`).then((response) => {
                            props.setStudentExe(response.data)
                            refetchLearningPath(response).then(() => {
                                setExerciseCompleted(true)
                            }).catch(() => globalErrorHandler(dispatch, navigate))
                        }).catch(() => globalErrorHandler(dispatch, navigate))
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }
            }).catch(() => globalErrorHandler(dispatch, navigate))
        } else {
            let audioPath: string | undefined = ""

            if (props.exercise.exercise_type === "Speaking") {
                if (rawBlob && audio) {
                    audioPath = await uploadAudio(rawBlob, audio)
                }
            }
            const auxResult = {
                id_user: props.id_user,
                id_exercise: props.exercise.id_exercise,
                completed: true,
                start_date: exerciseResults.start_date,
                end_date: new Date(Date.now()),
                hits: null,
                failed: null,
                text_written: props.exercise.exercise_type === "Writing" ? `<p>${writingText.replaceAll("\n", "<br/>")}</p>` : null,
                file_speaking: props.exercise.exercise_type === "Speaking" ? audioPath ? audioPath : null : null,
                corrected: false,
                passed: null,
                score: null,
                id_exam: 0,
                score_global: 0
            }
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/completed`, auxResult).then(() => {
                axios.get<CompletedExercise[]>(`${process.env.REACT_APP_API_URL}/exercises/completed/${props.id_user}`).then((response) => {
                    props.setStudentExe(response.data)
                    refetchLearningPath(response).then(() => {
                        setExerciseCompleted(true)
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }).catch(() => globalErrorHandler(dispatch, navigate))
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }
    }

    if (loading) {
        return <Loader loading={loading} />
    }
    return (
        <>
            {!exerciseCompleted ?
                <MainDiv state={isMobileState}>
                    <CompetencyTop>
                        <TitleComponent style={{ fontSize: "30px" }} setGoBack={props.setToExam} goBack={true} text={t(`${props.exercise.exercise_type}`)} />
                        <SubTitle>
                            {`${t("Task")} ${props.exercise.task_part}-${props.exercise.task_type}`}
                        </SubTitle>
                    </CompetencyTop>
                    <TaskTimeBar>
                        <LangooChronometer stop={exerciseCompleted}/>
                    </TaskTimeBar>
                    <DownContainer state={isMobileState}>
                        {questions && answers && (props.exercise.exercise_type !== "Writing") && (props.exercise.exercise_type !== "Speaking") &&
                            <>
                                <ExamCard state={isMobileState}>
                                    <div>
                                        {props.exercise.statement}
                                    </div>
                                    <DecorationLine />
                                    <div>
                                        {parse(props.exercise.content)}
                                        {props.exercise.file && props.exercise.file.split(";").filter((elem) => elem.length > 0).map((elem) =>
                                            <audio controls controlsList="noplaybackrate nodownload" src={elem} />
                                        )}
                                    </div>
                                </ExamCard>
                                <TasksContainers>
                                    {questions.questions.map((elem, i) => <TaskCardDiv key={i}>
                                        <InfoCardDiv color={props.mainColor}>
                                            <InfoCardTop>
                                                <strong>{elem.text.length !== 0 ? elem.text : `${i}.`}</strong>
                                            </InfoCardTop>
                                            <Fieldset style={{ border: "none", margin: "0px", padding: "0px" }}>
                                                {answers[i].map((elem2, j) => elem2.type === "fill" ?
                                                    <InfoCardInput type="text" value={inputValue[i] as string} onChange={(e) => changeCheckValues(i, j, e)} key={i + j + 10} />
                                                    :
                                                    <Label key={i + j + 10}>
                                                        <LabelRadio color={props.mainColor}>
                                                            <CircularInputCheckbox type="radio" checked={inputValue[i][j] as boolean} onChange={() => changeCheckValues(i, j)} />
                                                        </LabelRadio>
                                                        {elem2.type === "opt" ? elem2.text : <img style={{ width: "50%" }} src={elem2.text} alt={`img_${i}_${j}`} />}
                                                    </Label>)}
                                            </Fieldset>
                                        </InfoCardDiv>
                                    </TaskCardDiv>)}
                                    <div style={{ marginLeft: '5%' }}>
                                        <NextButton background="#F93E63" color="white" onClick={sendAnswers}>{t("Finish")}</NextButton>
                                    </div>
                                </TasksContainers>
                            </>
                        }
                        {props.exercise.exercise_type === "Writing" &&
                            <WritingTest>
                                <WritingExamCard>
                                    <strong>
                                        {props.exercise.statement}
                                    </strong>
                                    <DecorationLine />
                                    <div>
                                        {parse(props.exercise.content)}
                                    </div>
                                    {props.exercise.file && props.exercise.file.split(";").filter((elem) => elem.length > 0).map((elem) =>
                                        <audio controls controlsList="noplaybackrate nodownload" src={elem} />
                                    )}
                                </WritingExamCard>
                                <TextArea examType={true} writingText={writingText} setWritingText={setWritingText} id_user={props.id_user} />
                                <NextButton background="#F93E63" color="white" onClick={sendAnswers}>{t("Finish")}</NextButton>
                            </WritingTest>
                        }
                        {props.exercise.exercise_type === "Speaking" &&
                            <SpeakingContainer>
                                <SpeakingExamCard>
                                    <strong>
                                        {props.exercise.statement}
                                    </strong>
                                    <DecorationLine />
                                    <div>
                                        {parse(props.exercise.content)}
                                    </div>
                                    {props.exercise.file && props.exercise.file.split(";").filter((elem) => elem.length > 0).map((elem) =>
                                        <audio controls controlsList="noplaybackrate nodownload" src={elem} />
                                    )}
                                </SpeakingExamCard>
                                <VoiceRecorder>
                                    <RecordTimeSpan>
                                        <h4>{t("Time")}: </h4><h4 style={{ color: '#0E8EAB' }}>{formatMinutes(recordingMinutes)}:{formatSeconds(recordingSeconds)}</h4>
                                    </RecordTimeSpan>
                                </VoiceRecorder>
                                {(recordingSeconds === 0 && recordingMinutes === 0) ?
                                    <NextButton background="#F93E63" color="white" onClick={startRecording}>{t("Start Recording")}</NextButton>
                                    :
                                    <NextButton background="#F93E63" color="white" onClick={() => [saveRecording(), setLoading(true), setTimeout(() => { setLoading(false) }, 1000)]} disabled={recordingSeconds === 0}>{t("Stop Recording & Finish Task")}</NextButton>
                                }
                            </SpeakingContainer>
                        }
                    </DownContainer>
                </MainDiv>
                :
                props.exercise.exercise_type === "Speaking" || props.exercise.exercise_type === "Writing" ?
                    <TReportWS exercise={exerciseResults}
                        competency={props.exercise.exercise_type === "Speaking" ? props.exercise.exercise_type : "Writing"}
                        task={`${t("Task")} ${props.exercise.task_part}-${props.exercise.task_type}`}
                        id_user={props.id_user} goBack={exerciseCompleted} setGoBack={props.setToExam}
                    />
                    :
                    <TaskReport
                        questions={questions}
                        answers={answers} setToExam={props.setToExam} prevSolution={nextSolution}
                        completedExercise={exerciseResults} inputValue={inputValue}
                        id_user={props.id_user} setStudentExe={props.setStudentExe} goBack={exerciseCompleted}
                        setGoBack={props.setToExam}
                    />
            }
        </>
    )
}

export default TaskExam

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "80%" : "80%"};
    min-height: 100vh;
    background: #F5F5F5;
    padding-bottom: 50px;
`
const CompetencyTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;
    color: ${props => props.theme.fontColor};
`
const SubTitle = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    font-weight: 700;
    font-size: 20px;
    margin-left: 33px;
    color: ${props => props.theme.fontColor};
`
const InfoCardInput = styled.input`
    background: #eeeded;
    border: 1px solid transparent;
    height: 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    outline: none;
`
const DownContainer = styled.div<{ state: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    width: 100%;
    height: 100%;
    gap: ${props => props.state ? "20px" : "0px"};
    img {
        max-width: 100%;
    }
`
const TaskTimeBar = styled(WhiteCard)`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin: 3% 10%;
    margin-top: 0;
    padding: 15px;
    box-sizing: border-box;
`
const DecorationLine = styled.div`
    border-bottom: 1.5px solid #2122225a;
    width: 100%;
    margin: 3%;
    text-decoration: underline;
`
const Fieldset = styled.fieldset`
    width: 100%;
    height: 100%;
`
const NextButton = styled(Button) <{ background: string, color?: string }>`
    border: 1px solid ${props => props.background};
    background: ${props => props.disabled ? "white" : props.background};
    color: ${props => props.disabled ? props.background : (props.color ? props.color : props.theme.fontColor)};;
    width: 300px;
    height: 43px;
    font-size: 20px;
    border-radius: 8px;
    margin-top: 10px;
`
const ExamCard = styled(WhiteCard)<{state: boolean}>`
    position: ${props => props.state ? "none" : "sticky"};
    top: 0px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    min-height: fit-content;
    text-align: justify;
    padding: 3%;
    margin-right: 3%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    p {
        width: 100%;
        word-break: break-word;
        word-wrap: normal;
        img {
            width: 100%;
        }
        span {
            width: 100%;
        }
        hr {
            background: transparent;
        }
        textarea {
            resize: none;
            width: 100%;
            min-height: 200px;
        }
    }
`
const WritingExamCard = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 3%;
    box-sizing: border-box;
    img {
        width: 95%;
    }
    li {
        text-align: left;
    }
    p{
        img {
            width: 95%;
            max-width: 500px;
        }
    }
`
const TasksContainers = styled.div`
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`
const TaskCardDiv = styled(WhiteCard)`
    box-sizing: border-box;
    align-items: center;
    justify-content: left;
    border: white;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding-right: 5%;
    padding-top: 3%;
    padding-left: 5%;
    padding-bottom: 3%;
`
const InfoCardTop = styled.div`
    display: flex;
    flex-direction: row;
    strong {
        margin-bottom: 3%;
        margin-left: 10px;
    }
`
const InfoCardDiv = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding-left: 20px;
    border-left: 10px solid ${props => props.color};
    strong {
        margin-bottom: 3%;
    }
`
const LabelRadio = styled.label<{ color: string }>`
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    height: 50%;
    margin-top: 1%;
    input[type=radio] {
        -webkit-appearance: none;
        appearance: none;
        background: #FFFFFF;
        border: 0.15em solid #AAAAAA;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin: 0;
        width: 1.15em;
        height: 1.15em;
        display: grid;
        place-content: center;
        transform: translateY(-0.075em);
        ::before {
            content: "";
            width: 0.45em;
            height: 0.45em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1.40em 1em #FFFFFF;
            background: ${props => props.color};
            border: 0.15em solid ${props => props.color};
        }
        :checked::before{
            transform: scale(1.40);
        }
    }
`
const CircularInputCheckbox = styled.input`
    border-radius: 999px;
`
const Label = styled.label`
    display:flex;
    flex-direction: row;
    margin-bottom: 10px;
`
const WritingTest = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
`
const SpeakingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
`
const SpeakingExamCard = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 100%;
    height: auto;
    min-height: 80%;
    margin-bottom: 20px;
    padding: 5%;
    box-sizing: border-box;
    img {
        width: 95%;
    }
    audio {
        margin-bottom: 10px;
    }
`
const VoiceRecorder = styled(WhiteCard)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    min-height: 30px;
    padding: 1% 5%;
    box-sizing: border-box;
    button {
        height: 80%;
    }
`
const RecordTimeSpan = styled.div`
    display: flex;
    flex-direction: row;
    h4{
       padding: 0 3%; 
    }
`