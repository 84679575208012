//* External imports
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {VictoryLabel, VictoryPie} from "victory"

//? Donut chart (pie chart without middle) template. Simple look with two data values.
//? Two % labels. One for success % in the middle and another on the right with fail %.
//! It's called simple because how it's looks.

type Props = {
    colorScale: Array<string>
    y: number,
    passed: string,
    labelValue: number | string
    labelValue2?: string
    labelValue3?: string
}

const DonutChartSimpleMod = (props: Props): JSX.Element => {
    const {t} = useTranslation("translation", {keyPrefix: "donutChartMod"})
    const width = 700;
    const height = 980;
    return(
        <svg viewBox={`0 0 ${width} ${height}`}>
            <g transform="translate(0, 300)">
                <VictoryPie
                    standalone={false}
                    width={width} height={500}
                    data={[
                        {x: 1, y: 10-props.y},
                        {x: 2, y: props.y}
                    ]}
                    colorScale={props.colorScale}
                    padAngle={2}
                    style={{labels: {fill: "transparent"}}}
                    innerRadius={160}
                    animate={{duration: 2000}}
                />
                <g transform="translate(250, -220)">
                    <ForeignObject>
                        {props.passed}
                    </ForeignObject>
                </g>
                <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={props.labelValue2 ? props.labelValue2 : t("Total Score") ?? ""}
                    x={width/2}
                    y={-50}
                    style={[
                        {fontSize: 70, fontWeight: 700 , fontFamily: "'Familjen Grotesk', sans-serif"},
                    ]}
                />
                <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={`${props.labelValue}`}
                    x={width/2}
                    y={height-735}
                    style={[
                        {fontSize: 100, fontFamily: "'Familjen Grotesk', sans-serif"},
                    ]}
                />
                <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={props.labelValue3 ? props.labelValue3 : [`${t("Minimun score to pass")}:`, "60%"]}
                    x={width/2}
                    y={(height/2)+60}
                    style={[
                        {fontSize: 50, fontFamily: "'Familjen Grotesk', sans-serif"},
                        {fontSize: 70, fontFamily: "'Familjen Grotesk', sans-serif"}
                    ]}
                />
            </g>
        </svg>
    )
}

export default DonutChartSimpleMod;

const ForeignObject = styled.foreignObject`
    background: #0B8EAB;
    color: white;
    border-radius: 10px;
    width: 200px;
    height: 70px;
    font-size: 50px;
    text-align: center;
`