//* External Imports
import React from "react"
import styled from "styled-components";

export const Button = styled.button<{color?: string, state?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.state === true ? "#B2AAAA" : (props.color ?? props.theme.langooBlue)};
    border: 1px solid ${props => props.state === true ? "#B2AAAA" : (props.color ?? props.theme.langooBlue)};
    color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    cursor: pointer;
`