//* External Imports
import React, { useEffect, useMemo, useState }  from "react";
import styled from "styled-components";

//? Internal Imports
//? Utils
import { secsToTimerHours } from "../utils/secsToTimerHours";

/** Timer component. Start, stop & change timer value*/
const LangooTimer = (props: {timeMinutes: number, setWorker: React.Dispatch<React.SetStateAction<Worker | undefined>>, setEnded?: React.Dispatch<React.SetStateAction<boolean>>, setTimeAccumulation?: React.Dispatch<React.SetStateAction<number>>}) => {
    const timerWorker: Worker = useMemo(() => new Worker(new URL("../webWorkers/timerCounter", import.meta.url)), [])
    const [seconds, setSeconds] = useState<number>(10)
    useEffect(() => {
        if(window.Worker){
            props.setWorker(timerWorker)
            timerWorker.postMessage("startTimer")
            timerWorker.onmessage = (e: MessageEvent<string>) => {
                setSeconds((prev) => prev-Number(e.data))
                if(props.setTimeAccumulation) {
                    props.setTimeAccumulation((prev) => prev+Number(e.data))
                }
            }
        }
    }, [timerWorker])
    useEffect(() => {
        setSeconds(props.timeMinutes * 60)
        timerWorker.postMessage("stopTimer")
        timerWorker.postMessage("startTimer")
    }, [props.timeMinutes])
    useEffect(() => {
        if(seconds === 0){
            if(window.Worker){
                timerWorker.postMessage("stopTimer")
            }
            if(props.setEnded){
                props.setEnded(true)
            }
        }
    }, [seconds])
    return (
        <div>
            <TextColor> {secsToTimerHours(seconds > 0 ? seconds : 0)}</TextColor>
        </div>
    )
}

export default LangooTimer;

const TextColor = styled.strong`
    color: ${props => props.theme.langooBlue};
    margin-left: 10px;
    font-size: 20px;
`