import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeError, setErrorStatus } from "../redux/hook/useReducer";

type StoredErrorInfo = {
    loadError: boolean
    sendError: boolean
}

/** Generic Error JSX Component */
const ErrorComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const storedInfo: StoredErrorInfo = useSelector<StoredErrorInfo, StoredErrorInfo>((state: StoredErrorInfo) => state)
    useEffect(() => {
        if(storedInfo.loadError){
            dispatch(closeError())
            navigate("/", {replace: true})
        }else{
            dispatch(setErrorStatus(true))
        }
    }, [])
    return (
        <MainDiv>
            <Ohno>Oh no!</Ohno>
            <ErrorDiv>There has been a error. Please try again later</ErrorDiv>
        </MainDiv>
    )
}
export default ErrorComponent

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`
const Ohno = styled.div`
    color: ${props => props.theme.langooBlue};
    font-size: 60px;
    font-weight: 700;
`
const ErrorDiv = styled.div`
    font-weight: 700;
    font-size: 25px;
`

