// Type Imports
import { DefaultTheme } from "styled-components";

export const langooTheme: DefaultTheme = {
	fontFamily: "'Familjen Grotesk', sans-serif",
	fontColor:  "#454545",
	defaultBackgroundColor: "#F5F5F5",
	langooBlue: "#0B8EAB",
	langooRed: "#F93E63",
	langooYellow: "#FFC152",
	langooLightBlue: "#A2D9E7",
	langooBlueRgb: "rgb(11, 142, 171)",
	langooRedRgb: "rgb(249, 62, 99)",
	langooYellowRgb: "rgb(255, 193, 82)",
	langooLightBlueRgb: "rgb(162, 217, 231)",
	lightGrey: 	"#D9D9D9",
	buttonDisabled: "#B2AAAA",
    basicBoxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    basicInsetBoxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
    clickedButtonColor: "#B3A9A9"
}
