//* External Imports
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
//* Hooks
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Types Imports
import { UserType } from "../types/UserTypes";
import { CompletedExercise } from "../types/UserTypes";
import { ExerciseType, Flashcard, LearningPathCompetencyType, LearningPathExercises, ProgBarInfo } from "../types/GeneralTypes";

//? Internal Imports 
//? JSX Components
import ProgressBar from "../components/progressBar";
import Loader from "../components/loader";
import TaskExam from "../components/taskExam";
import { Desktop } from "../responsive/mediaQueriesComponents";
//? Hooks
import { setMainStates } from "../redux/hook/useReducer";
//? Methods
import { isMobile, isTablet } from "../responsive/mediaQueriesStates";
//? Styled-Components
import { WhiteCard } from "../styles/WhiteCard";
import { VerticalDecorationLine } from "../styles/decorationLines/VerticalDecorationLine";

//! Images
import arrow from "../assets/icons/arrow.svg"
import deleLogo from "../assets/affiliatedLogos/deleLogo.png"
import cambridgeLogo from "../assets/affiliatedLogos/cambridgeLogo.png"
import { shortMonthNameToFullName } from "../utils/shortMonthNameToFullName";
import { objectToArrayByCertificactionAndLevel } from "../utils/objectToArrayByCertificationAndLevel";
import { globalErrorHandler } from "../axios/globalErrorHandler";
import { useNavigate } from "react-router-dom";

//? First thing user sees after login. Main stadistics and such. 

type PropType = {
    user: UserType
    aux: boolean;
    setStudentExe: React.Dispatch<React.SetStateAction<CompletedExercise[] | undefined>>
    setLearningPathPercent: React.Dispatch<React.SetStateAction<{
        reading: number;
        listening: number;
        speaking: number;
        writing: number;
    }>>
}

const defaultProgBar: ProgBarInfo = {
    reading: 0,
    speaking: 0,
    listening: 0,
    writing: 0
}

const Landing = (props: PropType): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileState = isMobile()
    const isTabletState = isTablet()
    //i18next
    const { t } = useTranslation("translation", { keyPrefix: "landing" })
    //state variables
    const [goBack, setGoBack] = useState<boolean>(props.aux)
    const [competencyHours, setCompetencyHours] = useState<ProgBarInfo>(defaultProgBar)
    const [loading, setLoading] = useState<boolean>(true)
    const [learningPathExercises, setLearningPathExercises] = useState<LearningPathCompetencyType[]>()
    const [learningPathFlashcards, setLearningPathFlashcards] = useState<Flashcard>()
    const [toExam, setToExam] = useState<boolean>(false)
    const [actualExe, setActualExe] = useState<ExerciseType>()
    
    useEffect(() => {
        dispatch(setMainStates(new Array<boolean>(7).fill(true).fill(false, 0, 6)))
        axios.get<LearningPathExercises>(`${process.env.REACT_APP_API_URL}/learningPath/${props.user.id}`).then((response) => {
            const competencyToArray = objectToArrayByCertificactionAndLevel(response.data, props.user.certification, props.user.certification_level) as LearningPathCompetencyType[]
            setLearningPathExercises(competencyToArray)
            props.setLearningPathPercent({
                reading: response.data.reading.percent,
                listening: response.data.listening.percent,
                speaking: response.data.speaking.percent,
                writing: response.data.writing.percent
            })
            axios.get<ProgBarInfo>(`${process.env.REACT_APP_API_URL}/numHours/${props.user.id}`).then((response) => {
                setCompetencyHours(response.data)
                axios.get<Flashcard>(`${process.env.REACT_APP_API_URL}/learningPath/flashcards/${props.user.id}`).then((response) => {
                    setLearningPathFlashcards(response.data)
                    setGoBack(true)
                    setLoading(false)
                }).catch(() => globalErrorHandler(dispatch, navigate))
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])

    /** Handle onClick event of a flashcard */
    const onClickFlashCard = async(id_card: number) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/learningPath/flashcards/${id_card}/${props.user.id}`).catch(() => globalErrorHandler(dispatch, navigate))
        axios.get<Flashcard>(`${process.env.REACT_APP_API_URL}/learningPath/flashcards/${props.user.id}`).then((response) => {
            setLearningPathFlashcards(response.data)
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }

    if (loading) {
        return <Loader loading={loading} />
    }
    return (
        <>
            {goBack &&
                <>
                    {!toExam &&
                        <MainDiv state={isMobileState}>
                            <TitleContainer>
                                <span>{t("Overview")}</span>
                                {`${t("Your last study session was")}... ${t(shortMonthNameToFullName(new Date(props.user.last_connection).toUTCString().split(" ")[2]))} ${new Date(props.user.last_connection).toUTCString().split(" ")[1]}`}
                            </TitleContainer>
                            <MiddleCard>
                                <Desktop>
                                    <ImgContainer>
                                        <img src={props.user.certification === "DELE" ? deleLogo : cambridgeLogo} />
                                    </ImgContainer>
                                    <DecorationLine color="#D3CFCF" />
                                    <MiniTitleContainer>
                                        <span>{t("Level")}</span>
                                        {props.user.certification_level}
                                    </MiniTitleContainer>
                                    <DecorationLine color="#D3CFCF" />
                                </Desktop>
                                <MiniTitleContainer>
                                    <span>{t("Study goal")}</span>
                                    {props.user.num_total_hours ?? 0} {t("hours/week")}
                                </MiniTitleContainer>
                                <DecorationLine color="#D3CFCF" />
                                <MiniTitleContainer>
                                    <span>{t("Time Spent")}</span>
                                    {`${Object.values(competencyHours).reduce((prev, acc) => prev + acc, 0)} ${t("hours")}`}
                                </MiniTitleContainer>
                            </MiddleCard>
                            {learningPathExercises && <>
                                <TitleContainer>
                                    <span>{t("Learning Path")}</span>
                                    {t("Ready to take your learning to the next level? Check out our customized page and find out which exercises you need to focus on to keep progressing. Pass each test and unlock our Mock exams to boost your confidence and take your skills to the next level. Let's put your knowledge to the test!")}
                                </TitleContainer>
                                <Desktop>
                                    <LearningPathContainer>
                                        {Object.values(learningPathExercises).map((elem, i) => {
                                            const beingCorrected = ((elem.beingCorrected !== undefined) && (elem.beingCorrected === true))
                                            const colors = elem.exercise.exercise_type === "Reading" ? { transparency: "rgba(11, 142, 171, 0.8)", solid: "#0B8EAB", filter: "invert(53%) sepia(89%) saturate(3173%) hue-rotate(158deg) brightness(82%) contrast(91%)" }
                                                : elem.exercise.exercise_type === "Writing" ? { transparency: "rgba(249, 62, 99, 0.8)", solid: "#F93E63", filter: "invert(34%) sepia(71%) saturate(2495%) hue-rotate(326deg) brightness(99%) contrast(97%)" }
                                                    : elem.exercise.exercise_type === "Speaking" ? { transparency: "rgba(255, 193, 82, 0.8)", solid: "#FFC152", filter: "invert(66%) sepia(83%) saturate(308%) hue-rotate(357deg) brightness(101%) contrast(102%)" }
                                                        : { transparency: "rgba(162, 217, 231, 0.8)", solid: "#A2D9E7", filter: "invert(80%) sepia(28%) saturate(303%) hue-rotate(145deg) brightness(96%) contrast(97%)" }
                                            return <LearningPathCompetency key={i}>
                                                <LearningPathInfo>
                                                    <LearningPathCTitle color={colors.transparency}>{t(`${elem.exercise.exercise_type}`)}</LearningPathCTitle>
                                                    <ProgressBar completeTasks={elem.percent} color={colors.solid} />
                                                </LearningPathInfo>
                                                {elem.finished === undefined ?
                                                    <LearningPathCard state={beingCorrected}>
                                                        <VerticalDecorationLine color={colors.solid} style={{filter: beingCorrected ? "blur(1.5px)" : "none"}}/>
                                                        <LearningPathContent state={beingCorrected}>
                                                            {`${t("Part")} ${(elem.exercise as ExerciseType).task_part} - ${(elem.exercise as ExerciseType).task_type}`}
                                                            <span>
                                                                {t("Let’s practise some")} {t(`${elem.exercise.exercise_type}`)}! <br/>
                                                                {t("This type of Task needs some work")}
                                                            </span>
                                                        </LearningPathContent>
                                                                {beingCorrected &&
                                                                    <BeingCorrectedLabel color={colors.solid}>
                                                                        {t("Your")} {t(`${elem.exercise.exercise_type}`)} {t("exercise is being corrected")}!
                                                                    </BeingCorrectedLabel>
                                                                }
                                                        {!beingCorrected && <button onClick={() => [setActualExe(elem.exercise as ExerciseType), setToExam(true)]}><img src={arrow} style={{ width: "20px", filter: colors.filter }} /></button>}
                                                    </LearningPathCard>
                                                    :
                                                    elem.finished === true && <LearningPathCustomCard color={colors.solid}>
                                                        <CompletedLabel color={colors.solid}>{t("Completed")}!</CompletedLabel>
                                                        <LearningPathCustomContent color={colors.solid}>
                                                            <span>{t("Congratulations")}!</span>
                                                            {t("You have completed all the recommended")} {t(`${elem.exercise.exercise_type}`)} {t("exercises for this week")}.
                                                        </LearningPathCustomContent>
                                                    </LearningPathCustomCard>
                                                }
                                            </LearningPathCompetency>
                                        })}
                                    </LearningPathContainer>
                                </Desktop>
                                {/*----------Responsive---------*/}
                                {(isMobileState || isTabletState) &&
                                    <LearningPathResponsiveContainer>
                                        {Object.values(learningPathExercises).map((elem, i) => {
                                            const beingCorrected = ((elem.beingCorrected !== undefined) && (elem.beingCorrected === true))
                                            const colors = elem.exercise.exercise_type === "Reading" ? { transparency: "rgba(11, 142, 171, 0.8)", solid: "#0B8EAB", filter: "invert(53%) sepia(89%) saturate(3173%) hue-rotate(158deg) brightness(82%) contrast(91%)" }
                                                : elem.exercise.exercise_type === "Writing" ? { transparency: "rgba(249, 62, 99, 0.8)", solid: "#F93E63", filter: "invert(34%) sepia(71%) saturate(2495%) hue-rotate(326deg) brightness(99%) contrast(97%)" }
                                                    : elem.exercise.exercise_type === "Speaking" ? { transparency: "rgba(255, 193, 82, 0.8)", solid: "#FFC152", filter: "invert(66%) sepia(83%) saturate(308%) hue-rotate(357deg) brightness(101%) contrast(102%)" }
                                                        : { transparency: "rgba(162, 217, 231, 0.8)", solid: "#A2D9E7", filter: "invert(80%) sepia(28%) saturate(303%) hue-rotate(145deg) brightness(96%) contrast(97%)" }
                                            return <LearnigPathCompetencyResponsive key={i}>
                                                <LearningPathInfo>
                                                    <LearningPathCTitle color={colors.transparency}>{t(`${elem.exercise.exercise_type}`)}</LearningPathCTitle>
                                                    <ProgressBar completeTasks={elem.percent} color={colors.solid} />
                                                </LearningPathInfo>
                                                {elem.finished === undefined ?
                                                    <LearningPathCard state={beingCorrected}>
                                                        {!beingCorrected && <VerticalDecorationLine color={colors.solid} />}
                                                        <LearningPathContent state={beingCorrected} state2={true}>
                                                            {`${t("Task")} ${(elem.exercise as ExerciseType).task_part}`}
                                                            <span>
                                                            {t("Let’s practise some")} {t(`${elem.exercise.exercise_type}`)}! <br/>
                                                                {t("This type of Task needs some work")}
                                                            </span>
                                                        </LearningPathContent>
                                                                {beingCorrected &&
                                                                    <BeingCorrectedLabel color={colors.solid}>
                                                                        {t("Your")} {t(`${elem.exercise.exercise_type}`)} {t("is being corrected")}!
                                                                    </BeingCorrectedLabel>
                                                                }
                                                        {!beingCorrected && <button onClick={() => [setActualExe(elem.exercise as ExerciseType), setToExam(true)]}><img src={arrow} style={{ width: "20px", filter: colors.filter }} /></button>}
                                                    </LearningPathCard>
                                                    :
                                                    elem.finished === true && <LearningPathCustomCard color={colors.solid}>
                                                        <CompletedLabel color={colors.solid}>{t("Completed")}!</CompletedLabel>
                                                        <LearningPathCustomContent color={colors.solid}>
                                                            <span>{t("Congratulations")}!</span>
                                                            {t("You have completed all the recommended")} {t(`${elem.exercise.exercise_type}`)} {t("exercises for this week")}.
                                                        </LearningPathCustomContent>
                                                    </LearningPathCustomCard>
                                                }
                                            </LearnigPathCompetencyResponsive>
                                        })}
                                    </LearningPathResponsiveContainer>
                                }
                            </>
                            }
                            {learningPathFlashcards && learningPathFlashcards.length > 0 && <>
                                <TitleContainer>
                                    <span>{t("Flashcards")}</span>
                                </TitleContainer>
                                <CardDisplay>
                                    {learningPathFlashcards.slice(0, 4).map((elem) => 
                                        <A href={elem.link} target="_blank" state={(isMobileState || isTabletState)} onClick={() => onClickFlashCard(elem.id_card)}>
                                            <Card src={`${process.env.REACT_APP_FLASHPREFIX}/${elem.image}`} alt={elem.image} />
                                            <Desktop><div>{elem.description}</div></Desktop>
                                        </A>
                                    )}
                                </CardDisplay>
                            </>
                            }
                        </MainDiv>
                    }
                    {toExam && actualExe &&
                        <TaskExam exercise={actualExe} mainColor={
                            actualExe.exercise_type === "Reading" ? "#0B8EAB"
                                : actualExe.exercise_type === "Writing" ? "#F93E63"
                                    : actualExe.exercise_type === "Speaking" ? "#FFC152"
                                        : "#A2D9E7"
                        }
                            id_user={props.user.id}
                            setToExam={setToExam}
                            setStudentExe={props.setStudentExe}
                            setLearningPathExercises={setLearningPathExercises}
                            certification_level={props.user.certification_level}
                            certification={props.user.certification}
                            setLearningPathPercent={props.setLearningPathPercent}
                        />
                    }
                </>
            }
        </>
    )

}

export default Landing;

//Global components
const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #F5F5F5;
    width: ${props => props.state ? "95%" : "80%"};
    height: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
    max-width: 1900px;
    gap: 30px;
    margin-top: 30px;
    button {
        cursor: pointer;
    }
`
const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    gap: 10px;
    span {
        font-size: 28px;
        color: ${props => props.theme.langooBlue};
    }
`
const ImgContainer = styled.div`
    width: 10%;
    img {
        width: 30px;
        height: 25.33px;
    }
`
const MiniTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    width: 24%;
    min-width: fit-content;
    gap: 10px;
    //border-left: 0.5px solid #D3CFCF;
    span {
        font-size: 16px;
        color: ${props => props.theme.langooBlue};
    }
`
const DecorationLine = styled.div<{ color: string }>`
    min-width: 0.5px;
    height: 50px;
    background: ${props => props.color};
    margin-left: 10px;
`
const MiddleCard = styled(WhiteCard)`
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    padding: 10px;
`
const LearningPathContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`
const LearningPathResponsiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
`
const LearnigPathCompetencyResponsive = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    height: auto;
    gap: 20px;
`
const LearningPathCompetency = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23%;
    gap: 20px;
`
const LearningPathInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
`
const LearningPathCTitle = styled.span<{ color: string }>`
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.color};
    text-shadow: 1px 4px 6px #f5f5f5, 0 0 0 #000, 1px 4px 6px #f5f5f5;
`
const LearningPathCard = styled(WhiteCard) <{ state: boolean }>`
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 0px 30px 10px;
    background: ${props => props.state ? props.theme.lightGrey : "#FFFFFF"};
    z-index: 0;
    button {
        align-self: flex-end;
        border: 1px solid transparent;
        background: transparent;
    }
`
const BeingCorrectedLabel = styled.div<{color: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    height: fit-content;
    width: 60%;
    padding: 10px;
    left: 0;
    top: 16px;
    bottom: 0;
    right: 0;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 700;
    background: ${props => props.color};
`
const LearningPathCustomCard = styled(WhiteCard) <{ color: string }>`
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #EEEEEE;
    overflow: hidden;
    padding: 30px 0px 30px 10px;
    font-weight: 700;
    font-size: 15px;
    top: 0px;
    z-index: 0;
`
const CompletedLabel = styled.div<{ color: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: ${props => props.color};
    transform: rotate(42deg);
    font-weight: normal;
    font-size: 14px;
    width: 300px;
    height: 30px;
    top: 15px;
    right: -120px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    color: white;
`
const LearningPathCustomContent = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    //text-align: left;
    font-size: 14px;
    font-weight: 700;
    margin-right: 15px;
    gap: 5px;
    span {
        font-size: 18px;
        color: ${props => props.color}
    }
`
const LearningPathContent = styled.div<{state: boolean, state2?: boolean}>`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin-left: 7px;
    align-self: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: ${props => (props.state2 && props.state2 === true) ? "30px 0px 30px 10px" : "0px"};
    height: 100%;
    width: 100%;
    filter: ${props => props.state ? "blur(1.5px)" : "none"};
    span {
        font-size: 13px;
    }
`
const CardDisplay = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`
const Card = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-shadow: ${props => props.theme.basicBoxShadow};
    width: 100%;
    height: 100%;
    transition: 0.45s;
`
const A = styled.a<{state: boolean}>`
    position: relative;
    width: ${props => props.state ? "45%" : "20%"};
    text-decoration: none;
    color: ${props => props.theme.fontColor};
    font-size: 13px; 
    height: 100%;
    transition: 0.45s;
    div {
        transition: 0.45s;
        display: none;
        position: absolute;
        width: 95%;
        border-radius: 10px;
        height: 95%;
        box-sizing: border-box;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        color: white;
    }
    &:hover {
        ${Card} {
            filter: blur(0.8px);
        };
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
        }
    }
`
