//* External Imports
import React from "react"

//? Internal Imports
//? Utils
import { secsToTimerMinutes } from "../../utils/secsToTimerMinutes"

type AudioDurationProps = {
    audioTime: number
}

const AudioTimer = (props: AudioDurationProps) => (
    <div>
        {secsToTimerMinutes(props.audioTime)}
    </div>
)

export default AudioTimer
