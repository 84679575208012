//* External imports
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ExamTemplate from "../components/examTemplate";
import axios from "axios";
import Modal from "react-modal"
//* Hooks
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Types Imports
import { UserType } from "../types/UserTypes";
import { ExerciseType, QuestionSizeType } from "../types/GeneralTypes";
import { ExamType } from "../types/GeneralTypes";

//? Internal Imports
//? JSX Components
import ProficiencyTestResult from "../components/reports/proficiencyTestResult";
import Loader from "../components/loader";
import AudioButton from "../components/audioButton";
//? Hooks
import { setMainStates } from "../redux/hook/useReducer";
//? Methods
import { isMobile } from "../responsive/mediaQueriesStates";
//? Utils
import { changeBooleanArrayValueByIndex } from "../utils/changeBooleanArrayValueByIndex";
//? Styled-components
import { Button } from "../styles/buttons/Button";
import { WhiteCard } from "../styles/WhiteCard";
import { SimpleTitle } from "../styles/SimpleTitle";
import { globalErrorHandler } from "../axios/globalErrorHandler";
import { useNavigate } from "react-router-dom";

//? Main component for Study Plan, competence test subdivision.

Modal.setAppElement("body")

type Props = {
    corrected: boolean;
    exam: ExamType
    mainColor: string
    user: UserType;
    aux: boolean;
    screenStates: boolean[]
}

const modalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        maxWidth: "90%",
        height: "fit-content",
    },
    overlay: {
        zIndex:1000
    }
}

/** Main Proficiency Test components. Contains Proficiency Exam & Proficiency Exam Report. 
 *  @param {boolean} corrected - Specify if the exam has been corrected
 *  @param {ExamType} exam - Exam information
 *  @param {string} mainColor - Main render color
 *  @param {UserType} user - Logged user info
 *  @param {boolean} aux - State value that allow this component to render 
 *  @param {boolean[]} screenStates - Values that specify what component renders.
*/
const ProficiencyTest = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileState = isMobile()
    const { t } = useTranslation("translation", {keyPrefix: "proficiencyTest"})
    const [competencyTest, setCompetencyTest] = useState<boolean>(false)
    const [questions, setQuestions] = useState<QuestionSizeType>()
    const [loading, setLoading] = useState<boolean>(true)
    const [modal, setModal] = useState<boolean>(true)
    useEffect(() => { 
        dispatch(setMainStates(changeBooleanArrayValueByIndex(props.screenStates, 1)))
        window.addEventListener('beforeunload', alertUser);
        window.addEventListener('popstate', alertUser);
        return () => {
          window.removeEventListener('beforeunload', alertUser);
          window.removeEventListener('popstate', alertUser);
        };
    }, [])
    useEffect(() => { setCompetencyTest(false); }, [props.aux])
    useEffect(() => {
        axios.post<QuestionSizeType>(`${process.env.REACT_APP_API_URL}/questions`, {
            id: props.exam.id_exam
        }).then((response) => {
            setQuestions(response.data)
            axios.get(`${process.env.REACT_APP_API_URL}/exams/completed/${props.exam.id_exam}/${props.exam.exam_type}/${props.user.id}`).then((response) => {
                if(response.data.data && !response.data.data.completed){
                    setModal(false)
                    setLoading(false)
                }
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [competencyTest])
    
    /** Send an alert when exiting exam */
    const alertUser = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
        return "";
    };
    
    if (loading) {
        return <Loader loading={loading}/>
    }
    return (
        <MainDiv state={isMobileState}>
            {!competencyTest && !props.corrected &&
                <>
                    <UpperTextDiv>
                        <SimpleTitle>{t("Welcome to our Language proficiency test")}</SimpleTitle>
                        <span>{t("Take the test and verify your level")}</span>
                    </UpperTextDiv>
                    <CardsContainer state={isMobileState}>
                        <CardBackground>
                            <ContentCardDiv >
                                <strong>{t("Put yourself to the test")}</strong> <br /><br />
                                <DecorationLine />
                                <div>
                                    {t("Measure your reading, writing, listening and speaking skills with this 50 min test.")}
                                    <br /><br />
                                    {t("You will find 4 exercises to prove your knowledge in each of the 4 skills mentioned. For each part, read the instructions carefully and complete the exercises.")}
                                    <br /><br />
                                    {t("Your performance in this test will create your personalised learning path which will accompany and guide you towards your goals.")}
                                    <br /><br />
                                    {t("Good luck!")}
                                </div>
                            </ContentCardDiv>
                        </CardBackground>
                        <CardBackground>
                            <ContentCardDiv style={{ marginTop: '3%' }}>
                                <strong>{t("Proficiency Test")}</strong> <br /><br />
                                <DecorationLine />
                                <div>
                                    {t("In this test we will evaluate your ability in the four skills assessed in the")} {props.user.certification === "DELE" ? `${t("Exam")} ${props.user.certification}` : `${props.user.certification} ${t("Exam")}`}.
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', height: "60%" }}><CardButton onClick={() => setCompetencyTest(true)}>{t("Let's start")}</CardButton></div>
                            </ContentCardDiv>
                        </CardBackground>
                    </CardsContainer>
                    <Modal
                        isOpen={modal}
                        style={modalStyle}
                    >
                        <AudioButton setModal={setModal}/>
                    </Modal>
                </>
            }
            {competencyTest && questions && !props.corrected &&
                <ExamTemplate exam={props.exam} mainColor={props.mainColor} userData={props.user} exerciseQuestions={questions} />
            }
            {props.corrected && 
                <ProficiencyTestResult userData={props.user} exam={props.exam}/>
            }
        </MainDiv>
    )
}

export default ProficiencyTest;

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "95%" : "80%"};
    margin-bottom: 60px;
    background: #F5F5F5;
    margin-top: 100px;
`
const UpperTextDiv = styled.div`
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
    box-sizing: border-box;
    text-align: left;
    span {
        color: ${props => props.theme.langooBlue};
        font-size: 18px;
        font-weight: 700;
    }
`
const CardsContainer = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    gap: ${props => props.state ? "20px" : "0px"};
    width: 100%;
    box-sizing: border-box;
    height: 70%;
    button {
        margin-top: ${props => props.state ? "20px" : "0px"};
    }
`
const CardBackground = styled(WhiteCard)`
    flex-direction: column;
    margin-right: 5%;
    padding: 15px;
    box-sizing: border-box;
`
const ContentCardDiv = styled.div`
    left:0;
    right: 0;
    height: 100%;
    padding: 5%;
    font-size: 18px;
    text-align: left;
`
const CardButton = styled(Button)`
    align-self: flex-end;
    height: 40px;
    padding: 3% 15%;
`
const DecorationLine = styled.div`
    border-bottom: 1.5px solid #2122225a;
    min-width: 250px;
    margin-bottom: 15px;
    text-decoration: underline;
`