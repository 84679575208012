import { PutObjectCommand, PutObjectCommandInput } from "@aws-sdk/client-s3";
import { s3Client } from "../lib/s3Client";

const uploadAudio = async(blob: Blob, name: string): Promise<string | undefined> => {
    try{
        const keyAudioName = `${name.split("/")[name.split("/").length-1]}.webm`
        const key = process.env.REACT_APP_AWS_AUDIO_REKEY ? `${process.env.REACT_APP_AWS_AUDIO_REKEY}/${keyAudioName}` : keyAudioName
        const params: PutObjectCommandInput = {
            Bucket: process.env.REACT_APP_AWS_AUDIO_BUCKET,
            Key: key,
            Body: blob
        }
        const data = await s3Client.send(new PutObjectCommand(params))
        return data ? `${process.env.REACT_APP_AWS_AUDIO_URL}/${name.split("/")[name.split("/").length-1]}.webm` : "";
    }catch(e){
        console.log(e)
    }
}
export default uploadAudio;