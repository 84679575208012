/** 
 * Returns a string with a timer format hh:mm:ss from seconds 
 * @param {number} secs - Seconds
*/
export const secsToTimerHours = (secs: number) => {
    const totalMinutes = Math.floor(secs / 60);

    const seconds = secs % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const returnedHours = hours < 10 ? `0${hours}` : `${hours}`
    return `${returnedHours}:${returnedMinutes}:${returnedSeconds}`;
}