//* External imports
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal"
import Draggable from "react-draggable";
//* Hooks
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

// Type imports
import { CompletedExercise } from "../types/UserTypes";
import { ExercisesType, ExerciseType } from "../types/GeneralTypes";

//? Internal Imports
//? JSX Components
import TaskExam from "../components/taskExam";
import TReportWS from "../components/reports/TReportWS";
import TaskReport from "../components/reports/taskReport";
//? Hooks
import { setMainStates } from "../redux/hook/useReducer";
//? Methdos
import { isMobile, isTablet } from "../responsive/mediaQueriesStates";
//? Utils
import { changeBooleanArrayValueByIndex } from "../utils/changeBooleanArrayValueByIndex";
//? Styled-Components
import { Button } from "../styles/buttons/Button";
import { SimpleTitle } from "../styles/SimpleTitle";
import { WhiteCard } from "../styles/WhiteCard";

//! Images
import arrowBlack from "../assets/icons/arrowBlack.png"
import infoIcon from "../assets/icons/info.png"
import modalButton1 from "../assets/others/classroom/modalButton1.png"
import modalButton2 from "../assets/others/classroom/modalButton2.png"
import modalButton3 from "../assets/others/classroom/modalButton3.png"
import modalButton4 from "../assets/others/classroom/modalButton4.png"
import largeArrow from "../assets/others/classroom/largeArrow.png"
import smallArrow from "../assets/others/classroom/smallArrow.png"
import taskToCom from "../assets/others/classroom/taskToCom.png"
import taskCom from "../assets/others/classroom/taskCom.png"
import backArrow from "../assets/icons/backArrow.png"

//? Main screen for Classroom. Show every task aviable.

type Props = {
    studentExe: CompletedExercise[],
    exercises: ExercisesType,
    goal: string,
    id_user: number
    resetClassroom: boolean;
    loadFromLanding?: number;
    screenStates: boolean[]
    setStudentExe: React.Dispatch<React.SetStateAction<CompletedExercise[] | undefined>>
}

Modal.setAppElement("body")

const ModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "482px",
        height: "541px",
        padding: "20px",
        marginRight: -"50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "50px 0",
        backgroundColor: "white",
    },
    overlay: {
        backgroundColor: "#45454570",
        zIndex: 1000
    }
}

const Classroom = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const isMobileState = isMobile()
    const isTabletState = isTablet()
    const { t } = useTranslation("translation", { keyPrefix: "classroom" })
    const [parts, setParts] = useState<number>(-1);
    const [toExam, setToExam] = useState<boolean>(false)
    const [competencyArray] = useState<ExercisesType>(props.exercises)
    const [competencySelect, setCompetencySelect] = useState<boolean[]>(new Array(props.exercises.length).map((_, i) => props.loadFromLanding ? (props.loadFromLanding === i ? true : false) : false))
    const [modal, setModal] = useState<boolean>(false);
    const [dragPos, setDragPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 })

    const [color, setColor] = useState<string>("") //Use in future refactor
    const [completedExe, setCompletedExe] = useState<CompletedExercise>();
    const [actualExe, setActualExe] = useState<ExerciseType>();
    //const [actualPosExe, setActualPosExe] = useState<ExerciseType>()
    const [exercisePosStates, setExercisePosStates] = useState<boolean[]>([])

    const auxRef = useRef<[HTMLDivElement | null]>([null])

    //Use for Modal component
    /** Changes modal state to true */
    const openModal = () => {
        setModal(true)
    }
    /** Changes modal state to false */
    const closeModal = () => {
        setModal(false)
    }
    /** Change draggable position on drag*/
    const draggableHandlers = (e: any, ui: any) => {
        const actualIndex = auxRef.current.filter((elem) => elem).findIndex((elem, i) => elem !== null && ((ui.x + (elem.getBoundingClientRect().width * i) + 30) > 0))
        //setActualPosExe(competencyArray[parts][actualIndex > -1 ? actualIndex : 0])
        setExercisePosStates(exercisePosStates.map((_, index) => index === actualIndex ? true : false))
        setDragPos({
            x: dragPos.x + ui.deltaX,
            y: 0,
        })
    }
    /** Change draggable position on click to the left */
    const draggableHandleButtonLeft = () => {
        const actualIndex = auxRef.current.filter((elem) => elem !== null).findIndex((elem, i) => elem !== null && (((dragPos.x + 480) + (elem.getBoundingClientRect().width * i) + (30 * (i+1))) >= 0))
        //setActualPosExe(competencyArray[competencySelect.findIndex((elem) => elem)].filter((elem) => elem.task_part === parts+1)[actualIndex > -1 ? actualIndex : 0])
        //console.log(competencyArray[competencySelect.findIndex((elem) => elem)].filter((elem) => elem.task_part === parts+1)[actualIndex > -1 ? actualIndex : 0])
        setExercisePosStates(exercisePosStates.map((_, index) => index === actualIndex ? true : false))
        setDragPos({
            x: dragPos.x % 480 === 0 ? dragPos.x + 480 : ((competencyArray[competencySelect.findIndex((elem) => elem)].filter((elem) => elem.task_part === parts+1).slice(0, actualIndex).length) * 480),
            y: 0
        })
    }
    /** Change draggable position on click to the right */
    const draggableHandleButtonRight = () => {
        const actualIndex = auxRef.current.filter((elem) => elem !== null).findIndex((elem, i) => (((dragPos.x - 480) + (elem!.getBoundingClientRect().width * i) + (30 * (i+1))) >= 0))
        //setActualPosExe(competencyArray[competencySelect.findIndex((elem) => elem)].filter((elem) => elem.task_part === parts+1)[actualIndex > -1 ? actualIndex : 0])
        setExercisePosStates(exercisePosStates.map((_, index) => index === actualIndex ? true : false))
        setDragPos({
            x: dragPos.x % 480 === 0 ? dragPos.x - 480 : ((competencyArray[competencySelect.findIndex((elem) => elem)].filter((elem) => elem.task_part === parts+1).slice(0, actualIndex).length) * (-480)),
            y: 0
        })
    }
    /** Change draggable position to element at index on click button
     * @param {number} index - Index of the element
     */
    const handlePosButtonPress = (index: number) => {
        setExercisePosStates(exercisePosStates.map((_, i) => i === index ? true : false))
        setDragPos({
            x: ((competencyArray[competencySelect.findIndex((elem) => elem)].filter((elem) => elem.task_part === parts+1).slice(0, index).length) * (-480)),
            y: 0
        })
    }
    /** Initialice parts useState array */
    const initPartsArray = (arr: ExerciseType[]) => {
        const baseArray: ExercisesType = [[]]
        arr.map((elem) => {
            if (elem.task_part > baseArray.length) {
                baseArray.push([elem])
            } else {
                baseArray[elem.task_part - 1].push(elem)
            }
        })
        return baseArray
    }

    useEffect(() => { dispatch(setMainStates(changeBooleanArrayValueByIndex(props.screenStates, 2))) }, [])
    useEffect(() => { setToExam(false) }, [props.resetClassroom])

    /** Change competencySelect state with an array with the element at index at true */
    const changeStates = (index: number) => {
        setCompetencySelect(competencyArray.map((_, i) => index === i ? true : false))
    }
    
    /** Change competencySelect, color & parts */
    const changeCompetencyButtonStates = (index: number, color: string) => {
        changeStates(index)
        setColor(color)
        setParts(-1)
    }
    /** Open every Part from a competency  */
    const onClickCompetencyButton = (color: string) => (
        <>
            <CompetencyDiv style={{ marginTop: "40px" }}>
                <strong><div style={{ marginLeft: "20px", marginTop: "3px" }}>{t("Choose a part")}</div></strong>
                <ButtonContainerDiv state={isMobileState}>
                    {competencyArray.map((elem, i) => {
                        if (competencySelect[i]) {
                            const competencyPartArray = initPartsArray(elem)
                            return competencyPartArray.map((_, j) => (
                                <ButtonCompetency state={parts === j || (parts === -1)} color={color} onClick={() => [setParts(j), auxRef.current = [null], setExercisePosStates([true, ...new Array(competencyArray[i].filter((elem) => elem.task_part === j+1).length-1).fill(false)]),setDragPos({ x: 0, y: 0 })]} key={i + j}>
                                    <>
                                        {`${t("Part")} ${j + 1}`} <br />
                                    </>
                                </ButtonCompetency>
                            ))
                        }
                    })}
                </ButtonContainerDiv>
            </CompetencyDiv>
            <TaskDiv>
                {competencySelect.map((_, i) => competencySelect[i] && parts > -1 && displayTasks(competencyArray[i], color))}
            </TaskDiv>
        </>
    )

    /** Generic task return function
     * @param {ExerciseType[]} arr - Array with exercises 
     * @param {string} color - Competency color  
     */
    const displayTasks = (arr: ExerciseType[], color: string) => {
        let counter = 0;
        auxRef.current=[null]
        return (
            <>
                <TaskInfoDiv>
                    <div>
                        <strong>
                            {t("Select an exercise")}
                        </strong>
                    </div>
                </TaskInfoDiv>
                <ButtonsDraggableWrapper>
                    <ArrowButton onClick={draggableHandleButtonLeft} disabled={dragPos.x === 0}><img src={backArrow}/></ArrowButton>
                    <DraggableWrapper state={isMobileState || isTabletState}>
                        <Draggable position={dragPos} onDrag={draggableHandlers} axis="x" scale={1.5} cancel="button">
                            <CardDivWrapper>
                                {arr.map((elem, i) => {
                                    if (elem.task_part === (parts + 1)) {
                                        counter++;
                                        const completed: CompletedExercise | undefined = props.studentExe.find((elem2) => elem2.id_exercise === elem.id_exercise)
                                        return (
                                            <TaskCardDiv state={completed ? true : false} key={parts + counter} ref={el => auxRef.current[i] = el}>
                                                <InfoCardDiv color={color}>
                                                    <TaskWrapper>
                                                        <strong>{`${t("Task")} ${counter}`}<br /></strong>
                                                        {completed && completed.passed !== null && (completed.text_written || completed.file_speaking || completed.corrected) &&
                                                            <PassFailDiv>
                                                                <PassFailBox state={completed.passed && completed.corrected} color={color}>{t("Pass")}</PassFailBox> <PassFailBox state={!completed.passed && completed.corrected} color={color}>{t("Fail")}</PassFailBox>
                                                            </PassFailDiv>
                                                        }
                                                    </TaskWrapper>
                                                    {elem.statement}
                                                    <div style={{ display: 'flex', height: '100%', justifyContent: 'flex-end', alignItems: "flex-end" }}>
                                                        <div style={{ display: 'flex', justifyContent: "center" }}>
                                                            <strong> {completed && (completed.text_written || completed.file_speaking || completed.corrected) ? (!completed.text_written && !completed.file_speaking) ? t("Repeat") : t("Review") : t("Let's Go")}</strong>
                                                            <button
                                                                className="button"
                                                                style={{ border: 'None', backgroundColor: 'transparent' }}
                                                                onClick={() => [
                                                                    setCompletedExe(completed && (completed.text_written || completed.file_speaking || completed.corrected) ? completed : undefined),
                                                                    setActualExe(elem),
                                                                    setColor(color),
                                                                    setToExam(true)
                                                                ]}><img src={arrowBlack} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </InfoCardDiv>
                                            </TaskCardDiv>
                                        )
                                    }
                                })}
                            </CardDivWrapper>
                        </Draggable>
                    </DraggableWrapper>
                    <ArrowButton onClick={draggableHandleButtonRight} disabled={dragPos.x === (-480 * ((arr.filter((elem) => elem.task_part === parts + 1).length) - 1))}><img src={backArrow} style={{transform: "rotate(180deg)"}}/></ArrowButton>
                </ButtonsDraggableWrapper>
                <TaskPositionWrapper>
                    {exercisePosStates.map((elem, i) => <TaskPositionDot state={elem} color={color} onClick={() => handlePosButtonPress(i)}/>)}
                </TaskPositionWrapper>
            </>
        )
    }
    
    return (
        <>
            {!toExam &&
                <MainDiv state={isMobileState}>
                    <ClassroomDiv>
                        <Title>{t("Classroom")}</Title>
                        <InfoContainer>
                            <img src={infoIcon} alt="infoIcon" /><button onClick={openModal}>{t("More information")}</button>
                        </InfoContainer>
                        <h2>
                            {`${t("According to your")} ${props.goal ?? 0} ${t("hour/week plan and your level test, we have created a study plan that will help you achieve your goal faster, once you finish your assignments you will see your progress in the dashboard.")}`}
                        </h2>
                    </ClassroomDiv>
                    <Modal
                        isOpen={modal}
                        onRequestClose={closeModal}
                        style={ModalStyle}
                        contentLabel="Info Modal"
                    >
                        <InnerModal>
                            <UpperModal>
                                <UpperLeftModal>
                                    <span style={{ color: "#0B8EAB", fontSize: "30px", fontWeight: 700 }}>{t("Classroom")}</span>
                                    <span style={{ color: "#A4A4A4", fontSize: "20px", fontWeight: 400 }}>{t("More information")}</span>
                                </UpperLeftModal>
                                <ModalYDiv>{t("Hey !")}</ModalYDiv>
                            </UpperModal>
                            <span style={{ fontSize: "20px", marginBottom: "10px" }}>{t("Understand the different states of our buttons")}</span>
                            <ModalRow>
                                <ModalColumn>
                                    {t("Original state")}
                                    <ImgButton src={modalButton1} alt="" width="111px" height="38px" />
                                </ModalColumn>
                                <img src={largeArrow} alt="" />
                                <ImgButton src={modalButton2} width="111px" height="38px" />
                            </ModalRow>
                            <ModalRow>
                                <ImgButton src={modalButton3} alt="" width="127px" height="44.72px" />
                                <img src={largeArrow} alt="" />
                                <ImgButton src={modalButton4} width="127px" height="44.72px" />
                            </ModalRow>
                            <ModalRow>
                                <ModalColumn>
                                    {t("Original state")}
                                    <ImgButton src={taskToCom} width="198px" height="97px" />
                                </ModalColumn>
                                <img src={smallArrow} />
                                <ModalColumn>
                                    {t("Completed state")}
                                    <ImgButton src={taskCom} width="198px" height="97px" />
                                </ModalColumn>
                            </ModalRow>
                        </InnerModal>
                    </Modal>
                    <CompetencyDiv>
                        <strong><div style={{ marginLeft: "20px", marginTop: "3px" }}>{t("Choose a competency")}</div></strong>
                        <ButtonContainerDiv state={isMobileState}>
                            {competencyArray.map((elem, i) => {
                                const color = elem[0].exercise_type === "Reading" ? "#0B8EAB" : (elem[0].exercise_type === "Writing" ? "#F93E63" : (elem[0].exercise_type === "Speaking" ? "#FFC152" : "#A2D9E7"))
                                return <ButtonCompetency state={competencySelect[i] || (!competencySelect.find((elem) => elem))} color={color} onClick={() => changeCompetencyButtonStates(i, color)} key={i + 6}>
                                    {t(elem[0].exercise_type)}
                                </ButtonCompetency>
                            })}
                        </ButtonContainerDiv>
                    </CompetencyDiv>
                    {competencySelect.map((_, i) => competencySelect[i] && onClickCompetencyButton(color))}
                </MainDiv>
            }
            {toExam && actualExe &&
                <>
                    {!completedExe ?
                        <TaskExam exercise={actualExe} mainColor={color} id_user={props.id_user} setToExam={setToExam} setStudentExe={props.setStudentExe} />
                        :
                        (completedExe.text_written || completedExe.file_speaking) ?
                            <TReportWS exercise={completedExe}
                                competency={actualExe.exercise_type === "Speaking" ? actualExe.exercise_type : "Writing"}
                                task={`Task ${actualExe.task_part}-${actualExe.task_type}`}
                                id_user={props.id_user} goBack={toExam} setGoBack={setToExam}
                            />
                            :
                            <TaskReport completedExercise={completedExe}
                                setToExam={setToExam} id_user={props.id_user} setStudentExe={props.setStudentExe}
                                goBack={toExam} setGoBack={setToExam}
                            />
                    }
                </>
            }
        </>
    )
}

export default Classroom

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${props => props.state ? "95%" : "80%"};
    height: 100%;
    background: #F5F5F5;
    padding-bottom: 5%;
`
const Title = styled(SimpleTitle)`
    margin-bottom: 20px;
`
const ClassroomDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    h2 {
        font-size: 18px;
    }
    h1 {
        font-size: 40px;
    }
    margin-bottom: 20px;
`
const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    color: #A4A4A4;
    button {
        background: transparent;
        border: transparent;
        color: #A4A4A4;
        text-decoration: underline;
    }
`
const InnerModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 50px 50px;
    border-top-left-radius: 50px 50px;
    background-color: white;
    border: 2px solid ${props => props.theme.langooBlue};
`
const UpperModal = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin: 20px;
`
const UpperLeftModal = styled.div`
    display: flex;
    flex-direction: column;
`
const ModalYDiv = styled.div`
    display: flex;
    align-self: start;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.langooYellow};
    border-radius: 40px 40px 40px 0px;
    font-size: 18px;
    width: 112px;
    height: 64px;
    left: 367px;
    top: -28px;
    text-align: center;
    color: ${props => props.theme.langooBlue};
    font-weight: 600;
    margin-left: 15px;
    margin-bottom: 40px;
`
const ModalRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
`
const ImgButton = styled.img<{ height: string, width: string }>`
    width: ${props => props.width};
    height: ${props => props.height};
`
const ModalColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    margin-bottom: 10px;
`
const CompetencyDiv = styled(WhiteCard)`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
`
const ButtonContainerDiv = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: ${props => props.state ? "wrap" : "normal"};
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    button {
        width: ${props => props.state ? "30%" : "20%"};
    }
`
const ButtonCompetency = styled(Button)`
    background: ${props => props.state === true ? (props.color ?? props.theme.langooBlue) : "#B2AAAA"};
    border: ${props => props.state === true ? (props.color ?? props.theme.langooBlue) : "#B2AAAA"};
    margin: 20px;
    width: 20%;
`
const TaskDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 20px;
`
const TaskInfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
`
const TaskCardDiv = styled(WhiteCard) <{ state: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    min-height: 150px;
    min-width: 450px;
    margin-right: 30px;
`
const InfoCardDiv = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 85%;
    height: auto;
    border-left: 9px solid ${props => props.color};
    padding-left: 20px;
    margin-left: 20px;
`
const TaskWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
`
const PassFailDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
`
const PassFailBox = styled.div<{ state: boolean, color: string }>`
    color: ${props => props.state ? props.color : "inherit"};
    padding: 2.5px 10px 2.5px 10px;
    font-weight: 700;
    font-size: 16px;
`
const ButtonsDraggableWrapper = styled.div`
    display: flex;
    width: 100%;
`
const DraggableWrapper = styled.div<{ state: boolean }>`
    display: flex;
    width: 100%;
    max-width: ${props => props.state ? "100vw" : "70vw"};
    height: auto;
    overflow: hidden;
    margin-top: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
`
const ArrowButton = styled(Button)`
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;
`
const CardDivWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`
const TaskPositionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
    width: 100%;
`
const TaskPositionDot = styled.button<{state: boolean, color: string}>`
    height: 14px;
    width: 14px;
    background: ${props => props.state ? props.color : "grey"};
    border: 1px solid transparent;
    border-radius: 50%;
`

