import { S3Client } from "@aws-sdk/client-s3";
// Set the AWS Region.
const REGION = process.env.REACT_APP_AWS_REGION; //e.g. "us-east-1"
const ACCESSKEYID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const SECRETACCESSKEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
// Create an Amazon S3 service client object.
const s3Client = new S3Client({ region: REGION, credentials: {
    accessKeyId: ACCESSKEYID ? ACCESSKEYID : "",
    secretAccessKey: SECRETACCESSKEY ? SECRETACCESSKEY : ""
} });
export { s3Client };