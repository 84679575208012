//* External imports
import React from "react"
import { useTranslation } from "react-i18next"
import { VictoryLabel, VictoryChart, VictoryPolarAxis, VictoryArea, VictoryContainer } from "victory"

type RadarProps = {
    data: Array<{
        total: number,
        completed: number
    }>
    radius: number
    color: string
}

const RadarChart = (props: RadarProps) => {
    const { t } = useTranslation("translation", { keyPrefix: "radarChart" })
    const dataFormat: Array<{ x: string, y: number }> = props.data.map((elem, i) => ({
        x: `Part ${i + 1}`,
        y: ((elem.completed * 100) / elem.total) / 100
    }))
    return (
        <VictoryChart polar
            //standalone={false}
            width={450}
            domain={{ y: [0, 1] }}
        >
            <VictoryArea
                data={dataFormat}
                style={{ data: { fillOpacity: 0.2, strokeWidth: 2, fill: props.color } }}
            />
            {
                Object.keys(dataFormat).map((_, i) => (
                    <VictoryPolarAxis
                        key={i}
                        dependentAxis
                        style={{
                            axisLabel: {
                                padding:
                                    dataFormat.length % 2 === 0 ?
                                        (dataFormat.length === 2 ?
                                            40
                                            :
                                            ((i + 1) % 2 === 0 ? 20 : 40))
                                        :
                                        (dataFormat.length === 3 ? ((i + 1) === 1 ? 40 : 25)
                                            :
                                            dataFormat.length === 5 ? (((i + 1) !== 2 && ((i + 1) !== 5)) ? 40 : 20) : 10),
                                fontSize: 17
                            },
                            axis: { stroke: "none" },
                            grid: { stroke: "grey", strokeWidth: 0.25, opacity: 0.5, strokeDasharray: 5 },
                            tickLabels: { fontSize: 12, fontWeight: "normal" }
                        }}
                        tickComponent={<VictoryLabel labelPlacement="vertical" />}
                        axisLabelComponent={<VictoryLabel labelPlacement="vertical" />}
                        labelPlacement="perpendicular"
                        label={`${t("Part")} ${i + 1}`}
                        axisValue={i + 1}
                        tickFormat={(t) => `${Math.ceil(t * 100)}`}
                        tickValues={[0.25, 0.5, 0.75, 1]}
                    />
                ))
            }
            <VictoryPolarAxis
                tickFormat={() => ""}
                style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "grey", opacity: 0.5, strokeDasharray: 5 }
                }}
            />
        </VictoryChart>
    )
}

export default RadarChart