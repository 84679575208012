//* External Imports
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import parse from "html-react-parser"
import axios from "axios";
//* Hooks
import { useTranslation } from "react-i18next";

// Types Imports
import { CompletedExam, UserType, CompletedExercise, ReqCompletedExam } from "../types/UserTypes";
import { ExerciseType, ExamType, QuestionSizeType } from "../types/GeneralTypes";
import { UseRecorder } from "../hooks/VoiceRecorder/types/recorder"

//? Internal imports
//? JSX Components
import LangooTimer from "./LangooTimer"
import TextArea from "./TextArea";
import ProficiencyTestResult from "./reports/proficiencyTestResult";
import Loader from "./loader";
import MockExamReport from "./reports/mockExamReports";
//? Hooks
import useRecorder from "../hooks/VoiceRecorder/use-recorder"
//? Methods
import { formatMinutes, formatSeconds } from "../hooks/VoiceRecorder/utils/format-time"
import uploadAudio from "../aws/query/uploadAudio";
import { isMobile } from "../responsive/mediaQueriesStates";
//? Styled-Components
import { WhiteCard } from "../styles/WhiteCard";
import { Button } from "../styles/buttons/Button";
import { Button as ButtonRound } from "../styles/buttons/ButtonRound"
import LangooTimerWarning from "./LangooTimerWarning";
import { globalErrorHandler } from "../axios/globalErrorHandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//! Images and CSS

Modal.setAppElement("body");

const completedInitialState = {
    completedExam: {
        id_user: null,
        id_exam: null,
        completed: "si",
        start_date: new Date(Date.now()),
        end_date: new Date("1900-01-01"),
        score: "",
        corrected: "no",
    },
    completedExercise: {
        id_user: null,
        id_exercise: null,
        completed: false,
        start_date: new Date(Date.now()),
        end_date: new Date("1900-01-01"),
        hits: null,
        failed: null,
        text_written: null,
        file_speaking: null,
        answers_todas: {
            answerText: undefined,
            answers: []
        },
        corrected: false,
        passed: false,
        score: null,
        score_global: 0,
        id_exam: null
    }
}
type Props = {
    //Exams
    exam: ExamType
    examCompleted?: CompletedExam | undefined
    //Questions
    exerciseQuestions: QuestionSizeType;
    //User
    userData: UserType;
    //others
    mainColor: string;
}

const ModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        height: "auto",
        width: "auto",
        padding: "40px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        borderRadius: "40px 0px 40px 0px",
        maxWidth: "50%",
        gap: "10px"
    },
    overlay: {
        zIndex: 1000
    }
}

const defaultExercise: ExerciseType = {
    id_exercise: 0,
    statement: "",
    content: "",
    exercise_type: "",
    order: 0,
    max_words: 0,
    min_words: 0,
    task_part: 0,
    task_type: 0
}

/** Generic Exam JSX Component 
 * @param {ExamType} exam - Exam info
 * @param {CompletedExam | undefined} examCompleted - (optional): Completed exam info
 * @param {QuestionSizeType} exerciseQuestions - Exam exercises questions 
 * @param {string} mainColor - Main color
 * 
*/
const ExamTemplate = (props: Props): JSX.Element => {
    const isMobileState = isMobile()
    const { t } = useTranslation("translation", { keyPrefix: "exam" })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isProficiency = props.exam.exam_type ? (props.exam.exam_type === "Proficiency" ? true : false) : false
    const totalTime: number = (props.exam.time_reading + props.exam.time_listening + props.exam.time_speaking + props.exam.time_writing)
    const [loading, setLoading] = useState<boolean>(true)
    const [competencyScreen, setCompetencyScreen] = useState<number>(0);
    const [showCharts, setShowCharts] = useState<boolean>(props.examCompleted ? true : false);
    const [inputValue, setInputValue] = useState<(boolean[] | string)[]>([[true], "hola"]);
    const [answers, setAnswers] = useState<{ text: string, id: number, type: string }[][]>();
    const [examExercises, setExamExercises] = useState<ExerciseType[][]>([[defaultExercise]]);
    const [states, setStates] = useState<{ examStates: number, subStates: number }>({ examStates: 0, subStates: 0 });
    const [completed, setCompleted] = useState<{ completedExam: ReqCompletedExam, completedExercise: CompletedExercise }>({
        ...completedInitialState,
        completedExam: { ...completedInitialState.completedExam, id_user: props.userData.id, id_exam: props.exam.id_exam, start_date: new Date(Date.now()) },
        completedExercise: { ...completedInitialState.completedExercise, id_user: props.userData.id, id_exam: props.exam.id_exam }
    })
    const [ended, setEnded] = useState<boolean>(false)
    const [exerciseEnded, setExerciseEnded] = useState<boolean>(false)
    //* Others 
    const [teacher, setTeacher] = useState<{ name: string }>({ name: "" })

    const [timer, setTimer] = useState<number>(props.exam.time_reading);
    const [totalTimer, setTotalTimer] = useState<number>(0)
    const [timerWorker, setTimerWorker] = useState<Worker>()
    const [modal, setModal] = useState<boolean>(false);
    const [writingText, setWritingText] = useState<string>("");
    const { recorderState, ...handlers }: UseRecorder = useRecorder();
    const { startRecording, saveRecording, cancelRecording } = handlers;
    const { recordingMinutes, recordingSeconds } = recorderState;
    const { audio, rawBlob } = recorderState;

    const openModal = (): void => {
        setModal(true);
    }

    const closeModal = (): void => {
        setModal(false);
    }

    const changeCheckValues = (i: number, j: number, e?: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(inputValue.map((elem, index) => i !== index ? elem : (
            Array.isArray(elem) ? elem.map((_, index2) => j === index2 ? true : false) : (e ? e.target.value : "")
        )))
    }

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/examTry`, {
            id_user: props.userData.id,
            id_exam: props.exam.id_exam,
            start_date: completed.completedExam.start_date
        }).then(() => {
            axios.get<ExerciseType[][]>(`${process.env.REACT_APP_API_URL}/exercises/exam/${props.exam.id_exam}`).then((response) => {
                setExamExercises(response.data);
                const exercises = response.data
                axios.get<{ text: string, id: number, type: string }[][]>(`${process.env.REACT_APP_API_URL}/answers/exam/${props.exam.id_exam}`).then((response) => {
                    setAnswers(response.data);
                    const questions = isProficiency ? props.exerciseQuestions[states.examStates] : props.exerciseQuestions.find((elem) => elem.questions.find((elem2) => elem2.id_exercise === exercises[competencyScreen][0].id_exercise));
                    if (questions) {
                        setInputValue(questions.questions.map((elem) => response.data.flat(1).filter((elem2) => elem2.id === elem.id_question)).map((elem) => elem.length === 1 ? "" : new Array(elem.length).fill(false)));
                    }
                    setTimer(props.exam.time_reading)
                    axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
                        id_user: props.userData.id,
                        id_exercise: exercises[competencyScreen][isProficiency ? states.subStates : states.examStates].id_exercise,
                        start_date: completed.completedExam.start_date,
                        id_exam: props.exam.id_exam
                    }).then((_) => setCompleted({ ...completed, completedExercise: { ...completed.completedExercise, start_date: completed.completedExam.start_date } }));
                    axios.get<{ name: string }>(`${process.env.REACT_APP_API_URL}/teacher/${props.userData.id}`).then((response) => {
                        setTeacher(response.data)
                        setLoading(false)
                        if (!isProficiency) {
                            openModal()
                        }
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }).catch(() => globalErrorHandler(dispatch, navigate));
            }).catch(() => globalErrorHandler(dispatch, navigate));
        }).catch(() => globalErrorHandler(dispatch, navigate))
        setLoading(false);
        window.addEventListener('beforeunload', alertUser);
        window.addEventListener('popstate', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
            window.removeEventListener('popstate', alertUser);
        };
    }, []);

    useEffect(() => {
        if (rawBlob && audio) {
            uploadAudio(rawBlob, audio).then((response) => {
                axios.post(`${process.env.REACT_APP_API_URL}/completed`, {
                    ...completed.completedExercise,
                    id_exercise: examExercises[competencyScreen][isProficiency ? 0 : states.examStates].id_exercise,
                    completed: true,
                    end_date: new Date(Date.now()),
                    file_speaking: response
                }).then(() => {
                    cancelRecording()
                    if (ended) {
                        setLoading(true)
                        const newCompleted = { ...completed, completedExam: { ...completed.completedExam, end_date: new Date(Date.now()) } }
                        setCompleted(newCompleted)
                        axios.post(`${process.env.REACT_APP_API_URL}/completedExam`, newCompleted).then((_) => {
                            setTimeout(() => { setLoading(false), setShowCharts(true) }, 1000);
                        }).catch(() => globalErrorHandler(dispatch, navigate))
                    }
                }).catch(() => globalErrorHandler(dispatch, navigate))
            })
        }
    }, [rawBlob, audio])

    useEffect(() => {
        if (exerciseEnded) {
            if(examExercises[competencyScreen][states.examStates].exercise_type === "Speaking" && !(states.examStates < examExercises[competencyScreen].length - 1)){
                endExam()
            }else{
                if(!isProficiency){
                    onClickNext()
                }else{
                    endExercise(competencyScreen)
                }
            }
        }
    }, [exerciseEnded])

    const alertUser = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
        return "";
    };

    const changeSize = (competencyIndex: number, examIndex: number) => {
        const questions = isProficiency ? props.exerciseQuestions[examIndex] : props.exerciseQuestions.find((elem) => elem.questions.find((elem2) => elem2.id_exercise === examExercises[competencyIndex][examIndex].id_exercise))
        //console.log({questions, competencyScreen: competencyScreen, examExercises: examExercises[competencyIndex][examIndex], examAux: examExercises[competencyIndex]})
        if (questions && answers) {
            setInputValue(questions.questions.map((elem) => answers!.flat(1).filter((elem2) => elem2.id === elem.id_question)).map((elem) => elem.length === 1 ? "" : new Array(elem.length).fill(false)));
        }
    };
    const onClickNext = () => {
        window.scrollTo(0, 0)
        if (!isProficiency) {
            openModal()
        }
        setStates({ examStates: 0, subStates: states.subStates + 1 });
        setTimer(examExercises[competencyScreen + 1][0].exercise_type === "Reading" ? props.exam.time_reading :
            (examExercises[competencyScreen + 1][0].exercise_type === "Listening" ? props.exam.time_listening :
                (examExercises[competencyScreen + 1][0].exercise_type === "Writing" ? props.exam.time_writing : props.exam.time_speaking)));
        setExerciseEnded(false)
        const newDate = new Date(Date.now())
        setCompetencyScreen(competencyScreen + 1)
        setCompleted({ ...completed, completedExercise: { ...completed.completedExercise, start_date: newDate } });
        if (competencyScreen + 1 < 2) {
            changeSize(competencyScreen + 1, isProficiency ? competencyScreen + 1 : 0);
        }
        axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
            id_user: props.userData.id,
            id_exercise: examExercises[competencyScreen + 1][isProficiency ? 0 : 0].id_exercise,
            start_date: newDate,
            id_exam: props.exam.id_exam
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }
    const endExercise = async (index: number) => {
        window.scrollTo(0, 0)
        closeModal()
        if ((competencyScreen === 0) || (competencyScreen === 1)) {
            const preRequest: { idArr: number[] | undefined, answers: (string | boolean[])[] } = {
                idArr: (isProficiency ? props.exerciseQuestions[states.subStates] : props.exerciseQuestions.find((elem) => elem.questions.find((elem2) => elem2.id_exercise === examExercises[competencyScreen][(states.examStates)].id_exercise)))?.questions.map((elem) => elem.id_question).flat(1),
                answers: inputValue
            };
            axios.post<{ results: boolean[] }>(`${process.env.REACT_APP_API_URL}/answers`, preRequest).then(async (response) => {
                const hits = response.data.results.filter((elem) => elem === true).length
                const answersValue = (inputValue.filter((elem) => Array.isArray(elem)) as boolean[][]).flat(1).map((elem2, index) => elem2 ? index : -1).map((elem3, i) => ({
                    answerPos: elem3,
                    id: answers?.flat(1).filter((elem4) => elem4.type !== "fill").filter((elem4) => preRequest.idArr?.find((elem5) => elem5 === elem4.id))[i].id
                }))
                const answerText = inputValue.filter((elem) => (typeof elem === "string")) as string[]
                await axios.post(`${process.env.REACT_APP_API_URL}/completed`, {
                    ...completed.completedExercise,
                    id_exercise: examExercises[competencyScreen][isProficiency ? 0 : states.examStates].id_exercise,
                    completed: true,
                    end_date: new Date(Date.now()),
                    hits: hits,
                    failed: props.exerciseQuestions[states.subStates].questions.length - hits,
                    answers_todas: {
                        answerText: answerText ?? undefined,
                        answers: preRequest.idArr?.map((elem) => ({
                            id_question: elem,
                            answerPos: answersValue.find((elem4) => elem4.id === elem && elem4.answerPos !== -1) ? answersValue.find((elem4) => elem4.id === elem && elem4.answerPos !== -1)!.answerPos : undefined
                        }))
                    },
                    corrected: true,
                    passed: hits > (props.exerciseQuestions[states.subStates].questions.length / 2),
                    score: hits > (props.exerciseQuestions[states.subStates].questions.length / 2) ? "APTO" : "NO APTO",
                }).catch(() => globalErrorHandler(dispatch, navigate))
                if (states.examStates < examExercises[index].length - 1) {
                    const newDate = new Date(Date.now())
                    setCompleted({ ...completed, completedExercise: { ...completed.completedExercise, start_date: newDate } });
                    setStates({ examStates: states.examStates + 1, subStates: states.subStates + 1 });
                    axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
                        id_user: props.userData.id,
                        id_exercise: examExercises[competencyScreen][isProficiency ? 0 : states.examStates + 1].id_exercise,
                        start_date: newDate,
                        id_exam: props.exam.id_exam
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                    changeSize(competencyScreen, states.examStates + 1)
                } else {
                    onClickNext()
                }
            }).catch(() => globalErrorHandler(dispatch, navigate))
        } else if (competencyScreen === 2) {
            await axios.post(`${process.env.REACT_APP_API_URL}/completed`, {
                ...completed.completedExercise,
                id_exercise: examExercises[competencyScreen][isProficiency ? 0 : states.examStates].id_exercise,
                completed: true,
                end_date: new Date(Date.now()),
                text_written: `${writingText.replaceAll("\n", "<br/>")}`
            }).catch(() => globalErrorHandler(dispatch, navigate))
            setWritingText("")
            if (states.examStates < examExercises[index].length - 1) {
                const newDate = new Date(Date.now())
                setCompleted({ ...completed, completedExercise: { ...completed.completedExercise, start_date: newDate } });
                setStates({ ...states, examStates: states.examStates + 1 });
                axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
                    id_user: props.userData.id,
                    id_exercise: examExercises[competencyScreen][isProficiency ? 0 : states.examStates + 1].id_exercise,
                    start_date: newDate,
                    id_exam: props.exam.id_exam
                }).catch(() => globalErrorHandler(dispatch, navigate))
            } else {
                onClickNext()
            }
        } else if (competencyScreen === 3) {
            saveRecording();
            if (states.examStates < examExercises[index].length - 1) {
                //setLoading(true)
                setTimeout(() => {
                    const newDate = new Date(Date.now())
                    setCompleted({ ...completed, completedExercise: { ...completed.completedExercise, start_date: newDate } })
                    setStates({ ...states, examStates: states.examStates + 1 });
                    axios.post(`${process.env.REACT_APP_API_URL}/exerciseTry`, {
                        id_user: props.userData.id,
                        id_exercise: examExercises[competencyScreen][isProficiency ? 0 : states.examStates + 1].id_exercise,
                        start_date: newDate,
                        id_exam: props.exam.id_exam
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }, 1000)
            }
        }
    }
    const endExam = async () => {
        saveRecording()
        timerWorker?.postMessage("stopTimer")
        setEnded(true)
    }
    if (loading) {
        return <Loader loading={loading} />
    }
    return (
        <>
            {showCharts && <>
                {props.exam.exam_type === "Mock" ? <MockExamReport userData={props.userData} text={writingText} examStatus={{ info: { testDate: new Date(Date.now()), timeSpent: totalTimer / 60 } }} audio={(audio !== null) ? audio : undefined} />
                    : <ProficiencyTestResult userData={props.userData} text={writingText} completedExam={props.examCompleted} audio={(audio !== null) ? audio : undefined} time={Date.now() - completed.completedExam.start_date.getTime()} exam={props.exam} />}
            </>
            }
            {!showCharts &&
                <MainDiv>
                    <LangooTimerWarning totalTimeCounted={totalTimer} maxTime={totalTime} />
                    <UpperTextDiv id="upperDiv">
                        <div style={{ width: '100%' }}>
                            <h3 style={{ textAlign: 'left' }}>{t(`${examExercises[competencyScreen][0].exercise_type}`)}
                                &nbsp; {t("Test")}
                            </h3>
                        </div>
                    </UpperTextDiv>
                    <TaskTimeBar>
                        <LangooTimer timeMinutes={timer} setWorker={setTimerWorker} setEnded={setExerciseEnded} setTimeAccumulation={setTotalTimer} />
                        <PartText>
                            {`${states.examStates + 1}/${examExercises[competencyScreen].length}`}
                        </PartText>
                    </TaskTimeBar>
                    <DownContainer state={isMobileState}>
                        <Modal
                            isOpen={modal}
                            onRequestClose={closeModal}
                            style={ModalStyle}
                            contentLabel="Continue test"
                        >
                            <ModalWrapper>
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {<ModalText>{t(`${examExercises[competencyScreen][0].exercise_type} Test!`)}</ModalText>}
                                        <ModalYDiv>{t("Hey!")}</ModalYDiv>
                                    </div>
                                    <span>
                                        {competencyScreen === 0 ?
                                            props.exam.statement_reading
                                            : competencyScreen === 1 ?
                                                props.exam.statement_listening
                                                : competencyScreen === 2 ?
                                                    props.exam.statement_writing : props.exam.statement_speaking
                                        }
                                    </span>
                                </>
                            </ModalWrapper>
                            <ModalButton onClick={closeModal}>{isProficiency ? t("Next Task") : t("Close")}</ModalButton>
                        </Modal>
                        {(examExercises[competencyScreen][states.examStates].exercise_type === "Reading" || examExercises[competencyScreen][states.examStates].exercise_type === "Listening") &&
                            <>
                                <ExamCard state={isMobileState}>
                                    <strong>
                                        {examExercises[competencyScreen][states.examStates].statement}
                                    </strong>
                                    <DecorationLine />
                                    <div>
                                        {parse(examExercises[competencyScreen][states.examStates].content.replaceAll("<a", "<a target='_blank'").replaceAll("<hr />", ""))}
                                        {((examExercises[competencyScreen][states.examStates].file) && (examExercises[competencyScreen][states.examStates].file!.split(";").filter((elem) => elem.length > 0).map((elem) =>
                                            <audio controls controlsList="noplaybackrate nodownload" src={elem} />
                                        )))}
                                    </div>
                                </ExamCard>
                                <TasksContainers>
                                    {props.exerciseQuestions.find((elem) => elem.questions.find((elem2) => elem2.id_exercise === examExercises[competencyScreen][states.examStates].id_exercise))?.questions.map((elem, i) =>
                                        <TaskCardDiv key={i}>
                                            <InfoCardDiv color={examExercises[competencyScreen][states.examStates].exercise_type === "Reading" ? "#0B8EAB" : "#A2D9E7"}>
                                                <strong>{elem.text.length === 1 ? `${elem.text}. ${t("Fill in the gap")}` : elem.text}</strong>
                                                <fieldset style={{ border: "none" }}>
                                                    {answers && inputValue[i] !== undefined &&
                                                        answers.find((elem2) => elem2[0].id === elem.id_question)?.map((elem2, j) => elem2.type === "fill" ?
                                                            <InfoCardInput type="text" value={inputValue[i] as string} onChange={(e) => changeCheckValues(i, j, e)} key={i + j + 10} />
                                                            :
                                                            <Label key={i + j + 10}>
                                                                <LabelRadio color={props.mainColor}>
                                                                    <CircularInputCheckbox type="radio" checked={inputValue[i][j] as boolean} onChange={() => changeCheckValues(i, j)} />
                                                                </LabelRadio>
                                                                {elem2.type === "opt" ? elem2.text : <img style={{ width: "50%" }} src={elem2.text} alt={`img_${i}_${j}`} />}
                                                            </Label>)
                                                    }
                                                </fieldset>
                                            </InfoCardDiv>
                                        </TaskCardDiv>)}
                                    <ButtonContainer>
                                        {states.examStates < examExercises[competencyScreen].length - 1 && <NextButton background="#F93E63" color="white" onClick={() => endExercise(competencyScreen)}>{t("Next Part")}</NextButton>}
                                        {!(states.examStates < examExercises[competencyScreen].length - 1) && <NextButton background="#F93E63" color="white" onClick={() => endExercise(competencyScreen)}>{isProficiency ? t("Next Task") : t("Next Test")}</NextButton>}
                                        {/**{!(states.examStates < examExercises[competencyScreen].length - 1) && <NextButton background="#F93E63" color="white" onClick={() => (!isProficiency && competencyScreen === 0) ? [ setCompetencyScreen(competencyScreen+1),endExercise(competencyScreen)] : endExercise(competencyScreen)}>{isProficiency ? t("Next Task") : t("Next Test")}</NextButton>} */}
                                    </ButtonContainer>
                                </TasksContainers>
                            </>
                        }
                        {examExercises[competencyScreen][states.examStates].exercise_type === "Writing" &&
                            <WritingTest>
                                <WritingExamCard>
                                    <strong>
                                        {examExercises[competencyScreen][states.examStates].statement}
                                    </strong>
                                    {examExercises[competencyScreen][states.examStates].content.length > 0 && <DecorationLine />}
                                    <div>
                                        {parse(examExercises[competencyScreen][states.examStates].content.replaceAll("<a", "<a target='_blank'"))}
                                    </div>
                                    {((examExercises[competencyScreen][states.examStates].file) && (examExercises[competencyScreen][states.examStates].file!.split(";").filter((elem) => elem.length > 0).map((elem) =>
                                        <audio controls controlsList="noplaybackrate nodownload" src={elem} />
                                    )))}
                                </WritingExamCard>
                                <TextArea examType={props.exam.exam_type !== "Mock" ? true : false} writingText={writingText} setWritingText={setWritingText} id_user={props.userData.id} />
                                <ButtonContainer>
                                    {states.examStates < examExercises[competencyScreen].length - 1 && <NextButton background="#F93E63" color="white" onClick={() => endExercise(competencyScreen)}>{t("Next Part")}</NextButton>}
                                    {!(states.examStates < examExercises[competencyScreen].length - 1) && <NextButton background="#F93E63" color="white" onClick={() => endExercise(competencyScreen)}>{isProficiency ? t("Next Task") : t("Next Test")}</NextButton>}
                                </ButtonContainer>
                            </WritingTest>
                        }
                        {examExercises[competencyScreen][states.examStates].exercise_type === "Speaking" &&
                            <SpeakingContainer>
                                <SpeakingExamCard>
                                    {examExercises[competencyScreen][states.examStates].statement}
                                    <DecorationLine />
                                    {parse(examExercises[competencyScreen][states.examStates].content.replaceAll("<a", "<a target='_blank'"))}
                                    {((examExercises[competencyScreen][states.examStates].file) && (examExercises[competencyScreen][states.examStates].file!.split(";").filter((elem) => elem.length > 0).map((elem) =>
                                        <audio controls controlsList="noplaybackrate nodownload" src={elem} />
                                    )))}
                                </SpeakingExamCard>
                                <VoiceRecorder>
                                    <RecordTimeSpan>
                                        <h4>{t("Time:")} </h4><h4 style={{ color: '#0E8EAB' }}>{formatMinutes(recordingMinutes)}:{formatSeconds(recordingSeconds)}</h4>
                                    </RecordTimeSpan>
                                </VoiceRecorder>
                                <ButtonContainer>
                                    {
                                        states.examStates < examExercises[competencyScreen].length - 1 ?
                                            (recordingSeconds === 0 && recordingMinutes === 0) ?
                                                <NextButton background="#F93E63" color="white" onClick={startRecording}>{t("Start Recording")}</NextButton>
                                                :
                                                <NextButton background="#F93E63" color="white" onClick={() => endExercise(competencyScreen)}>{t("Stop Recording & Next Part")}</NextButton>
                                            :
                                            (recordingSeconds === 0 && recordingMinutes === 0) ?
                                                <NextButton background="#F93E63" color="white" onClick={startRecording}>{t("Start Recording")}</NextButton>
                                                :
                                                <NextButton background="#F93E63" color="white" onClick={endExam} disabled={recordingSeconds === 0}>{t("Stop Recording & Finish Test")}</NextButton>
                                    }

                                </ButtonContainer>
                            </SpeakingContainer>
                        }
                    </DownContainer>
                </MainDiv>
            }
        </>
    )
}

export default ExamTemplate;

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 5%;
    img {
        max-width: 100%;
    }
`
const UpperTextDiv = styled.div`
    width: 100%;
    padding-bottom: 0;
    box-sizing: border-box;
    text-align: left;
    font-size: 30px;
    h4 {
        color: ${props => props.theme.langooBlue}
    }
`
const TaskTimeBar = styled(WhiteCard)`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin: 3% 10%;
    margin-top: 0;
    padding: 15px;
    box-sizing: border-box;
`
const PartText = styled.div`
    color: ${props => props.theme.langooBlue};
    margin-left: 10px;
    font-size: 18px;
    font-weight: 700;
`
const DecorationLine = styled.div`
    border-bottom: 1.5px solid #2122225a;
    width: 100%;
    margin: 3%;
    text-decoration: underline;
`
const ModalYDiv = styled.div`
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.langooYellow};
    border-radius: 40px 40px 40px 0px;
    font-size: 18px;
    width: fit-content;
    padding: 0 5%;
    height: 64px;
    left: 367px;
    top: -28px;
    text-align: center;
    margin-left: 10%;
    margin-bottom: 5%;
`
const ModalWrapper = styled.div`
    text-align: left;
    span {
        font-size: 20px;
        font-weight: 400;
    }
`
const ModalButton = styled.button`
    background: transparent;
    border: transparent;
    color: ${props => props.theme.langooBlue};
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
    cursor: pointer;
`
const ModalText = styled.div`
    font-weight: 700;
    font-size: 40px;
    color:${props => props.theme.langooBlue};
`
const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`
const NextButton = styled(ButtonRound) <{ background: string, color?: string }>`
    border: 1px solid ${props => props.background};
    background: ${props => (props.disabled ?? false) ? "white" : props.background};
    color: ${props => (props.disabled ?? false) ? props.background : ((props.color !== undefined) ? props.color : "#454545")};;
    width: 300px;
    height: 43px;
    font-size: 20px;
    margin-top: 10px;
`
const DownContainer = styled.div<{ state: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    width: 100%;
    height: 100%;
    gap: ${props => props.state ? "20px" : "0px"};
`
const ExamCard = styled(WhiteCard) <{ state: boolean }>`
    position: ${props => props.state ? "none" : "sticky"};
    top: 0px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    min-height: fit-content;
    text-align: left;
    padding: 3%;
    margin-right: 3%;
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    p {
        width: 100%;
        word-break: break-word;
        word-wrap: normal;
        span {
            width: 100%;
        }
        hr {
            background: transparent;
        }
        textarea {
            resize: none;
            width: 100%;
            min-height: 200px;
        }
    }
`
const WritingExamCard = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 3%;
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    li {
        text-align: left;
    }
    textarea {
        resize: none;
        width: 100%;
        min-height: 200px;
    }
`
const TasksContainers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 70%;
    height: 100%;
`
const TaskCardDiv = styled(WhiteCard)`
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding-right: 5%;
    padding-top: 3%;
    padding-left: 5%;
    padding-bottom: 3%;
`
const InfoCardDiv = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding-left: 20px;
    border-left: 10px solid ${props => props.color};
    strong {
        margin-bottom: 3%;
    }
`
const LabelRadio = styled.label<{ color: string }>`
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    height: 50%;
    margin-top: 1%;
    input[type=radio] {
        -webkit-appearance: none;
        appearance: none;
        background: #FFFFFF;
        border: 0.15em solid #AAAAAA;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin: 0;
        width: 1.15em;
        height: 1.15em;
        display: grid;
        place-content: center;
        transform: translateY(-0.075em);
        ::before {
            content: "";
            width: 0.45em;
            height: 0.45em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1.40em 1em #FFFFFF;
            background: ${props => props.color};
            border: 0.15em solid ${props => props.color};
        }
        :checked::before{
            transform: scale(1.40);
        }
    }
`
const CircularInputCheckbox = styled.input`
    border-radius: 999px;
`
const Label = styled.label`
    display:flex;
    flex-direction: row;
    margin-bottom: 10px;
`
const InfoCardInput = styled.input`
    background: #eeeded;
    border: 1px solid transparent;
    height: 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    outline: none;
`
const WritingTest = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
`

const SpeakingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
`
const SpeakingExamCard = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 100%;
    height: auto;
    min-height: 80%;
    margin-bottom: 20px;
    padding: 5%;
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    img {
        width: 95%;
    }
    textarea {
        resize: none;
        width: 100%;
        min-height: 200px;
    }
`
const VoiceRecorder = styled(WhiteCard)`
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 100%;
    height: auto;
    min-height: 30px;
    padding: 1% 5%;
    box-sizing: border-box;
    button {
        height: 80%;
    }
`
const RecordTimeSpan = styled.div`
    display: flex;
    flex-direction: row;
    h4{
       padding: 0 3%; 
    }
`