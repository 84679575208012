//* External Imports
import React from "react";
import styled from "styled-components";

type Props = {
    loading: boolean
}

/** Generic Loading animation without overlay */
const LoaderNoOverlay = (props: Props): JSX.Element => (
    <Main state={props.loading}/>
)
const Main = styled.span<{state: boolean}>`
    width: 20px;
    height: 20px;
    border: 5px solid ${props => props.theme.lightGrey};
    border-bottom-color: ${props => props.theme.langooBlue};
    border-radius: 50%;
    display: ${props => props.state ? "inline-block" : "none"};
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`
export default LoaderNoOverlay;

