//* External Imports
import React from "react";
import styled from "styled-components";

//? Internal Imports
//? Methods
import { isMobile } from "../responsive/mediaQueriesStates";

//! Images
import backArrow from "../assets/icons/backArrow.png"


type Props = {
    text: string
    goBack: boolean
    style?: React.CSSProperties
    setGoBack: React.Dispatch<React.SetStateAction<boolean>>
    setExtra?: React.Dispatch<React.SetStateAction<boolean | boolean[]>>
}

/** Generic JSX Title Component */
const TitleComponent = (props: Props) => {
    const isMobileState = isMobile()
    return (
        <SpanText state={isMobileState} style={props.style ? props.style : {}}>
        <button onClick={() => props.setExtra ? [props.setGoBack(!props.goBack), props.setExtra([false, false])] : props.setGoBack(!props.goBack)}>
            <img src={backArrow} alt="" />
        </button>
        <div>{props.text}</div>
    </SpanText>
    )
}
    
export default TitleComponent

const SpanText = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.langooBlue};
    div {
        font-size: ${props => props.state ? "20px" : "30px"};
        margin-left: 10px;
        font-weight: 700;
    }
    button {
        height: 100%;
        display: flex;
        align-items: center;
        background: transparent;
        border: transparent
    }
`