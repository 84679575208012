//* External imports
import React from "react"
import { VictoryChart, VictoryGroup, VictoryLabel, VictoryPie } from "victory"

//? Donut chart (pie chart without middle) template. Simple look with two data values and % label on the middle.
//! It's called simple because how it's looks.

type Props = {
    color: string
    data: Array<{
        x: string | number,
        y: number
    }>
    height?: number;
    width?: number
    textCompetency?: string;
    text?: Array<string> | string
}
const DonutChartCompetency = (props: Props): JSX.Element => {
    const width = props.height ? props.height : 700;
    const height = props.width ? props.width : 550;
    return (
            <VictoryPie
                width={width}
                height={height}
                colorScale={["#D4D4D4", props.color]}
                data={props.data}
                padAngle={2}
                style={{ labels: { fill: "transparent" } }}
                innerRadius={170}
                animate={{ duration: 2000 }}
                labelComponent={<VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    text={props.text ? props.text : `${Math.floor(props.data[1].y)}%`}
                    x={width / 2}
                    y={(height / 2) - 15}
                    style={[
                        {
                            fontSize: 100,
                            fontFamily: "'Familjen Grotesk', sans-serif",
                            fill: props.color,
                            fontWeight: 700
                        },
                    ]}
                />}
            />
    )
}

export default DonutChartCompetency;