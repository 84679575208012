//* External Imports
import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import styled from "styled-components"
import Modal from "react-modal"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Type Imports
import { StorageInfo } from "../redux/types/ReduxTypes";

//? Internal Imports 
//? Methods
import { logIn, sendError } from "../redux/hook/useReducer";
//? Styled-Components
import { ButtonTransition } from "../styles/buttons/ButtonTransition";
import { Button } from "../styles/buttons/Button";

//! Images and CSS
import mainlogo1 from "../assets/mainlogo/Logo_Langoo_01.png"

Modal.setAppElement("body");

type ErrorStatesType = {
    badPasswordOrMail: boolean,
    emptyInput: boolean,
    expiredPlan: boolean,
    serverError: boolean
}

const defaultErrorStates: ErrorStatesType = {
    badPasswordOrMail: false,
    emptyInput: false,
    expiredPlan: false,
    serverError: false
}

const ModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        height: "auto",
        width: "auto",
        padding: "40px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderBottomRightRadius: "50px 50px",
        borderTopLeftRadius: "50px 50px",
        backgroundColor: "white",
        border: "2px solid #A2D9E7",
    },
    overlay: {
        zIndex: 1000
    }
}

const Login = (): JSX.Element => {
    //router
    const navigate = useNavigate()
    //i18next
    const { t } = useTranslation("translation", { keyPrefix: "login" })
    //Redux
    const storedInfo: StorageInfo = useSelector<StorageInfo, StorageInfo>((state: StorageInfo) => state)
    const dispatch = useDispatch()
    //User info state variables
    const [resetMail, setResetMail] = useState<string>("")
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [modal, setModal] = useState<boolean>(false);
    //Token state variable
    const [authToken, setAuthToken] = useState<number>();
    const [stayLogged, setStayLogged] = useState<boolean>(false)
    //Error state variables
    const [errorStates, setErrorStates] = useState<ErrorStatesType>(defaultErrorStates)

    window.addEventListener("keyup", ((ev: KeyboardEvent) => {
        ev.preventDefault()
        if (ev.key === "Enter") {
            onAccess()
        }
    }))

    //Use for Modal component
    /** Changes modal state to true */
    const openModal = () => {
        setModal(true)
    }
    /** Changes modal state to false */
    const closeModal = () => {
        setModal(false)
    }

    /**Log in button logic (onClick). */
    const onAccess = async (): Promise<void> => {
        const token = await axios.post<{ id_user: number }>(`${process.env.REACT_APP_API_URL}/logIn`, {
            email: email,
            password: password
        }).then((response) => {
            dispatch(logIn({
                mainStates: (storedInfo && storedInfo.mainStates.length !== 0) ? storedInfo.mainStates : new Array<boolean>(7).fill(true).fill(false, 0, 6),
                authToken: response.data.id_user,
                logInfo: {
                    stayLogged: stayLogged ? true : false,
                    isLogged: true,
                }
            }))
            return response.data;
        }).catch((error: AxiosError) => {
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        setErrorStates({
                            badPasswordOrMail: true,
                            emptyInput: false,
                            expiredPlan: false,
                            serverError: false
                        })
                        break;
                    case 403:
                        setErrorStates({
                            badPasswordOrMail: false,
                            emptyInput: false,
                            expiredPlan: true,
                            serverError: false
                        })
                        break;
                    case 400:
                        setErrorStates({
                            badPasswordOrMail: false,
                            emptyInput: true,
                            expiredPlan: false,
                            serverError: false
                        })
                        break;
                    default:
                        setErrorStates({
                            badPasswordOrMail: false,
                            emptyInput: false,
                            expiredPlan: false,
                            serverError: true
                        })
                }
            }
        })
        //https://beta.langoo.io/landing
        if (token && token.id_user) {
            setAuthToken(token.id_user)
            window.open(`${process.env.REACT_APP_OPEN_URL}/`, "_parent")
            //navigate("/landing")
        }
    }

    /** Reset password button handler */
    const onReset = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/resetPassword`, {
            mail_user: resetMail
        })
        setResetMail("")
        closeModal()
    }

    if ((storedInfo.logInfo.stayLogged) || (storedInfo.logInfo.isLogged)) {
        window.open(`${process.env.REACT_APP_OPEN_URL}/`, "_parent")
        //navigate("/landing")
    }

    if (authToken) {
        window.open(`${process.env.REACT_APP_OPEN_URL}/`, "_parent")
        //navigate("/landing")
    }

    if (errorStates.serverError) {
        dispatch(sendError())
        navigate("/error")
    }

    return (
        <>
            <MainDiv id="mainDiv">
                <div style={{ transform: "translate(0, 50%)" }}>
                    <TextDiv>
                        <div>{t('Log in to')}</div>
                        <Img src={mainlogo1} alt="mainLogo1" />
                    </TextDiv>
                    <InputDiv>
                        <Input type="text" autoComplete="false" placeholder={t("Email") ?? "Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Input type="password" autoComplete="false" placeholder={t("Password") ?? "Password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </InputDiv>
                    {Object.values(errorStates).find((elem) => elem) &&
                        <div style={{ marginBottom: "10px" }}>
                            {errorStates.badPasswordOrMail && <div>{t("Incorrect Email or Password")}</div>}
                            {errorStates.expiredPlan && <div>{t("Expired Plan")}</div>}
                            {errorStates.emptyInput && <div>{t("Missing Information")}</div>}
                        </div>
                    }
                    <div style={{ marginBottom: "10px" }}>
                        <input type="radio" checked={stayLogged} onClick={() => setStayLogged(!stayLogged)} /> {t("Remember me")}
                    </div>
                    <div>
                        <LogInButton onClick={onAccess}>{t("Log In")}</LogInButton>
                    </div>
                    <ButtonDiv>
                        <ButtonLink onClick={openModal}>{t("Forgot your password?")}</ButtonLink>
                        {/**<DecorationDiv style={{ textDecoration: "underline" }}></DecorationDiv> */}
                    </ButtonDiv>
                </div>
                <Modal
                    isOpen={modal}
                    onRequestClose={closeModal}
                    style={ModalStyle}
                    contentLabel="Password reset"
                >
                    <ModalDiv>
                        <div style={{ fontSize: "35px" }}>{t("Forgot your password?")}</div>
                        <ModalYDiv>Hey!</ModalYDiv>
                    </ModalDiv>
                    <ModalDivI>
                        <div>{t("Enter your email to reset your password:")}</div>
                        <ModalInput type="text" placeholder="Email" value={resetMail} onChange={(e) => setResetMail(e.target.value)} />
                    </ModalDivI>
                    <ModalButton onClick={onReset}>{t("Reset")}</ModalButton>
                </Modal>
            </MainDiv>
        </>
    )
}

export default Login;

//Modal component style in "login.css"

// Main components css
const MainDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-height: 100vh;
`
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    justify-Content: center;
    flex-direction: column;
    margin: 20px;
    span {
        display: flex;
        align-items: center;
        justify-Content: center;
        flex-direction: row;
        font-size: 18px;
    }
`
const LogInButton = styled(ButtonTransition)`
    background: ${props => props.theme.langooRed};
    width: 100%;
    min-width: 260px;
    height: 30px;
    padding: 0;
    font-size: 15px;
    &:hover {
        border: 2px solid ${props => props.theme.langooRed};
        color: ${props => props.theme.langooRed};
    }
`
const ButtonLink = styled.button`
    height: 30px;
    background: transparent;
    border: transparent;
    color: light grey;
    text-decoration: none;
    margin: 6px;
    color: ${props => props.theme.langooBlue};
    font-weight: 700;
    font-size: 15px;
    &:hover {
        color: ${props => props.theme.langooBlue};
        text-decoration: underline;
    }
`

const Img = styled.img`
    width: 150px;
    height: 73px;
`
//Modal inside components css
const ModalDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: ${props => props.theme.langooBlue};
    height: 100%;
    width: auto;
`
const ModalYDiv = styled.div`
    display: flex;
    align-self: start;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.langooYellow};
    border-radius: 40px 40px 40px 0px;
    font-size: 18px;
    width: 112px;
    height: 64px;
    left: 367px;
    top: -28px;
    text-align: center;
    color: ${props => props.theme.langooBlue};
    font-weight: 600;
    margin-left: 15px;
    margin-bottom: 40px;
`
const ModalDivI = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    font-size: 20px;
`
const ModalInput = styled.input`
    background: transparent;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    border-bottom: 1px solid #000000;
    width: 80%;
    height: 30px;
    margin-top: 10px;
`
const ModalButton = styled(Button)`
    width: 50%;
    margin-top: 10px;
    border: transparent;
    padding: 10px;
    gap: 10px;
    cursor: pointer;
    
`
const Input = styled.input`
    width: 100%;
    min-width: 250px;
    margin-top: 5px;
    height: 30px;
    margin-bottom: 20px;
    background: white;
    border: none;
    border-bottom: 3px solid ${props => props.theme.langooBlue};
    border-radius: 5px;
    padding-left: 15px;
    outline: none;
    &:hover {
        border-bottom: 3px solid ${props => props.theme.langooLightBlue};
    }
    &:focus {
        border-bottom: 3px solid ${props => props.theme.langooLightBlue};
    }
`
const InputDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const TextDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    div {
        display: flex;
        transform: translate(0%, -25%);
        font-size: 25px;
        font-weight: 700;
    }
`
const DecorationDiv = styled.div`
    border-bottom: 1.5px solid #2122225a;
    min-width: 250px;
    margin-bottom: 15px;
    margin-top: 15px;
`
