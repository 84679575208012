//* External Imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import i18n from "../i18n";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
//* Hooks
import { useDispatch, useSelector } from "react-redux"

// Types Imports
import { CompletedFullExam, UserType } from "../types/UserTypes";
import { CompletedExercise } from "../types/UserTypes";
import { ExercisesType } from "../types/GeneralTypes";
import { ExamType } from "../types/GeneralTypes";
import { StorageInfo } from "../redux/types/ReduxTypes";

//? Internal Imports 
//? JSX Components
import Landing from "./Landing";
import Classroom from "./Classroom";
import Dashboard from "./Dashboard";
import MockExam from "./MockExam";
import ProficiencyTest from "./proficiencyTest";
import ProficiencyTestResult from "../components/reports/proficiencyTestResult";
import StudyMaterial from "./studyMaterial";
import Login from "./Login";
import Loader from "../components/loader";
import MainNavBar from "../components/navBars/mainNavBar";
import TopNavBar from "../components/navBars/topNavBar";
import ResponsiveNavBar from "../components/navBars/responsive/responsiveNavBar";
import PaymentPlatform from "./PaymentPlatform";
import Profile from "./profile";
import { Mobile, Tablet } from "../responsive/mediaQueriesComponents";
//? Hooks
import { useCurrentPath } from "../hooks/routes/useCurrentPath";
//? Methods
import { isMobile, isTablet } from "../responsive/mediaQueriesStates";
import { logIn } from "../redux/hook/useReducer";

//! Images
import mainlogo1 from "../assets/mainlogo/Logo_Langoo_01.png"
import deleLogo from "../assets/affiliatedLogos/deleLogo.png"
import cambridgeLogo from "../assets/affiliatedLogos/cambridgeLogo.png"

//? Main platform component. Used to access other components

type IProp = {
    authToken: number
}

/**//* screenStates array indexes.
 *      Dashboard -> 0
 *      ProficiencyTest -> 1
 *      Classroom -> 2
 *      MockExam -> 3
 *      StudyMaterial -> 4
 *      ProficiencyTestResult -> 5
 *      Report -> 6
*/

/**//* NavbarStates array indexes.
 *      toProfile -> 0
 *      SignOut -> 4 
*/

const PrivateZone = (props: IProp): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileState = isMobile()
    const isTabletState = isTablet()
    const currentPathEmpty = useCurrentPath([{path: "/"}])
    const currentPathTest = useCurrentPath([{path: "/proficiencytest"}])
    const storageInfo = useSelector<StorageInfo, StorageInfo>((state: StorageInfo) => state)
    const [loading, setLoading] = useState<boolean>(true)
    const [userInfo, setUserInfo] = useState<UserType>();
    const [noProficiency, setNoProficiency] = useState<boolean>(true)
    const [learningPathPercent, setLearningPathPercent] = useState<{reading: number, listening: number, speaking: number, writing: number}>({
        reading: 0,
        listening: 0,
        speaking: 0,
        writing: 0
    })
    const [proficiencyCorrected, setProficiencyCorrected] = useState<boolean>(false)
    //!! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR
    const [studentExercise, setStudentExercise] = useState<CompletedExercise[]>();
    const [userExercises, setUserExercises] = useState<ExercisesType>();
    const [exams, setExams] = useState<ExamType[]>([])
    //!! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR !! REFACTOR
    //Change screen
    const [aux, setAux] = useState<boolean>(false);
    const [screenStates, setScreenStates] = useState<boolean[]>(storageInfo.mainStates)
    //Aux variables
    const [openReport, setOpenReport] = useState<number>(-1)
    // NavBar
    const [navBarStates, setNavBarStates] = useState<boolean[]>(new Array(5).fill(false))
    const [byby, setByby] = useState<boolean>(false)

    //Dashboard

    const activityWatcher = () => {
        const events = ["mousedown", "mousemove", "keydown", "scroll", "touchstart"]
        let lastInteraction = 0;
        setInterval(() => {
            lastInteraction++;
            if (lastInteraction > 1200) {
                dispatch(logIn({
                    mainStates: [],
                    authToken: storageInfo.logInfo.stayLogged ? storageInfo.authToken : undefined,
                    logInfo: {
                        stayLogged: storageInfo.logInfo.stayLogged,
                        isLogged: false,
                    }
                }))
                setAllFalse(setScreenStates, 7, setNavBarStates, 5)
                setByby(true)
                window.open("https://www.langoo.io/inicia-sesion-beta/", "_parent")
                navigate("/")
            }
        }, 1000)
        events.forEach((elem) => {
            window.addEventListener(elem, (() => lastInteraction = 0), true)
        })
    }
    /** Set boolean states. Up to two states, 1 generic boolean[] state and 1 optional generic boolean value. */
    const setArrayStatesOnClick = (index: number, arr?: boolean[], setState?: React.Dispatch<React.SetStateAction<boolean[]>>, setAdicional?: React.Dispatch<React.SetStateAction<boolean>>) => {
        const auxScreenStates = (arr ? arr : screenStates).map((_, i) => index === i ? true : false)
        setState ? setState(auxScreenStates) : setScreenStates(auxScreenStates);
        if (setAdicional) {
            setAdicional(false)
        }
    }
    /** Find if a specific value inside an array is true. */
    const findTrueScreenState = (index: number, arr?: boolean[]) => {
        if (index === (arr ? arr : screenStates).findIndex((elem) => elem === true)) {
            return true;
        }
        return false;
    }
    /** Set up to two boolean[] state elements to false. */
    const setAllFalse = (setArr1: React.Dispatch<React.SetStateAction<boolean[]>>, arr1Size: number, setArr2?: React.Dispatch<React.SetStateAction<boolean[]>>, arr2Size?: number) => {
        setArr1(new Array(arr1Size).fill(false))
        if (setArr2 && arr2Size) {
            setArr2(new Array(arr2Size).fill(false))
        }
    }
    /** Change values when clicking on mainlogo */
    const onClickMainLogo = () => {
        setArrayStatesOnClick(6)
        setAux(!aux)
        setNavBarStates(new Array(5).fill(false))
    }
    /** Change screen state when clicking on a button */
    const onClickScreenButton = (index: number) => {
        setArrayStatesOnClick(index)
        setAllFalse(setNavBarStates, 7)
        setAux(screenStates[index] ? !aux : false)
    }
    
    useEffect(() => {
        activityWatcher()
        //Get User info and Certification from database
        axios.get<UserType>(`${process.env.REACT_APP_API_URL}/userById/${props.authToken}`).then((response) => {
            setUserInfo(response.data);
            i18n.changeLanguage(response.data.certification === "DELE" ? "es" : "en").then((_) => {
                axios.get<ExamType[]>(`${process.env.REACT_APP_API_URL}/exams/${props.authToken}`).then((response) => {
                    setExams(response.data)
                    const proficiency = response.data.find((elem) => elem.exam_type === "Proficiency")
                    if(proficiency){
                        axios.get<CompletedFullExam>(`${process.env.REACT_APP_API_URL}/exams/completed/${proficiency.id_exam}/${proficiency.exam_type}/${props.authToken}`).then((response) => {
                            if (Object.keys(response.data).length === 0 || (!response.data.data.completed)) {
                                setNoProficiency(true)
                                setLoading(false)
                                navigate("/proficiencytest", {replace: true})
                            } else {
                                if(!response.data.data.corrected && response.data.data.completed){
                                    setNoProficiency(true)
                                    setLoading(false)
                                    setProficiencyCorrected(true)
                                    navigate("/proficiencytest", {replace: true})
                                }else{
                                    setNoProficiency(false)
                                    axios.get<CompletedExercise[]>(`${process.env.REACT_APP_API_URL}/exercises/completed/${props.authToken}`).then((response) => {
                                        setStudentExercise(response.data)
                                        axios.get<ExercisesType>(`${process.env.REACT_APP_API_URL}/exercises/${props.authToken}`).then((response) => {
                                            setUserExercises(response.data)
                                            axios.get<{reading: number, listening: number, speaking: number, writing: number}>(`${process.env.REACT_APP_API_URL}/learningPath/percent/${props.authToken}`).then((response) => {
                                                setLearningPathPercent(response.data)
                                                setLoading(false)
                                                if(currentPathTest){
                                                    navigate("/proficiencyresults")
                                                }
                                                if(currentPathEmpty){
                                                    window.open(`${process.env.REACT_APP_OPEN_URL}/landing`, "_parent")
                                                    //navigate("/landing", {replace: true})
                                                }
                                            })
                                        })
                                    })
                                    window.addEventListener('unload', () => {
                                        axios.post(`${process.env.REACT_APP_API_URL}/lastConnection/${props.authToken}`)
                                    })
                                    //document.addEventListener("keypress", handlerKeyPress)
                                    return () => {
                                        window.removeEventListener('unload', () => {
                                            axios.post(`${process.env.REACT_APP_API_URL}/lastConnection/${props.authToken}`)
                                        })
                                        //document.removeEventListener("keypress", handlerKeyPress)
                                    }
                                }
                            }
                        })
                    }
                })
            })
        })
    }, [])

    if (loading) {
        return <Loader loading={loading} />
    }
    if(currentPathEmpty){
        navigate(noProficiency ? "/proficiencytest" : "/landing", {replace: true})
    }
    return (
        <>  {byby && <Login />}
            {!byby && userInfo &&
                <MainDiv state={isMobileState || isTabletState}>
                    <OptionsContainer state={isMobileState || isTabletState}>
                        <Link to="/landing"><Img src={mainlogo1} alt="mainLogo" onClick={onClickMainLogo} state={isMobileState || isTabletState} /></Link>
                        <Mobile>
                            <div style={{ display: "flex", width: "100%", gap: "10px", alignItems: "center" }}>
                                <ImgContainer>
                                    <img style={{ verticalAlign: "middle" }} src={userInfo.certification === "DELE" ? deleLogo : cambridgeLogo} />
                                </ImgContainer>
                                <div style={{ fontSize: "20px", verticalAlign: "text-top" }}>{userInfo.certification_level}</div>
                            </div>
                            <ResponsiveNavBar noProfiency={noProficiency} screenStates={screenStates} onClickScreenButton={onClickScreenButton} userId={props.authToken} userName={`${userInfo.name} ${userInfo.surname}`} setByby={setByby} />
                        </Mobile>
                        <Tablet>
                            <div style={{ display: "flex", width: "100%", gap: "10px", alignItems: "center" }}>
                                <ImgContainer>
                                    <img style={{ verticalAlign: "middle" }} src={userInfo.certification === "DELE" ? deleLogo : cambridgeLogo} />
                                </ImgContainer>
                                <div style={{ fontSize: "20px", verticalAlign: "text-top" }}>{userInfo.certification_level}</div>
                            </div>
                            <ResponsiveNavBar noProfiency={noProficiency} screenStates={screenStates} onClickScreenButton={onClickScreenButton} userId={props.authToken} userName={`${userInfo.name} ${userInfo.surname}`} setByby={setByby} />
                        </Tablet>
                        <MainNavBar screenStates={screenStates} onClickScreenButton={onClickScreenButton} userId={props.authToken}
                            userName={`${userInfo.name} ${userInfo.surname}`} setByby={setByby}
                            setNavBarStates={setNavBarStates} navBarStates={navBarStates} setArrayStatesOnClick={setArrayStatesOnClick}
                            setAllFalse={setAllFalse} noProfiency={noProficiency}
                            setScreenStates={setScreenStates}
                        />
                    </OptionsContainer>
                    <MainBottomDiv>
                        {!noProficiency && <TopNavBar setScreenStatesOnClick={setArrayStatesOnClick} findTrueScreenState={findTrueScreenState}
                            userData={{certification: userInfo.certification, certification_level: userInfo.certification_level}}
                            exam_date={userInfo.exam_date}
                            learningPathPercent={learningPathPercent}
                            />
                        }
                        {navBarStates[0] && <Profile userData={{...userInfo, id: props.authToken}} />}
                        <Routes>
                            {noProficiency ?
                                userInfo && exams &&
                                <>
                                    <Route path="proficiencytest" element={<ProficiencyTest corrected={proficiencyCorrected} exam={exams.find((elem) => elem.exam_type === "Proficiency")!} mainColor={"#0B8EAB"} user={{ ...userInfo, id: props.authToken }} aux={aux} screenStates={screenStates} />} />
                                    <Route path="profile" element={<Profile userData={{...userInfo, id: props.authToken}}/>}/>
                                </>
                                :
                                <>
                                    {studentExercise && userInfo && userExercises &&
                                        <Route  path="landing" element={<Landing user={{ ...userInfo, id: props.authToken }} aux={screenStates[6]} setStudentExe={setStudentExercise} 
                                            setLearningPathPercent={setLearningPathPercent}
                                            />} />
                                    }
                                    {studentExercise && userInfo && userExercises &&
                                        <Route path="dashboard" element={<Dashboard user={{ ...userInfo, id: props.authToken }} toCompetencyR={false} studenExercise={studentExercise}
                                            exercises={userExercises} resetDashboard={aux} stateScreen={screenStates}
                                        />} />
                                    }
                                    {studentExercise && userInfo && userExercises &&
                                        <Route path="classroom" element={<Classroom studentExe={studentExercise} exercises={userExercises} goal={userInfo.goal} resetClassroom={aux} loadFromLanding={openReport} id_user={props.authToken} screenStates={screenStates}
                                            setStudentExe={setStudentExercise}
                                        />} />
                                    }
                                    {userInfo && userExercises &&
                                        <Route path="mockexam" element={<MockExam studenExercise={studentExercise!} exam={exams.filter((elem) => elem.exam_type === "Mock")} mainColor={"#0B8EAB"} user={{ ...userInfo, id: props.authToken }} aux={aux}
                                            setArrayStatesOnClick={setArrayStatesOnClick} exercises={userExercises} screenState={screenStates}
                                        />} />
                                    }
                                    {<Route path="studymaterial" element={<StudyMaterial idUser={props.authToken} />} />
                                    }
                                    {userInfo && exams &&
                                        <Route path="proficiencyresults" element={<ProficiencyTestResult userData={{ ...userInfo, id: props.authToken }} exam={exams.find((elem) => elem.exam_type === "Proficiency")!} />
                                        } />
                                    }
                                    {
                                        <Route path="paymentPlatform" element={<PaymentPlatform id_user={props.authToken}/>}/>
                                    }
                                    {userInfo &&
                                        <Route path="profile" element={<Profile userData={{...userInfo, id: props.authToken}}/>}/>
                                    }
                                </>
                            }
                        </Routes>
                    </MainBottomDiv>
                </MainDiv>
            }
        </>
    )
}

export default PrivateZone;

const MainDiv = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    width: auto;
    padding-bottom: ${props => props.state ? "60px" : "0px"};
    button {
        font-family: 'Familjen Grotesk', sans-serif;
        cursor: pointer;
    }
`
const MainBottomDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    max-width: 1900px;
    background: #F5F5F5;
`
//Left Div components
const OptionsContainer = styled.div<{ state: boolean }>`
    display: flex;
    position: sticky;
    top: 0px;
    flex-direction: ${props => props.state ? "row" : "column"};
    align-items: center;
    width: ${props => props.state ? "100%" : "25.5%"};
    max-width: ${props => props.state ? "100%" : "400px"};
    height: 100%;
    min-height: ${props => props.state ? "0" : "100vh"};
    background: white;
    z-index: 5;
`
const Img = styled.img<{ state: boolean }>`
    width: ${props => props.state ? "100px" : "171px"};
    height: auto;
    margin-top: ${props => props.state ? "0px" : "20px"};
    cursor: pointer;
`
const ImgContainer = styled.div`
width: 10%;
img {
    width: 20px;
    padding: 2px;
}
`