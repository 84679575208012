//* External Imports
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
//* Hooks
import { useTranslation } from "react-i18next";

// Types Imports
import { CompletedExercise } from "../../types/UserTypes";
import { ExercisesType } from "../../types/GeneralTypes";

//? Internal Imports. 
//? JSX Components
import DonutChartComplex from "../charts/pieCharts/DonutChartComplex";
import MultiLineChart from "../charts/custom/multiLineChart";
import TaskChart from "../charts/custom/taskChart";
import LineChart from "../charts/line/LineChart";
import TReportWS from "./TReportWS";
import TaskReport from "./taskReport";
import Loader from "../loader";
import TitleComponent from "../TitleComponent";
//? Styled-Components
import { Button } from "../../styles/buttons/Button";
import { WhiteCard } from "../../styles/WhiteCard";
import { isMobile } from "../../responsive/mediaQueriesStates";
import RadarChart from "../charts/radar/RadarChart";
import { globalErrorHandler } from "../../axios/globalErrorHandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//! Images & CSS

type Props = {
    competency: string;
    colorScale: Array<string>
    buttonColorScale: Array<string>
    transparencyScale: Array<string>
    studentExe: CompletedExercise[],
    exercises: ExercisesType,
    standardColor: string,
    id_user: number,
    goBack: boolean,
    setGoBack: React.Dispatch<React.SetStateAction<boolean>>;
}

type ChartInfo = Array<{
    total: number,
    completed: number,
    passed: number
}>

type LineChartInfo = Array<{
    x: number,
    y: number
}>
type MultiLineChartInfo = {
    block: string[]
    info: Array<{
        partNum: number,
        data: Array<Array<{
            x: number,
            y: number
        }>>
    }>
}

const CompetencyReport = (props: Props): JSX.Element => {
    const { t } = useTranslation("translation", { keyPrefix: "competencyReport" })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileState = isMobile()
    const [loading, setLoading] = useState<boolean>(true)
    const [chartInfo, setChartInfo] = useState<ChartInfo>([{ total: 0, completed: 0, passed: 0 }])
    const [lineChartInfo, setLineChartInfo] = useState<LineChartInfo>([{ x: 0, y: 0 }])
    const [multiLineChartInfo, setMultiLineChartInfo] = useState<MultiLineChartInfo>({
        block: [""],
        info: [{ partNum: 0, data: [[{ x: 0, y: 0 }]] }]
    })
    const [multiLinePart, setMultiLinePart] = useState<number>(0)
    const [competencyMean, setCompetencyMean] = useState<number>(0)
    //Change report type
    const [taskReport, setTaskReport] = useState<boolean>(false)
    const [taskReportScreen, setTaskReportScreen] = useState<boolean>(false)
    const [competencyReportState, setCompetencyReport] = useState<boolean>(false)
    //TaskReport state variables
    const [parts, setParts] = useState<number>(-1);
    const [partsArray, setPartsArray] = useState<CompletedExercise[][]>([[]])
    const [toReport, setToReport] = useState<CompletedExercise>()
    const [task, setTask] = useState<string>("")
    const competency = props.competency === "Comprensión de lectura" ? "Reading" :
        props.competency === "Comprensión auditiva" ? "Listening" :
            props.competency === "Expresión e interacción oral" ? "Speaking" :
                props.competency === "Expresión e interacción escrita" ? "Writing" : props.competency
    const color = competency === "Reading" ? "#0B8EAB" :
        competency === "Listening" ? "#A2D9E7" :
            competency === "Speaking" ? "#FFC152" : "#F93E63"

    const initPartsArray = (arr: CompletedExercise[]) => {
        const baseArray: CompletedExercise[][] = [[]]
        props.exercises.forEach((elem) => {
            elem.forEach((elem2) => {
                const found = arr.find((elem3) => elem3.id_exercise === elem2.id_exercise)
                if (found) {
                    if (elem2.exercise_type === competency) {
                        if (elem2.task_part > baseArray.length) {
                            baseArray.push([found])
                        } else {
                            baseArray[elem2.task_part - 1].push(found)
                        }
                    }
                }
            })
        })
        return baseArray
    }
    useEffect(() => {
        axios.get<ChartInfo>(`${process.env.REACT_APP_API_URL}/taskChart/${competency}/${props.id_user}`).then((response) => {
            setChartInfo(response.data)
            axios.get<LineChartInfo>(`${process.env.REACT_APP_API_URL}/competencyLine/${competency}/${props.id_user}`).then((response) => {
                setLineChartInfo(response.data)
                axios.get<MultiLineChartInfo>(`${process.env.REACT_APP_API_URL}/blockInfo/${competency}/${props.id_user}`).then((response) => {
                    setMultiLineChartInfo(response.data)
                    axios.get<{ mean: number }>(`${process.env.REACT_APP_API_URL}/meanPercent/${competency}/${props.id_user}`).then((response) => {
                        setCompetencyMean(response.data.mean)
                        axios.get<CompletedExercise[]>(`${process.env.REACT_APP_API_URL}/completed/${competency}/${props.id_user}`).then((response) => {
                            setPartsArray(initPartsArray(response.data))
                            setLoading(false)
                        }).catch(() => globalErrorHandler(dispatch, navigate))
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }).catch(() => globalErrorHandler(dispatch, navigate))
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])

    const displayTasks = (arr: CompletedExercise[]) => {
        if (arr) {
            const taskInfo = props.exercises.flat(1).filter((elem) => arr.find((elem2) => elem2.id_exercise === elem.id_exercise))
            return arr.map((elem, i) => (
                    <TaskCardDiv key={i} state={isMobileState} onClick={() => [setToReport(arr[i]), setTask(`${t("Task")} ${parts + 1} - ${taskInfo[i].task_type}`), setTaskReportScreen(true)]}>
                        <ButtonTaskDiv color={props.buttonColorScale[1]}>
                            <strong style={{ fontWeight: "600", fontSize: "25px", color: props.colorScale[1] }}>{`${t("Task")} ${parts + 1} - ${taskInfo[i].task_type}`}<br /></strong>
                            <span>{t("Check report")}</span>
                        </ButtonTaskDiv>
                        <ScoreDiv color={props.colorScale[1]}>{elem.score}</ScoreDiv>
                    </TaskCardDiv>
                ))
        }
    }

    if (loading) {
        return <Loader loading={loading} />
    }

    if (taskReportScreen && toReport) {
        if ((competency === "Reading") || (competency === "Listening")) {
            return <TaskReport completedExercise={toReport}
                id_user={props.id_user} setToExam={setTaskReportScreen}
                goBack={taskReportScreen} setGoBack={setTaskReportScreen}
            />
        }
        return <TReportWS exercise={toReport} competency={competency} task={task}
            id_user={props.id_user} goBack={taskReportScreen} setGoBack={setTaskReportScreen}
        />
    }

    return (
        <MainDiv state={isMobileState}>
            <UpperDiv>
                <UpperLeftContainer>
                    <TitleComponent style={{ textAlign: isMobileState ? "left" : "center", color: color }} setGoBack={props.setGoBack} goBack={props.goBack} text={`${props.competency} ${t("Report")}`}></TitleComponent>
                    {/* MORE INFO MODAL */}
                    <UpperCardContainer>
                        <UpperCard>
                            <UpperCardText color={props.standardColor} fontSize="20px" fontWeight={700} style={{ marginBottom: "10px" }}>{t("Pass Rate")}</UpperCardText>
                            <UpperCardText color="#454545" fontSize="18px" fontWeight={600}>{`${Math.round(competencyMean)}%`}</UpperCardText>
                        </UpperCard>
                    </UpperCardContainer>
                </UpperLeftContainer>
                <UpperRightCharDiv>
                    <DonutDiv state={isMobileState}>
                        <div>Progress Task Completion</div>
                        <RadarChart
                            color={color}
                            data={chartInfo.map((elem) => ({
                                total: elem.total,
                                completed: elem.completed
                            }))}
                            radius={90}
                        />
                    </DonutDiv>
                </UpperRightCharDiv>
            </UpperDiv>
            <MiddleSpan>
                <ReportTypeButton color={props.standardColor} state={taskReport} onClick={() => [setTaskReport(true), setCompetencyReport(false)]}>{t("Task Report")}</ReportTypeButton>
                <ReportTypeButton color={props.standardColor} state={competencyReportState} onClick={() => [setTaskReport(false), setCompetencyReport(true)]}>{t("Competency Report")}</ReportTypeButton>
            </MiddleSpan>
            {taskReport && !competencyReportState &&
                <>
                    <TaskDiv>
                        <ButtonContainerDiv>
                            {

                                Array.from(new Set(props.exercises.flat(1).filter((elem) => elem.exercise_type === competency).map((elem2) => elem2.task_part))).map((elem3, i) => {
                                    if (partsArray[i]) {
                                        return <ButtonTask color={props.standardColor} onClick={() => setParts(i)} key={(elem3 * i) + 10}>
                                            {`${t("Part")} ${i + 1}`} <br />
                                        </ButtonTask>
                                    }
                                    return <ButtonTask color={"#B2AAAA"} key={(elem3 * i) + 10}>
                                        {`${t("Part")} ${i + 1}`} <br />
                                    </ButtonTask>
                                })
                            }
                        </ButtonContainerDiv>
                    </TaskDiv>
                    {parts >= 0 &&
                        <>
                            <TaskInfoDiv>
                                <div>
                                    <strong>{`${t("Part")} ${parts + 1}`}</strong>
                                </div>
                                <div>
                                    {props.exercises.flat(1).filter((elem) => (elem.task_part === parts + 1) && (elem.exercise_type === competency))[0].statement}
                                </div>
                            </TaskInfoDiv>
                            <ExamplesWrapCard>
                                {displayTasks(partsArray[parts])}
                            </ExamplesWrapCard>
                        </>
                    }
                </>
            }
            {competencyReportState && !taskReport &&
                <>
                    <ChartContainer>
                        <Chart>
                            <TaskChart
                                data={chartInfo}
                                color={props.buttonColorScale}
                            />
                        </Chart>
                    </ChartContainer>
                    <ChartContainer>
                        <Chart>
                            <LineChart
                                data={lineChartInfo}
                                color={props.standardColor}
                            />
                        </Chart>
                    </ChartContainer>
                    {(competency === "Writing" || competency === "Speaking") &&
                        <ChartContainer>
                            <ChartReportDiv>
                                {t("Report")}
                                <Select onChange={(e) => setMultiLinePart(Number(e.target.value))} value={multiLinePart}>
                                    {multiLineChartInfo.info ? multiLineChartInfo.info.map((elem, index) => <option key={index * 12.3} value={elem.partNum - 1}>
                                        {`${t("Part")} ${elem.partNum}`}
                                    </option>) : <option>{t("Part")} 1</option>
                                    }
                                </Select>
                            </ChartReportDiv>
                            <Chart>
                                <MultiLineChart
                                    data={multiLineChartInfo.info[multiLinePart].data}
                                    colorScale={["#F93E63", "#9747FF", "#FFD487", "#58C33D", "#479FF8"]}
                                    textArr={[t("Show All"), ...multiLineChartInfo.block]}
                                />
                            </Chart>
                        </ChartContainer>
                    }
                </>
            }
        </MainDiv>
    )
}

export default CompetencyReport;

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "95%" : "80%"};
    background: #F5F5F5;
    padding-bottom: 50px;
    gap: 20px;
`
const UpperDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    margin-top: 3%;
`
const UpperLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 45%;
    height: auto;
`
const UpperCardContainer = styled(WhiteCard)`
    align-items: center;
    justify-content: center;
    width: 50%; //100% of 50%
    height: 100%;
    padding: 10px;
`
const UpperCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    min-height: 80px;
`
const UpperCardText = styled.div<{ color: string, fontSize: string, fontWeight: number }>`
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
    font-Weight: ${props => props.fontWeight};
`
const DonutDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    div {
        font-weight: 700;
        font-size: ${props => props.state ? "15px" : "20px"};
    }
`
const UpperRightCharDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: auto;
    margin-top: 20px;
`
const MiddleSpan = styled(WhiteCard)`
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 40%;
    min-height: 125px;
`
const ReportTypeButton = styled(Button) <{ color: string, state: boolean }>`
    width: 45%;
    height: 50%;
    min-height: 62.5px;
    background: ${props => props.state ? "#B2AAAA" : props.color};
    color: white;
`
const TaskDiv = styled(WhiteCard)`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-top: 3%;
`
const ButtonContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
`
const ButtonTaskDiv = styled.div`
    width: auto;
    height: auto;
`
const ButtonTask = styled(Button) <{ color: string }>`
    color: white;
    margin: 20px;
    width: 20%;
`
const TaskInfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin: 40px;
    font-size: 18px;
    text-align: left;
`
const ScoreDiv = styled.div<{ color: string }>`
    text-align: right;
    font-weight: 700;
    width: auto;
    color: ${props => props.color};
`
const TaskCardDiv = styled(WhiteCard) <{ state: boolean }>`
    flex-direction: ${props => props.state ? "column" : "row"};
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin: 10px;
    width: 190px;
    height: 35%;
    min-height: 10%;
    padding: 15px;
    gap: ${props => props.state ? "10px" : "0px"};
    cursor: pointer;
    ${ScoreDiv} {
        text-align: ${props => props.state ? "left" : "right"};
    }
`
const ExamplesWrapCard = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`
const ChartContainer = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    margin-top: 20px;
`
const ChartReportDiv = styled.div`
    display: flex;
    align-self: flex-start;
    margin-left: 10%;
    margin-top: 30px;
    font-size: 30px;
    font-weight: 700;
`
const Select = styled.select`
    background: transparent;
    border: transparent;
    font-size: 30px;
    font-family: 'Familjen Grotesk', sans-serif;
    color: ${props => props.theme.fontColor};
    padding-left: 5px;
    font-weight: 700;
`
const Chart = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: 15px;
`