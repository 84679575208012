//* External Imports
import React from "react";
import styled from "styled-components";

export const VerticalDecorationLine = styled.div<{color: string}>`
    min-width: 6px;
    content: "";
    min-height: 100%;
    height: 100%;
    background-color: ${props => props.color};
    margin-left: 10px;
`