//* External Imports
import React from "react"

// Type Imports
import { MediaPermissionError, MediaPermissionErrorType, DialogType } from "../types/mediaTypes"

//? Internal Imports
//? Methods
import {requestAllPermissions, requestAudioPermissions, requestVideoPermissions} from "./requestMediaPermissions"

const setDialog = (err: MediaPermissionError): string => {
    console.log(err)
    if (err.type === MediaPermissionErrorType.SystemPermissionDenied) {
        // user denied permission
        return DialogType.systemDenied
    } else if (err.type === MediaPermissionErrorType.UserPermissionDenied) {
        // browser doesn't have access to devices
        return DialogType.userDenied
    } else if (err.type === MediaPermissionErrorType.CouldNotStartVideoSource) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        return DialogType.trackError
    }else{
        return DialogType.explanation
    }
}

export const checkMediaPermissions = async(media?: "audio" | "video"): Promise<boolean | string > => {
    if(media){
        return media === "audio" ? 
                requestAudioPermissions()
                    .then(() => true)
                    .catch((err: MediaPermissionError) => setDialog(err))
                :
                requestVideoPermissions()
                    .then(() => true)
                    .catch((err: MediaPermissionError) => setDialog(err))
    }
    return requestAllPermissions()
                .then(() => true)
                .catch((err: MediaPermissionError) => setDialog(err))
}

