//* External Imports
import React from "react";
import styled from "styled-components";

export const ButtonTransition = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: 0.45s;
    height: 30px;
    padding: 20px;
    font-size: 16px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    &:hover {
        background: white;
    }
`