//* External imports
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {VictoryAxis, VictoryChart, VictoryGroup, VictoryLine,VictoryScatter} from "victory";

type Props = {
    data: Array<Array<{
        x: string | number,
        y: string | number,
        y0?: string |number
    }>>
    width?: number,
    height?: number,
    colorScale?: Array<string>,
    textArr?: Array<string>
}

const MultiLineChart = (props: Props) => {
    const [displayed, setDisplayed] = useState<boolean[]>([true, false, false, false, false])
    const {t} = useTranslation("translation", {keyPrefix: "lineCharts"})
    const textArr: string[] = props.textArr ? props.textArr : ["Show all", "Reading", "Writing", "Speaking" ,"Listening"]
    const colorArr: string[] = props.colorScale ? props.colorScale : ["#454545", "#0B8EAB", "#F93E63", "#FFC152", "#A2D9E7"]
    const domainPadding = 10;
    const width: number = props.width ? props.width : 500;
    const height: number = props.height ? props.height : 210;
    const data = props.data ? props.data : []
    const changeValue = (index: number) => displayed.map((elem,i) => index===0 ? (i===index ? true : false) : (i===index ? !elem : (i===0 ? false : elem)))
    return(
        <svg viewBox={`0 0 ${width} ${height}`}>
            <g transform="translate(0, 0)">
                <foreignObject x="0" y="-5" width="100%" height="50">
                    <Div>
                        {displayed.map((_, i) => <Button state={displayed[i]} onClick={() => setDisplayed(changeValue(i))} key={i+10}><Leyenda color={colorArr[i]}/>{textArr[i]}</Button>)}
                    </Div>
                </foreignObject>
                <VictoryChart
                    standalone={false}
                    height={height}
                    width={width} 
                    domainPadding={{x: domainPadding}}
                > 
                    <VictoryAxis
                        dependentAxis
                        domain={[0, 100]}
                        tickValues={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        tickFormat={(elem) => `${elem}%`}
                        style={{
                            tickLabels: {fontSize: 7}
                        }}
                    />
                    <VictoryAxis
                        domain={[1,data.length]}
                        tickValues={data[0] ? new Array(data[0].length).fill(1).map((_,i)=> i+1) : [1,4]}
                        tickFormat={(elem) => `${t("Month")} ${elem}`}
                        style={{
                            tickLabels: {fontSize: 10}
                        }}
                    />
                        {(data && data.length > 0) && displayed.map((_, i) => {
                            const color: string = (data[i-1] && data[i-1][0].y===0 && data[i-1].length === 1) ? "transparent" : (props.colorScale ? props.colorScale[i] : colorArr[i])
                            return(
                                (i!==0) && (displayed[0] || displayed[i]) &&
                                    <VictoryGroup key={i}
                                        data={data[i-1]}
                                    >
                                        <VictoryLine
                                            style={{
                                                data: {stroke: color}
                                            }}
                                        />
                                        <VictoryScatter
                                            style={{
                                                data: {
                                                    fill: color
                                                }
                                            }}
                                        />
                                    </VictoryGroup>
                            )
                        })}
                </VictoryChart>
                <g>
                    <line x1={50} y1={(height/2)-10} x2={width-50} y2={(height/2)-10} stroke="black" strokeWidth={.5} strokeDasharray={5} strokeOpacity="40%"/>
                    <text x={width-50} y={(height/2)-8} fontSize={7}>
                        {t("Pass Mark")}
                    </text>
                </g>
            </g>
        </svg>
    )
}

export default MultiLineChart;

const Button = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    min-height: 18px;
    background: ${props => props.state ? "#D3CFCF" : "transparent"};
    border: 0.5px solid ${props => props.state ? "rgba(0, 0, 0, 0.2)" : "transparent"};
    box-shadow: ${props => props.state ? "inset 0px 2px 1px rgba(0, 0, 0, 0.25)" : "0px 1px 1px rgba(0, 0, 0, 0.25)"};
    border-radius: 2px;
    height: 10px;
    font-size: 8px;
    margin-top: 20px;
    margin-left: 10px;
    padding: 0px 2px 0px 2px;
`
const Leyenda = styled.div<{color: string}>`
    min-width: 6px;
    min-height: 6px;
    margin-right: 2px;
    background-color: ${props => props.color};
    border: none;
`
const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: fit-content;
    cursor: pointer;
`