/* eslint-disable arrow-body-style */
//* External Imports
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

//? Internal Imports
//? JSX Components
import PlayPauseButton from "./PlayPauseButton"
import AudioTimer from "./AudioDuration"
import AudioSlide from "./AudioSlide"
//? Styled-Components
import { WhiteCard } from "../../styles/WhiteCard"

type MediaPlayerProps = {
    src: string,
    onFinish: () => void[]
}


const MediaPlayer = (props: MediaPlayerProps) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const [audioProgress, setAudioProgress] = useState<number>(0)
    const audioRef = useRef<HTMLAudioElement>(new Audio(props.src))
    const [duration, setDuration] = useState<number>(audioRef.current.duration)
    const intervalRef = useRef<any>()

    useEffect(() => {
        return () => {
            audioRef.current.pause()
            clearInterval(intervalRef.current)
        }
    }, [])

    useEffect(() => {
        audioRef.current.addEventListener('loadedmetadata', () => {
            if ((audioRef.current.duration === Infinity) || isNaN(Number(audioRef.current.duration))) {
              audioRef.current.currentTime = 1e101
              audioRef.current.addEventListener('timeupdate', getDuration)
            }
          })
    }, [audioRef])

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play()
            startTimer();
        } else {
            audioRef.current.pause()
        }
    }, [isPlaying])

    const getDuration = () => {
        audioRef.current.currentTime = 0;
        audioRef.current.removeEventListener('timeupdate', getDuration)
        setDuration(audioRef.current.duration)
    }

    const startTimer = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                setIsPlaying(false)
                props.onFinish();
            } else {
                setAudioProgress(audioRef.current.currentTime)
            }
        }, 1000)
    }

    const onScrub = (value: string) => {
        clearInterval(intervalRef.current)
        audioRef.current.currentTime = Number(value)
        setAudioProgress(audioRef.current.currentTime)
    }

    const onScrubEnd = () => {
        if (!isPlaying) {
            setIsPlaying(true)
        }
        startTimer()
    }

    return (
        <MediaComponentsWrapper>
            <PlayPauseButton isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
            <AudioTimer audioTime={duration}/>
            <AudioSlide
               max={duration ? Math.trunc(duration) : 0}
               value={audioProgress}
               onChange={onScrub} 
               onScrubEnd={onScrubEnd}
            />
            <AudioTimer audioTime={audioProgress}/>
        </MediaComponentsWrapper>
    )
}

export default MediaPlayer

const MediaComponentsWrapper = styled(WhiteCard)`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #F1F3F4;
    box-shadow: ${props => props.theme.basicBoxShadow};
    border-radius: 20px;
    width: fit-content;
    height: 40px;
    padding: 3px 10px 3px 10px;
    gap: 5px;
`
