//* External Imports
import React from "react";
import styled from "styled-components";

type Props = {
    loading: boolean
}

/** Generic Loading animation with overlay */
const Loader = (props: Props): JSX.Element => (
    <Wrapper state={props.loading}>
        <PosWrapper>
            <Main>
                <div /><div /><div /><div /><div /><div /><div /><div /><div />
            </Main>
        </PosWrapper>
    </Wrapper>
)

const Wrapper = styled.div<{ state: boolean }>`
    position: fixed;
    display: ${props => props.state ? "block" : "none"};
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 6;
    cursor: pointer;
`
const PosWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
`
const Main = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${props => props.theme.langooBlue};
        animation: Main 1.2s linear infinite;
    }
    div:nth-child(1){
        top: 8px;
        left: 8px;
        animation-delay: 0s;
    }
    div:nth-child(2){
        top: 8px;
        left: 32px;
        animation-delay: -0.4s;
    }
    div:nth-child(3){
        top: 8px;
        left: 56px;
        animation-delay: -0.8s;
    }
    div:nth-child(4){
        top: 32px;
        left: 8px;
        animation-delay: -0.4s;
    }
    div:nth-child(5){
        top: 32px;
        left: 32px;
        animation-delay: -0.8s;
    }
    div:nth-child(6){
        top: 32px;
        left: 56px;
        animation-delay: -1.2s;
    }
    div:nth-child(7){
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
    }
    div:nth-child(8){
        top: 56px;
        left: 32px;
        animation-delay: -1.2s;
    }
    div:nth-child(9){
        top: 56px;
        left: 56px;
        animation-delay: -1.6s;
    }
    @keyframes Main {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
    }
`
export default Loader;