//* External Imports
import React from "react"
import styled from "styled-components";

export const ButtonCircle = styled.button<{color?: string, state?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.state === true ? "#B2AAAA" : (props.color ?? "#0B8EAB")};
    border: ${props => props.state === true ? "#B2AAAA" : (props.color ?? "#0B8EAB")};
    color: white;
    border-radius: 50%;
    padding: 20px;
    height: 90px;
    width: 90px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    cursor: pointer;
`