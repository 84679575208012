//* External Imports
import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
//* Hooks
import { useTranslation } from "react-i18next";
//* Methods
import { format } from "date-fns";

// Type Imports
import { UserType } from "../../types/UserTypes";

//? Internal Imports
//? JSX Components
import MockDeleChart from "../charts/custom/mockDeleChart";
import DonutChartSimpleMod from "../charts/pieCharts/DonutChartSimpleMod";
import MockCambridge from "../charts/custom/mockCambridge";
import TitleComponent from "../TitleComponent";
import Feedback from "../feedback";
//? Styled-Components
import { Button } from "../../styles/buttons/ButtonRound";
import { WhiteCard } from "../../styles/WhiteCard";
import { SimpleTitle } from "../../styles/SimpleTitle";
import { isMobile } from "../../responsive/mediaQueriesStates";

Modal.setAppElement("body")

type Props = {
    userData: UserType;
    text?: string;
    audio?: string;
    examStatus: ExamStatus
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    goBack?: boolean;
    setGoBack?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        maxWidth: "90%",
        height: "auto",
        background: "transparent",
        border: "1px solid transparent"
    },
    overlay: {
        zIndex: 1000
    }
}
type ExamStatus = {
    chart?: {
        data: Array<number>
        pointsArray?: Array<number>
        xText?: Array<string>
        levels?: Array<string>
    }
    info: {
        testDate: Date
        timeSpent: number
        notes?: string
        passed?: string
    }
}

/** Mock exam Report */
const MockExamReport = (props: Props): JSX.Element => {
    const {t} = useTranslation("translation", {keyPrefix: "mockExamResults"})
    const isMobileState = isMobile()
    const [modal, setModal] = useState<boolean>(false);

    const openModal = () => {
        setModal(true)
    }
    const closeModal = () => {
        setModal(false)
    }
    return (
        <MainDiv state={isMobileState}>
            <TextDiv>
                {(props.setGoBack && props.goBack) ?
                    <TitleComponent setGoBack={props.setGoBack} goBack={props.goBack} text={t("Mock Exam Report")}/>
                    :
                    <SimpleTitle>{t("Mock Exam Report")}</SimpleTitle>
                }
                {props.examStatus && !props.examStatus.chart && <OpText>{t("Your teacher is reviewing the results, they will be uploaded soon.")}</OpText>}
            </TextDiv>
            <MiddleSpan>
                <InfoCard style={{borderLeft: "none"}}>
                    <UpperInfoCard>
                        {t("Student")}
                    </UpperInfoCard>
                    <DownInfoCard>
                        {props.userData.name}
                    </DownInfoCard>
                </InfoCard>
                <InfoCard>
                    <UpperInfoCard>
                        {t("Pass/Fail")}
                    </UpperInfoCard>
                    <DownInfoCard>
                        {/* props,exam.passed when new database*/}
                        {props.examStatus && (props.examStatus.info.passed ? t(`${props.examStatus.info.passed}`) : "---")}
                    </DownInfoCard>
                </InfoCard>
                <InfoCard>
                    <UpperInfoCard>
                        {t("Test date")}
                    </UpperInfoCard>
                    <DownInfoCard>
                        {props.examStatus && format(new Date(props.examStatus.info.testDate), "dd/MM/yyyy")}
                    </DownInfoCard>
                </InfoCard>
                <InfoCard>
                    <UpperInfoCard>
                        {t("Time Spent")}
                    </UpperInfoCard>
                    <DownInfoCard>
                        {props.examStatus && Math.round(props.examStatus.info.timeSpent)} {t("minutes")}
                    </DownInfoCard>
                </InfoCard>
            </MiddleSpan>
            {props.examStatus && props.examStatus.chart &&
                <>
                    <ChartDiv>
                        <ChartCard width={"25%"}>
                            {props.examStatus.chart.levels ?
                                <DonutChartSimpleMod
                                    colorScale={["#D9D9D9", "#0B8EAB"]}
                                    y={(props.examStatus.chart.data.reduce((acc, cur) => acc + cur, 0) / props.examStatus.chart.data.length) / 100}
                                    passed={props.examStatus.info.passed ? t(`${props.examStatus.info.passed}`) : ""}
                                    labelValue={(props.examStatus.chart.data.reduce((acc, cur) => acc + cur, 0) / props.examStatus.chart.data.length)}
                                    labelValue2={t("Overall Score") ?? ""}
                                    labelValue3={`${props.examStatus.chart.levels[1]}: ${props.examStatus.chart.levels[1] === "B2" ? 160 : (props.examStatus.chart.levels[0] === "B1" ? 140 : 180)}`}
                                />
                                :
                                <DonutChartSimpleMod
                                    colorScale={["#D9D9D9", "#0B8EAB"]}
                                    y={Math.round((props.examStatus.chart.data.reduce((acc, cur) => acc + cur, 0))) / 10}
                                    passed={props.examStatus.info.passed ? t(`${props.examStatus.info.passed}`) : ""}
                                    labelValue={`${Math.round((props.examStatus.chart.data.reduce((acc, cur) => acc + cur, 0)))}%`}
                                />
                            }
                        </ChartCard>
                        <ChartCard width={"70%"}>
                            {props.examStatus.chart.levels ?
                                <MockCambridge
                                    data={props.examStatus.chart.data}
                                    levels={props.examStatus.chart.levels!}
                                    xText={props.examStatus.chart.xText!}
                                    pointsArray={props.examStatus.chart.pointsArray!}
                                />
                                :
                                <MockDeleChart
                                    data={props.examStatus.chart.data}
                                />
                            }
                        </ChartCard>
                    </ChartDiv>
                    {!props.examStatus.chart.levels &&
                        <GradeDiv>
                            <GradeRow>
                                <div style={{ fontWeight: 700, fontSize: "20px" }}>{t("Grade system")}</div>
                                <GradeButton onClick={openModal}>{t("See Grade Chart")}</GradeButton>
                            </GradeRow>
                            <GradeRow style={{height: "50%"}}>
                                <GradeCard width={"33%"}>
                                    <div style={{ fontWeight: 700 }}>{t("Competency:")}</div>
                                    <div>{t("Maximun score")} 25</div>
                                </GradeCard>
                                <GradeCard width={"33%"}>
                                    <div style={{ fontWeight: 700 }}>{t("Blocks:")}</div>
                                    <div>{t("Minimun to pass")} 30% {t("(the result between 2 competencies)")}</div>
                                </GradeCard>
                                <GradeCard width={"33%"}>
                                    <div style={{ fontWeight: 700 }}>{t("Mock Exam")}:</div>
                                    <div>{t("Minimun to pass")} 60% {t("(this metric is the result of the 2 blocks)")}</div>
                                </GradeCard>
                            </GradeRow>
                        </GradeDiv>
                    }
                    <Feedback text={(props.examStatus.info.notes ?? "")} width="100%"/>
                    {!props.examStatus.chart.levels &&
                        <Modal
                            isOpen={modal}
                            onRequestClose={closeModal}
                            style={ModalStyle}
                            contentLabel="Chart grade"
                        >
                            <Table>
                                <tr>
                                    <Th style={{ borderLeft: "transparent" }} rowSpan={2}></Th>
                                    <Th colSpan={2}>{t("Block")} 1</Th>
                                    <Th colSpan={2}>{t("Block")} 2</Th>
                                </tr>
                                <Tr>
                                    <Td width="191px" color="#0B8EAB">{t("Reading")}</Td>
                                    <Td width="191px" color="#F93E63">{t("Writing")}</Td>
                                    <Td width="191px" color="#A2D9E7">{t("Listening")}</Td>
                                    <Td width="191px" color="#FFC152 ">{t("Speaking")} </Td>
                                </Tr>
                                <Tr>
                                    <TdLeft>{t("Maximun score")}</TdLeft>
                                    <Td style={{ fontSize: "25px" }}>25</Td>
                                    <Td style={{ fontSize: "25px" }}>25</Td>
                                    <Td style={{ fontSize: "25px" }}>25</Td>
                                    <Td style={{ fontSize: "25px" }}>25</Td>
                                </Tr>
                                <Tr>
                                    <TdLeft>{t("Minimun score to pass")}</TdLeft>
                                    <Td style={{ fontSize: "25px" }} color="#5ECC95" colSpan={2}>30</Td>
                                    <Td style={{ fontSize: "25px" }} color="#5ECC95" colSpan={2}>30</Td>
                                </Tr>
                                <Tr>
                                    <TdLeft>{t("Grade")}</TdLeft>
                                    <TdApt colSpan={2}>{t("Apto")}</TdApt>
                                    <TdApt colSpan={2}>{t("Apto")}</TdApt>
                                </Tr>
                                <Tr>
                                    <TdLeft>{t("Minimun score to pass")}</TdLeft>
                                    <Td style={{ fontSize: "25px" }} color="#5ECC95" colSpan={4}>60</Td>
                                </Tr>
                                <Tr>
                                    <TdLeft>{t("Grade")}</TdLeft>
                                    <TdApt colSpan={4}>{t("Apto")}</TdApt>
                                </Tr>
                            </Table>
                        </Modal>
                    }
                </>
            }
        </MainDiv>
    )
}

export default MockExamReport;

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "95%" : "80%"};
    min-height: 100vh;
    background: #F5F5F5;
    padding-bottom: 5px;
`
const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`
const OpText = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.theme.fontColor};
    margin-top: 10px;
    margin-bottom: 15px;
`
const MiddleSpan = styled(WhiteCard)`
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 25%;
    padding-top: 5px;
    padding-bottom: 5px;
`
const InfoCard = styled.div`
    display: flex;
    border-left: 0.5px solid #D3CFCF;
    flex-direction: column;
    width: 25%;
    height: 100%;
`
const UpperInfoCard = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.theme.langooBlue};
    margin-bottom: 4px;
    margin-top: 10px;
`
const DownInfoCard = styled.div`
    font-weight: 600;
    margin-bottom: 10px;
`
const ChartDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
`
const ChartCard = styled(WhiteCard)<{ width: string }>`
    align-items: center;
    width: ${props => props.width};
    height: fit-content;
    margin-top: 40px;
`
const GradeDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 10px;
`
const GradeRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
`
const GradeButton = styled(Button)`
    background: ${props => props.theme.langooRed};
    border: ${props => props.theme.langooRed};
    padding: 8px 20px 8px 20px;
    border-radius: 8px;
    width: auto;
    height: auto;
    font-size: 18px;
`
const GradeCard = styled.div<{ width: string }>`
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 10px;
    border: 3px solid ${props => props.theme.langooLightBlue};
    background: transparent;
    font-size: 16px;
    padding: 10px;
    min-height: 60px;
`
const Table = styled.table`
    background: white;
    border-radius: 20px;
    filter: drop-shadow(0px 10px 4px rgba(0, 0, 0, 0.25));
    border-collapse: separate;
    border-spacing: 0;
    font-weight: 600;
    td {
        height: 50px;
    }
`
const Tr = styled.tr`
    text-align: center;
`
const Td = styled.td<{ color?: string, width?: string }>`
    color: ${props => props.color ? "white" : "black"};
    background: ${props => props.color ? props.color : "transparent"};
    width: ${props => props.width ? props.width : "auto"};
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-right: transparent;
    border-bottom: transparent;
    font-size: 20px;
`
const TdLeft = styled.td`
    text-align: left;
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
`
const TdApt = styled.td`
    border-left: 0.5px solid rgba(0, 0, 0, 0.2);
    font-size: 20px;
`
const Th = styled.th`
    border-left: 0.5px solid rgba(0, 0, 0, 0.2);
    font-size: 20px;
    height: 50px;
`