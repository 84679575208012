/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable arrow-body-style */
//* External Imports
import Bowser from "bowser"

// Type Imports
import { MediaPermissionErrorType} from "../types/mediaTypes"


const requestMediaPermissions = (constrains: MediaStreamConstraints) => {
    return new Promise<boolean>((resolve, reject)=>{
        navigator.mediaDevices.getUserMedia(constrains)
            .then((stream: MediaStream) => {
                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    track.stop()
                });
                resolve(true)
            })
            .catch((err: any) => {
                const browser = Bowser.getParser(window.navigator.userAgent);
                const browserName = browser.getBrowserName();
                const errName = err.name
                const errMessage = err.message
                let errorType = MediaPermissionErrorType.Generic
                if(browserName === "Chrome"){
                    if(errName === "NotAllowedError"){
                        if(errMessage === "Permission denied by system"){
                            errorType = MediaPermissionErrorType.SystemPermissionDenied;
                        }else if(errMessage === "Permission denied" || errMessage === "Permission dismissed"){
                            errorType = MediaPermissionErrorType.UserPermissionDenied;
                        }
                    }else if(errName === "NotReadableError"){
                        errorType = MediaPermissionErrorType.CouldNotStartVideoSource;
                    }
                }else if(browserName === "Safari"){
                    if(errName === "NotAllowedError"){
                        errorType = MediaPermissionErrorType.UserPermissionDenied;
                    }
                }else if(browserName === "Microsoft Edge"){
                    if(errName === "NotAllowedError"){
                        errorType = MediaPermissionErrorType.UserPermissionDenied;
                    }else if(errName === "NotReadableError"){
                        errorType = MediaPermissionErrorType.CouldNotStartVideoSource;
                    }
                }else if(browserName === "Firefox"){
                    if(errName === "NotFoundError"){
                        errorType = MediaPermissionErrorType.SystemPermissionDenied;
                    }else if(errName === "NotReadableError"){
                        errorType = MediaPermissionErrorType.SystemPermissionDenied
                    }else if(errName  === "NotAllowedError"){
                        errorType = MediaPermissionErrorType.UserPermissionDenied;
                    }else if(errName === "AbortError"){
                        errorType = MediaPermissionErrorType.CouldNotStartVideoSource;
                    }
                }
                reject({
                    type: errorType,
                    name: err.name,
                    message: err.message
                })
            })
    })  
}

export const requestAudioPermissions = () => requestMediaPermissions({audio: true, video: false})
export const requestVideoPermissions = () => requestMediaPermissions({audio: false, video: true})
export const requestAllPermissions = () => requestMediaPermissions({audio: true, video: true})