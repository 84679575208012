/* eslint-disable @typescript-eslint/ban-ts-comment */
//* External Imports
import React from "react";
import Bowser from "bowser";
//* Hooks
import { useTranslation } from "react-i18next";

// Type Imports
import { MediaType } from "../../mediaPermissions/types/mediaTypes";

const SystemDeniedDialog = (props: {media?: MediaType}) => {
    const { t } = useTranslation()
    const browser = Bowser.getParser(window.navigator.userAgent);
    const mediaTranslation = t(props.media ? props.media : "camera or microphone")
    const settingsDataByOs = {
        macOs: {
            name: "System Preferences",
            link: "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera",
        },
    };
    return (
        <div>
            <div>{t("Can't use your")} {mediaTranslation}</div>
            <div>
                {t("Your browser might not have access to your")} {mediaTranslation}.
                {t("To fix this problem, open")}
                {
                    // @ts-ignore
                    settingsDataByOs[browser.getOSName()] ? <a
                        onClick={() => {
                            window.open(
                                // @ts-ignore
                                settingsDataByOs[browser.getOSName()]
                                    .link,
                                '_blank',
                            );
                        }}
                    >
                        {
                            // @ts-ignore
                            settingsDataByOs[browser.getOSName()].name
                        }
                    </a> : "Settings"
                }
            </div>
        </div>
    )
}

export default SystemDeniedDialog