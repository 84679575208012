/** 
 * Returns a string with a timer format hh:mm:ss from minutes 
 * @param {number} secs - Seconds
 * */
export const secsToTimerMinutes = (secs: number) => {
    const totalMinutes = Math.floor(secs / 60);

    const seconds = Math.trunc(secs % 60);
    const minutes = totalMinutes % 60;
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
}