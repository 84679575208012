//* External imports
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
    data: Array<{
        total: number,
        completed: number,
        passed: number
    }>
    color: string[],
}

const TaskChart = (props: Props) => {
    const {t} = useTranslation("translation", {keyPrefix: "taskChart"})
    const maxWidth = 500;
    return(
        <MainDiv>
            <LegendContainer>
                <LegendText>
                    <LegendGradient colors={props.color}/>
                    <span>{t("Passed / Completed & Passed")}</span>
                </LegendText>
                <LegendText>
                    <Legend color="#D9D9D9"/>
                    <span>{t("Completed & Failed")}</span>
                </LegendText>
                <LegendText>
                    <Legend color="white"/>
                    <span>{t("Total")}</span>
                </LegendText>
            </LegendContainer>
            {props.data.map((elem, index) => {
                const completedPercent = (maxWidth*elem.completed)/elem.total
                const passedPercent = (((maxWidth*elem.completed)/elem.total)*elem.passed)/elem.completed
                return <BarWrapper key={index}>
                    <span>{`Part ${index+1}`}</span>
                    <TotalData>
                        {completedPercent === passedPercent ? 
                            <TotalCompleted value={completedPercent} color={props.color[index]} colorText={index > 4 ? "#e2e1e1" : "#454545"}>
                                <span style={{width: "100%"}}>{elem.passed}</span>
                            </TotalCompleted>
                            :
                            <TotalCompleted value={completedPercent}>
                                <TotalPassed value={!isNaN(passedPercent) ? passedPercent : 0} color={props.color[index]} colorText={index > 4 ? "#e2e1e1" : "#454545"}>
                                    <span  style={{width: "100%"}}>{elem.passed}</span>
                                </TotalPassed>
                                {/* <span style={{width: `${completedPercent-passedPercent}px`}}>{elem.passed!==elem.completed && elem.completed}</span> */}
                            </TotalCompleted>
                        }
                        <span style={{width: `${maxWidth-completedPercent}px`}}>{elem.total}</span>
                    </TotalData>
                </BarWrapper>
            })}
        </MainDiv>
    )
}

export default TaskChart

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    span {
        font-weight: 700;
        font-size: 20px;
    }
`
const BarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
`
const LegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`
const LegendText = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 20px;
`
const LegendGradient = styled.div<{colors: string[]}>`
    width: 25px;
    height: 25px;
    background-image: ${props => `linear-gradient(${props.colors.toString()})`};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-right: 5px;
`
const Legend = styled.div<{color: string}>`
    width: 25px;
    height: 25px;
    background-color: ${props => props.color};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-right: 5px;
`
const TotalData = styled.div`
    display: flex;
    text-align: end;
    align-items: center;
    border: none;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 500px;
    height: 35px;
    border-radius: 10px;
    margin: 20px 20px;
    overflow: hidden;
    padding-right: 5px;
    span {
        padding-left: 5px;
    }
`
const TotalCompleted = styled.div<{value: number, color?: string, colorText?: string}>`
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    background: ${props => props.color ? props.color : "#D9D9D9"};
    height: 100%;
    border-radius: 10px;
    color: ${props => props.colorText ? props.colorText : "#454545"};
    text-align: end;
    padding-right: 5px;
    width: ${props => props.value}px;
`
const TotalPassed = styled.div<{color: string, colorText: string, value: number}>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    border: none;
    color:  ${props => props.colorText};
    font-weight: 700;
    background: ${props => props.color};
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    padding-right: 5px;
    width: ${props => props.value}px;
`

/**
    animation: TotalCompleted 1.5s ease-in-out forwards;
    @keyframes TotalCompleted {
        0% {
            width: 0px;
        };
        100% {
            width: ${props => `${props.value}px`};
        }
    }
    animation: TotalPassed 1.5s ease-in-out forwards;
    @keyframes TotalPassed {
        0% {
            width: 0px;
        };
        100% {
            width: ${props => `${props.value}px`};
        }
    }
 */