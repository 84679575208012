//* External Imports
import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
//* Hooks
import { useTranslation } from "react-i18next";
//* Methods
import { format } from "date-fns"

// Type Imports
import { UserType } from "../types/UserTypes";

//! Images
import profileIconBig from "../assets/icons/profileIcon.svg"
import { isMobile } from "../responsive/mediaQueriesStates";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { globalErrorHandler } from "../axios/globalErrorHandler";

type Props = {
    userData: UserType
}

type StaticUserInfo = {
    name: string,
    surname: string,
    email: string,
    password: string
}

/** Profile page main component
 *  @param {UserType} userData - Logged user general info
 * 
 */
const Profile = (props: Props): JSX.Element => {
    const { t } = useTranslation("translation", { keyPrefix: "profile" })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileState = isMobile()
    const hoursSelectOptions = ["<8", "8", "10", "15", "20", "25", ">25"]
    const staticUserStates: StaticUserInfo = {
        name: props.userData.name.split(" ")[0],
        surname: props.userData.surname,
        email: props.userData.email,
        password: props.userData.certification,
    }
    const [modUserStates, setModUserStates] = useState<{
        exam_date: Date | string,
        goal: string | undefined
    }>({
        exam_date: props.userData.exam_date ?? undefined,
        goal: props.userData.goal ?? undefined
    })
    const [inputState, setInputState] = useState<boolean>(props.userData.exam_date ? false : true)
    const onChangeEnrolled = (value: string) => {
        setInputState(value === "yes" ? false : true)
    }
    /** Change the value of the exam date on change */
    const onChangeExamDate = async(e: React.ChangeEvent<HTMLInputElement>) => {
        setModUserStates({
            ...modUserStates,
            exam_date: e.target.value
        })
        await axios.post(`${process.env.REACT_APP_API_URL}/examDate`, {
            idUser: props.userData.id,
            examDate: format(new Date(e.target.value), "dd-MM-yyyy")
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }
    /** Change the value of the goal on change */
    const onChangeGoal = async(e: React.ChangeEvent<HTMLSelectElement>) => {
        setModUserStates({
            ...modUserStates,
            goal: e.target.value
        })
        await axios.post(`${process.env.REACT_APP_API_URL}/newGoal`, {
            idUser: props.userData.id,
            newGoal: e.target.value
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }
    return (
        <MainDiv state={isMobileState}>
            <ProfileDiv>
                <img src={profileIconBig} alt="profileImgBig" />
                <div>{t("Profile")}</div>
            </ProfileDiv>
            <InputRow style={{ marginBottom: "30px" }}>
                <InputContainer width="47%">
                    <span>
                        {t("Name")}
                    </span>
                    <div>{staticUserStates.name}</div>
                </InputContainer>
                <InputContainer width="47%">
                    <span>
                        {t("Surname")}
                    </span>
                    <div>{staticUserStates.surname}</div>
                </InputContainer>
            </InputRow>
            <InputRow>
                <InputContainer width="28%">
                    <span>
                        {t("Email")}
                    </span>
                    <div>{staticUserStates.email}</div>
                </InputContainer>
            </InputRow>
            <Decoration />
            <InputRow>
                <InputContainer width="28%">
                    <span>
                        {t("Have you enrolled in the exam?")}
                    </span>
                    <select defaultValue={modUserStates.exam_date ? "yes" : "no"} onChange={(e) => onChangeEnrolled(e.target.value)}>
                        <option value="no" >{t("no")}</option>
                        <option value="yes">{t("yes")}</option>
                    </select>
                </InputContainer>
                <InputContainer width="28%">
                    <span>
                        {t("Exam date")}
                    </span>
                    <input type="date" disabled={inputState} value={modUserStates.exam_date ? (new Date(modUserStates.exam_date)).toISOString().split("T")[0] : undefined} onChange={onChangeExamDate}/>
                </InputContainer>
                <InputContainer width="28%">
                    <span>
                        {t("Hours of study per week")}
                    </span>
                    <select onChange={onChangeGoal} defaultValue={props.userData.goal}>
                        {hoursSelectOptions.map((elem) => <option value={elem}>{elem}</option>)}
                    </select>
                </InputContainer>
            </InputRow>
            <Decoration />
        </MainDiv>
    )
}

export default Profile;

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "95%" : "80%"};
    background: #F5F5F5;
    padding-bottom: 30px;
    margin-top: 50px;
`
const ProfileDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 30%;
    color: ${props => props.theme.langooBlue};
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 60px;
`
const InputRow = styled.div<{ disable?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    height: 100%;
    min-height: 60px;
    margin-bottom: 10px;
    button {
        background: ${props => props.disable ? "#C8C5C5" : props.theme.langooRed};
        border: 1px solid ${props => props.disable ? "#C8C5C5" : props.theme.langooRed};
        border-radius: 10px;
        box-shadow: ${props => props.theme.basicBoxShadow};
        color: white;
        font-size: 20px;
        padding: 5px;
        margin-top: 17.5px;
        width: 28%;
        height: 35px;
    }
`
const InputContainer = styled.div<{ width: string }>`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: ${props => props.width};
    height: 100%;
    font-size: 19px;
    font-weight: 700;
    gap: 20px;
    span  {
        padding-left: 15px;
    }
    div {
        width: 95%;
        min-height: 35px;
        height: 35px;
        font-weight: normal;
        border: none;
        border-bottom: 3px solid ${props => props.theme.langooBlue};
        font-size: 17px;
        border-radius: 5px;
        padding-left: 15px;
    }
    input {
        width: 95%;
        min-height: 35px;
        height: 35px;
        font-weight: normal;
        border: none;
        background: transparent;
        border-bottom: 3px solid ${props => props.theme.langooBlue};
        font-size: 17px;
        border-radius: 5px;
        padding-left: 15px;
        cursor: pointer;
    }
    select {
        width: 95%;
        min-height: 35px;
        height: 35px;
        font-weight: normal;
        border: none;
        background: transparent;
        border-bottom: 3px solid ${props => props.theme.langooBlue};
        font-size: 17px;
        border-radius: 5px;
        padding-left: 15px;
        cursor: pointer;
    }
`
const Decoration = styled.div`
    min-height: 30px;
    min-width: 95%;
    border-bottom: 2px solid #D9D9D9;
    margin-bottom: 30px;
`