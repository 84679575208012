/* eslint-disable arrow-body-style */
//* External Imports
import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
//* Hooks
import { useTranslation } from "react-i18next"
//* Methods
import { millisecondsToHours } from "date-fns"

//? Internal Imports
//? JSX Components
import DonutChartCompetency from "../charts/pieCharts/DonutChartCompetency"
//? Methods
import { isMobile, isTablet } from "../../responsive/mediaQueriesStates"
//? Styled-components
import { Button } from "../../styles/buttons/Button"
import { ButtonTransition } from "../../styles/buttons/ButtonTransition"
import { objectToArrayByCertificactionAndLevel } from "../../utils/objectToArrayByCertificationAndLevel"
import { wrap } from "module"

type Props = {
    setScreenStatesOnClick: (index: number) => void
    findTrueScreenState: (index: number, arr?: boolean[]) => boolean
    exam_date: Date;
    learningPathPercent: { reading: number; listening: number; speaking: number; writing: number; }
    userData: {certification: string, certification_level: string}
}

const TopNavBar = (props: Props) => {
    const timeToExam = Math.floor(millisecondsToHours((new Date(props.exam_date).getTime() - new Date(Date.now()).getTime())) / 24);
    const isLanding = props.findTrueScreenState(6)
    const isMobileState = isMobile()
    const isTabletState = isTablet()
    const { t } = useTranslation("translation", { keyPrefix: "topNavBar" })
    return (
        <MainDiv state={isMobileState}>
            {isLanding && !isMobileState && !isTabletState && <OverallDaysTo>
                <DaysTo>
                    {timeToExam > 0 ? timeToExam : 0}&nbsp;
                    <span>{t("days")}</span>
                </DaysTo>
                <div>{t("To take the test")}</div>
            </OverallDaysTo>
            }
            <div style={{ width: (isLanding && !isMobileState) ? "auto" : "100%", display: "flex", alignItems: "center", justifyContent: (isLanding && !isMobileState) ? "center" : (!isLanding && isMobileState) ? "flex-start" : "flex-end", gap: "20px", flexWrap: "wrap"}}>
                {((isLanding && (isMobileState || isTabletState)) || (!isLanding)) &&
                    <>
                        {
                            (objectToArrayByCertificactionAndLevel({
                                reading: {
                                    color: "#0B8EAB",
                                    data: [{ x: 2, y: 100 - props.learningPathPercent.reading }, { x: 1, y: props.learningPathPercent.reading }],
                                    name: t("Reading") ?? "Reading"
                                },
                                writing: {
                                    color: "#F93E63",
                                    data: [{ x: 2, y: 100 - props.learningPathPercent.writing }, { x: 1, y: props.learningPathPercent.writing }],
                                    name: t("Writing") ?? "Writing"
                                },
                                speaking: {
                                    color:"#FFC152",
                                    data:[{ x: 2, y: 100 - props.learningPathPercent.speaking }, { x: 1, y: props.learningPathPercent.speaking }],
                                    name: t("Speaking") ?? "Speaking"
                                },
                                listening: {
                                    color: "#A2D9E7",
                                    data: [{ x: 2, y: 100 - props.learningPathPercent.listening }, { x: 1, y: props.learningPathPercent.listening }],
                                    name: t("Listening") ?? "Listening",
                                }
                            }, props.userData.certification, props.userData.certification_level) as {[key: string]: any}).map((elem: any, i: number) => 
                                <DonutWrapper state={isMobileState} color={elem.color} key={i}>
                                    <div>{elem.name}</div>
                                    <DonutChartCompetency
                                        color={elem.color}
                                        data={elem.data}
                                        textCompetency={elem.name}
                                    />
                                </DonutWrapper>
                            )
                        }
                    </>
                }
                {!(isMobileState || isTabletState) && !isLanding && <Link to="/landing" style={{ textDecoration: "none" }}><PracticeButton onClick={() => props.setScreenStatesOnClick(6)}>{t("PRACTICE!")}</PracticeButton></Link>}
            </div>
        </MainDiv>
    )
}

export default TopNavBar

const MainDiv = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    gap: ${props => props.state ? "20px" : "0px"};
    width: ${props => props.state ? "95%" : "80%"};
    height: auto;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
`
const DonutWrapper = styled.div<{state: boolean, color: string}>`
    width: ${props => props.state ? "20%" : "121px"};
    color: ${props => props.color};
    height: auto;
    div {
        font-size: ${props => props.state ? "15px" : "17px"};
        font-weight: 700;
    }
`
const PracticeButton = styled(Button)`
    height: 30px;
`
const OverallDaysTo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: flex-end;
    text-align: center;
    color: ${props => props.theme.langooBlue};
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    width: auto;
`
const DaysTo = styled.span`
    font-size: 70px;
    span {
        font-size: 50px;
    }
`
const DashboardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`
const DashboardButtons = styled(ButtonTransition) <{ color: string, state: boolean }>`
    background: ${props => props.state ? "white" : props.color};
    border: 1px solid ${props => props.color};
    color: ${props => props.state ? props.color : "white"};
    width: auto;
    &:hover{
        background: white;
        color: ${props => props.color}
    }
`
const Separator = styled.div`
    font-size: 25px;
    height: 100%;
    width: auto;
    border-bottom: 3px solid transparent;
`