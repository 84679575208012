//* External Imports
import React, { useEffect, useMemo, useState }  from "react";
import styled from "styled-components";

//? Internal Imports
//? Utils
import { secsToTimerHours } from "../utils/secsToTimerHours";

/** Summatory Timer */
const LangooChronometer = (props: {stop: boolean}) => {
    const timerWorker: Worker = useMemo(() => new Worker(new URL("../webWorkers/timerCounter", import.meta.url)), [])
    const [seconds, setSeconds] = useState<number>(0)
    useEffect(() => {
        if(window.Worker){
            timerWorker.postMessage("startTimer")
            timerWorker.onmessage = (e: MessageEvent<string>) => {
                setSeconds((prev) => prev+Number(e.data))
            }
        }
    }, [timerWorker])

    useEffect(() => {
        if(props.stop){
            if(window.Worker){
                timerWorker.postMessage("stopTimer")
            }
        }
    }, [seconds])
    return (
        <div>
            <TextColor> {secsToTimerHours(seconds > 0 ? seconds : 0)}</TextColor>
        </div>
    )
}

export default LangooChronometer;

const TextColor = styled.strong`
    color: ${props => props.theme.langooBlue};
    margin-left: 10px;
    font-size: 20px;
`