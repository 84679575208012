/** 
 * Returns full month name from short format name 
 * @param {string} name - Short format month name
*/
export const shortMonthNameToFullName = (name: string): string => {
    switch(name) {
        case "Jan":
            return "January"
        case "Feb": 
            return "Frebruary"
        case "Mar":
            return "March"
        case "Apr":
            return "April"
        case "May":
            return "May"
        case "Jun":
            return "June"
        case "Jul":
            return "July"
        case "Aug":
            return "August"
        case "Sept":
            return "September"
        case "Sep":
            return "September"
        case "Oct":
            return "October"
        case "Nov":
            return "November"
        case "Dec":
            return "December"
        default:
            return "wtf?"
    }
}