//* External imports
import React from "react";
import {VictoryAxis, VictoryBar, VictoryLabel, VictoryStack} from "victory";
import styled from "styled-components"
import { useTranslation } from "react-i18next";

type Props = {
    data: Array<number>
    width?: number,
    height?: number
}
const MockDeleChart = (props: Props) => {
    const {t} = useTranslation("translation", {keyPrefix: "mockExamChart"})
    const width: number = props.width ? props.width : 500;
    const height: number = props.height ? props.height : 250;
    return(
        <svg viewBox={`0 0 ${width} ${height}`}>
            <g transform="translate(20, -50)">
                <g transform="translate(0, 50)">
                    <VictoryAxis
                        dependentAxis
                        standalone={false}
                        domain={[0.9,2]}
                        height={height}
                        padding={{top: 30, bottom: 50, left: 50}}
                        domainPadding={{y: 25}}
                        tickValues={[0.5, 1, 1.5, 2, 2.5]}
                        tickFormat={(elem) => Number.isInteger(elem) ? `${t("Block")} ${elem}` : " "}
                    />
                    </g>
                    <VictoryAxis
                        standalone={false}
                        width={width}
                        domain={[0, 25]}
                        tickValues={[0, 5, 10, 15, 20, 25]}
                        tickFormat={(elem) => `${elem}`}
                    />
                <g transform="translate(0, 20)">
                    <VictoryStack
                    colorScale={["#D9D9D9", "#1E4C7C"]}
                    standalone={false}
                    width={width}
                    horizontal
                    domainPadding={{x: 30}}
                    >
                        <VictoryBar
                            style={{
                                data: {
                                    fill: ({datum}) => datum.fill
                                },
                                labels: {
                                    fill: "white"
                                }
                            }}
                            labels={({datum}) => `${datum.y}`}
                            labelComponent={<VictoryLabel dx={-30}/>}
                            // X values -5.
                            // Y values +5.
                            data={[
                                {x: 1,   y: (props.data[3]), fill: "#A2D9E7"}, 
                                {x: 1.7, y: (props.data[2]), fill: "#FFC152"}, 
                                {x: 3.3, y: (props.data[1]), fill: "#F93E63"}, 
                                {x: 4 ,  y: (props.data[0]), fill: "#0B8EAB"}
                            ]}
                        />
                        <VictoryBar
                            style={{
                                data: {
                                    fill: ({datum}) => datum.fill
                                }
                            }}
                            data={[
                                {x: 1,   y: (25-props.data[3]), fill: "#D9D9D9"}, 
                                {x: 1.7, y: (25-props.data[2]), fill: "#D9D9D9"}, 
                                {x: 3.3, y: (25-props.data[1]), fill: "#D9D9D9"}, 
                                {x: 4,   y: (25-props.data[0]), fill: "#D9D9D9"}
                            ]}
                        />
                    </VictoryStack>
                </g>
                <foreignObject y="115" x="-10" width={50} height={160}>
                    <DecDiv>
                        {Math.round((100*(props.data[0]+props.data[1]))/50)}%
                    </DecDiv>
                </foreignObject>
                <foreignObject y="225" x="-10" width={50} height={160}>
                    <DecDiv>
                        {Math.round((100*(props.data[2]+props.data[3]))/50)}%
                    </DecDiv>
                </foreignObject>
            </g>
        </svg>
    )
}

export default MockDeleChart;

const DecDiv = styled.div`
    border: 1px solid green;
    border-radius: 5px;
    width: auto;
    font-size: 15px;
`