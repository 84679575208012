//* External Imports
import React from "react"
import styled from "styled-components";

export const SimpleTitle = styled.div`
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    width: 80%;
    color: ${props => props.theme.langooBlue}
`