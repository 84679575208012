//* External Imports
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal"
import styled from "styled-components";
import axios from "axios";
import i18n from "../../i18n";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser"
//* Hooks
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
//* Methods
import { format } from "date-fns";

// Types Imports
import { NotificationUser, Messages } from "../../types/GeneralTypes";
import { CompletedExercise } from "../../types/UserTypes";

//? Internal Imports
//? JSX Components
import TReportWS from "../reports/TReportWS";
//? Methods
import { logIn } from "../../redux/hook/useReducer";
import { isMobile, isTablet } from "../../responsive/mediaQueriesStates";
//? Styled-Componentes
import { Button } from "../../styles/buttons/ButtonRound";

//! Images
import dashboardBlack from "../../assets/icons/dashboardBlack.svg"
import dashboardWhite from "../../assets/icons/dashboardWhite.svg"
import classroomBlack from "../../assets/icons/classroomBlack.svg"
import classroomWhite from "../../assets/icons/classroomWhite.svg"
import proficiencyTestBlack from "../../assets/icons/proficiencyTestBlack.svg"
import proficiencyTestWhite from "../../assets/icons/proficiencyTestWhite.svg"
import mockExamBlack from "../../assets/icons/mockExamBlack.svg"
import mockExamWhite from "../../assets/icons/mockExamWhite.svg"
import studyMaterialBlack from "../../assets/icons/studyMaterialBlack.svg"
import studyMaterialWhite from "../../assets/icons/studyMaterialWhite.svg"
import bellIcon from "../../assets/icons/bellIcon.svg"
import chatIcon from "../../assets/icons/chatIcon.svg"
import profileIcon from "../../assets/icons/profileIcon.svg"
import cross from "../../assets/icons/cross.svg"
import arrow from "../../assets/icons/arrowShortWhite.svg"
import flag_es from "../../assets/icons/flag_es.svg"
import flag_gb from "../../assets/icons/flag_gb.svg"
import { globalErrorHandler } from "../../axios/globalErrorHandler";


type Props = {
    userId: number,
    userName: string
    screenStates: boolean[]
    setNavBarStates: React.Dispatch<React.SetStateAction<boolean[]>>
    navBarStates: boolean[]
    noProfiency: boolean
    onClickScreenButton: (index: number) => void
    setByby: React.Dispatch<React.SetStateAction<boolean>>
    setArrayStatesOnClick: (index: number, arr?: boolean[], setState?: React.Dispatch<React.SetStateAction<boolean[]>>, setAdicional?: React.Dispatch<React.SetStateAction<boolean>>) => void
    setAllFalse: (setArr1: React.Dispatch<React.SetStateAction<boolean[]>>, arr1Size: number, setArr2?: React.Dispatch<React.SetStateAction<boolean[]>>, arr2Size?: number) => void
    setScreenStates: React.Dispatch<React.SetStateAction<boolean[]>>
}

const notifiactionModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        minWidth: "150px",
        maxWidth: "90%",
        height: "auto",
        maxHeight: "80%"
    },
    overlay: { zIndex: 1000 }
}
const notifiactionModalResponsiveStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "99%",
        minWidth: "150px",
        height: "auto",
        maxHeight: "100%"
    },
    overlay: { zIndex: 1000 }
}
const MessagesModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "25%",
        height: "80%",
        padding: "20px",
        overflow: "hidden"
    },
    overlay: { zIndex: 1000 }
}
const MessagesModalResponsiveStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "90%",
        padding: "20px"
    },
    overlay: { zIndex: 1000 }
}

Modal.setAppElement("body")

const MainNavBar = (props: Props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const menuRef = useRef<any>()
    const { t } = useTranslation("translation", { keyPrefix: "mainNavBar" })
    const isMobileState = isMobile()
    const isTabletState = isTablet()
    const [showExercise, setShowExercise] = useState<boolean>(false)
    const [exercise, setExercise] = useState<CompletedExercise>()
    const [messageValue, setMessageValue] = useState<string>("")
    const [notifications, setNotification] = useState<NotificationUser[]>([])
    const [messages, setMessages] = useState<Messages[]>([])
    const [notificationModal, setNodificationModal] = useState<boolean>(false)
    const [messageModal, setMessageModal] = useState<boolean>(false)
    const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const handlerKeyPress = (ev: KeyboardEvent) => {
        if(ev.key === "Enter"){
            ev.preventDefault()
        }
    }

    const openModalNotifications = () => {
        setNodificationModal(true)
    }

    const closeModalNotifications = () => {
        setNodificationModal(false)
    }
    const openModalMessages = () => {
        setMessageModal(true)
    }

    const closeModalMessages = () => {
        setMessageModal(false)
    }

    const scrollToBottom = () => {
        const chatInput = document.getElementById("chatInput")
        if (chatInput) {
            changeMessageStatus()
            chatInput.scrollIntoView()
        }
    }

    const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if(ev.key === "Enter"){
            if(messageModal){
                postNewMessage()
            }
        }
    }

    const changeMessageStatus = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/sawMessages/${props.userId}`).catch(() => globalErrorHandler(dispatch, navigate))
        await axios.get<Messages[]>(`${process.env.REACT_APP_API_URL}/messages/${props.userId}`).then((response) => {
            setMessages(response.data)
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }

    const postNewMessage = async () => {
        if (messageValue.length > 0) {
            const message = {
                id_user: props.userId,
                text: messageValue,
                date: new Date(Date.now())
            }
            const resultMessage = await axios.post<Messages>(`${process.env.REACT_APP_API_URL}/sendMessage`, message)
            setMessageValue("")
            setMessages((old) => [...old, resultMessage.data])
            scrollToBottom()
        }
    }

    const seeExercise = async (elem: NotificationUser) => {
        setExercise({ ...elem.exercise, id_user: props.userId })
        setShowExercise(true)
        if (!elem.message.readed) {
            await axios.post(`${process.env.REACT_APP_API_URL}/sawNotification`, { idUser: props.userId, idExercise: elem.exercise.id_exercise }).catch(() => globalErrorHandler(dispatch, navigate))
            setNotification(notifications.map((elem2) => elem2 === elem ? { exercise: { ...elem.exercise }, message: { ...elem.message, readed: true } } : elem2))
        }
    }

    const onClickSignOut = () => {
        dispatch(logIn({
            mainStates: [],
            authToken: undefined,
            logInfo: {
                stayLogged: false,
                isLogged: false,
            }
        }))
        navigate("/")
        props.setByby(true)
    }

    useEffect(() => {
        axios.get<NotificationUser[]>(`${process.env.REACT_APP_API_URL}/notification/${props.userId}`).then((response) => {
            setNotification(response.data)
            axios.get<Messages[]>(`${process.env.REACT_APP_API_URL}/messages/${props.userId}`).then((response) => {
                setMessages(response.data)
                setLoading(false)
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])

    useEffect(() => {
        const handlerMenu = (event: MouseEvent | TouchEvent) => {
            if (showProfileMenu && menuRef.current && !menuRef.current.contains(event.target)) {
                setShowProfileMenu(false)
                props.setAllFalse(props.setNavBarStates, 5)
            }
        }
        document.addEventListener("mousedown", handlerMenu)
        document.addEventListener("touchstart", handlerMenu);
        //document.addEventListener("keypress", handlerKeyPress)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handlerMenu);
            document.removeEventListener("touchstart", handlerMenu);
            //document.removeEventListener("keypress", handlerKeyPress)
        };
    }, [showProfileMenu])

    return (
        <OptionDiv state={isMobileState || isTabletState}>
            <Modal
                isOpen={notificationModal}
                onRequestClose={closeModalNotifications}
                style={isMobileState ? notifiactionModalResponsiveStyle : notifiactionModalStyle}
            >
                <TopNav style={{ justifyContent: "flex-end"}}>
                    <ImgButtonCross onClick={() => [closeModalNotifications(), setShowExercise(false)]}>
                        <img src={cross} alt="closeModal" />
                    </ImgButtonCross>
                </TopNav>
                {showExercise && exercise ?
                    <ReportContainer>
                        <TReportWS exercise={exercise} id_user={props.userId} competency={exercise.text_written ? "Writing" : "Speaking"}
                            goBack={showExercise} setGoBack={setShowExercise} task={`${notifications.find((elem) => elem.exercise.id_exercise === exercise.id_exercise)!.message.name}`}
                            notificationRender={true}
                        />
                    </ReportContainer>
                    :
                    <div>
                        {notifications.length === 0 ? <div style={{width: "90%"}}>{t("No notifications")}</div> :
                            notifications.map((elem, i) => <NotificationCard onClick={() => seeExercise(elem)} key={i*100}>
                                <div>
                                    {format(new Date(elem.message.date), "dd/MM/yyyy HH:mm")}
                                    <IconBox>
                                        {!elem.message.readed &&
                                            <span style={{ top: "-20px" }}></span>
                                        }
                                    </IconBox>
                                </div>
                                <div><strong>{t("Correction available:")}</strong> {`${elem.message.name} - ${elem.message.exercise_type}`}</div>
                            </NotificationCard>)
                        }
                    </div>
                }
            </Modal>
            <Modal
                isOpen={messageModal}
                onRequestClose={closeModalMessages}
                style={isMobileState || isTabletState ? MessagesModalResponsiveStyle : MessagesModalStyle}
                onAfterOpen={scrollToBottom}
            >
                <TopNav>
                    <ImgButtonCross onClick={closeModalMessages}>
                        <img src={cross} alt="closeModal" />
                    </ImgButtonCross>
                    <a href="https://calendly.com/langoo-io/one-to-one-with-patricia-dele?month=2023-04" target="_blank"><CalendarButton>{t("Book a meeting")}</CalendarButton></a>
                </TopNav>
                <MessageContainer>
                    {messages.map((elem, index, arr) => {
                        const last = index !== arr.length - 1 ? arr[index + 1].author !== elem.author ? true : undefined : true
                        if (elem.author === "langoo") {
                            return <FromLangoo last={last} key={index}>
                                {parse(elem.text)}
                            </FromLangoo>
                        }
                        return <FromMe last={last} key={index}>
                            {elem.text}
                        </FromMe>
                    })}
                </MessageContainer>
                <InputContainer>
                    <ChatInput id="chatInput" autoComplete="off" value={messageValue} onChange={(e) => setMessageValue(e.target.value)} onKeyDown={onKeyDown}/>
                    <ImgButtonArrow onClick={postNewMessage}>
                        <ImgArrow src={arrow} />
                    </ImgButtonArrow>
                </InputContainer>
            </Modal>
            {!isMobileState && !isTabletState &&
                <>
                    {!props.noProfiency &&
                        <Link to="/dashboard" style={{ color: "inherit", textDecoration: "none", width: "100%" }} onClick={() => props.onClickScreenButton(0)}>
                            <SingleButtonWrapper state={props.screenStates[0]}>
                                <SelectImg src={props.screenStates[0] ? dashboardWhite : dashboardBlack} onClick={() => props.onClickScreenButton(0)} />
                                <SelectButton onClick={() => props.onClickScreenButton(0)}>
                                    {t("Dashboard")}
                                </SelectButton>
                            </SingleButtonWrapper>
                        </Link>
                    }
                    <Link to={!props.noProfiency ? "/proficiencyresults" : "/proficiencytest"} style={{ color: "inherit", textDecoration: "none", width: "100%" }} onClick={() => props.onClickScreenButton(1)}>
                        <SingleButtonWrapper state={props.screenStates[1]}>
                            <SelectImg src={props.screenStates[1] ? proficiencyTestWhite : proficiencyTestBlack} onClick={() => props.onClickScreenButton(1)} />
                            <SelectButton onClick={() => props.onClickScreenButton(1)} style={{ textAlign: "left" }}>
                                {!props.noProfiency ? t("Proficiency Test Results") : t("Proficiency Test")}
                            </SelectButton>
                        </SingleButtonWrapper>
                    </Link>
                    {!props.noProfiency &&
                        <>
                            <Link to="/classroom" style={{ color: "inherit", textDecoration: "none", width: "100%" }} onClick={() => props.onClickScreenButton(2)}>
                                <SingleButtonWrapper state={props.screenStates[2]}>
                                    <SelectImg src={props.screenStates[2] ? classroomWhite : classroomBlack} />
                                    <SelectButton>
                                        {t("Classroom")}
                                    </SelectButton>
                                </SingleButtonWrapper>
                            </Link>
                            <Link to="/mockexam" style={{ color: "inherit", textDecoration: "none", width: "100%" }} onClick={() => props.onClickScreenButton(3)}>
                                <SingleButtonWrapper state={props.screenStates[3]}>
                                    <SelectImg src={props.screenStates[3] ? mockExamWhite : mockExamBlack} />
                                    <SelectButton >
                                        {t("Mock Exam")}
                                    </SelectButton>
                                </SingleButtonWrapper>
                            </Link>
                            <Link to="/studymaterial" style={{ color: "inherit", textDecoration: "none", width: "100%" }} onClick={() => props.onClickScreenButton(4)}>
                                <SingleButtonWrapper state={props.screenStates[4]}>
                                    <SelectImg src={props.screenStates[4] ? studyMaterialWhite : studyMaterialBlack} />
                                    <SelectButton>
                                        {t("Study Material")}
                                    </SelectButton>
                                </SingleButtonWrapper>
                            </Link>
                            <DecorationSpan />
                        </>
                    }
                </>
            }
            <IconsContainer state={isMobileState || isTabletState}>
                <IconButtonContainer onClick={() => setShowProfileMenu(!showProfileMenu)} state={isMobileState || isTabletState} state2={showProfileMenu}>
                    <IconBox>
                        <img src={profileIcon} />
                    </IconBox>
                    {!isMobileState && !isTabletState && <IconButton>{props.userName}</IconButton>}
                </IconButtonContainer>
                <IconButtonContainer onClick={openModalMessages} state={isMobileState || isTabletState}>
                    <IconBox>
                        <img src={chatIcon} />
                        {messages.filter((elem) => !elem.readed && elem.author === "langoo").length > 0 &&
                            <span>{messages.filter((elem) => !elem.readed && elem.author === "langoo").length}</span>
                        }
                    </IconBox>
                    {!isMobileState && !isTabletState && <IconButton>{t("Messages")}</IconButton>}
                </IconButtonContainer>
                <IconButtonContainer onClick={openModalNotifications} state={isMobileState || isTabletState}>
                    <IconBox>
                        <img src={bellIcon} />
                        {notifications.filter((elem) => !elem.message.readed).length > 0 &&
                            <span>{notifications.filter((elem) => !elem.message.readed).length}</span>
                        }
                    </IconBox>
                    {!isMobileState && !isTabletState && <IconButton>{t("Notifications")}</IconButton>}
                </IconButtonContainer>
                <ProfileMenu state={showProfileMenu} responsiveState={isMobileState || isTabletState} ref={menuRef}>
                    <Link to="/profile" style={{ color: "inherit", textDecoration: "none", width: "100%" }} onClick={() => props.onClickScreenButton(-1)}>
                        <button onClick={() => [props.setArrayStatesOnClick(0, props.navBarStates, props.setNavBarStates, setShowProfileMenu), props.setAllFalse(props.setScreenStates, 7)]}>{t("Profile")}</button>
                    </Link>
                    <div />
                    <button onClick={() => props.setArrayStatesOnClick(1, props.navBarStates, props.setNavBarStates)}>{t("Language")}</button>
                    <div />
                    {!props.noProfiency &&
                        <>
                            <Link to="/paymentPlatform">
                                <button onClick={() => props.setAllFalse(props.setScreenStates, 7)}>{t("Settings")}</button>
                            </Link>
                            <div />
                        </>
                    }
                    <button onClick={onClickSignOut}>{t("Sign out")}</button>
                </ProfileMenu>
                {props.navBarStates[1] &&
                    <ProfileSubMenu state={props.navBarStates[1] && showProfileMenu} responsiveState={isMobileState || isTabletState} ref={menuRef}>
                        <button onClick={() => i18n.changeLanguage("en")}>
                            <img src={flag_gb} /> {t("English")}
                        </button>
                        <div />
                        <button onClick={() => i18n.changeLanguage("es")}>
                            <img src={flag_es} /> {t("Spanish")}
                        </button>
                    </ProfileSubMenu>
                }
            </IconsContainer>
        </OptionDiv>
    )
}

const OptionDiv = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "row" : "column"};
    justify-content: ${props => props.state ? "flex-end" : "normal"};
    align-items: center;
    margin: ${props => props.state ? "0px" : "10px"};
    width: ${props => props.state ? "auto" : "80%"};
    button {
        padding-bottom: 15px;
        padding-top: 15px;
    }
`
const ReportContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 10px 0px 10px 0px;
    width: 100%;
    min-width: 500px;
    min-height: 500px;
    background: ${props => props.theme.defaultBackgroundColor};
`
const SingleButtonWrapper = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    background: ${props => props.state ? props.theme.langooBlue : "transparent"};
    button {
        color: ${props => props.state ? "white" : "black"};
    }
`
const SelectButton = styled.button`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 700;
    height: 20%;
    width: 80%;
    background: transparent;
    border: transparent;
`
const SelectImg = styled.img`
    width: 35px;
    cursor: pointer;
`
const DecorationSpan = styled.span`
    width: 215px;
    height: 0px;
    border: 3px solid #D9D9D9;
    margin-top: 20px;
    margin-bottom: 20px;
`
const IconsContainer = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "row" : "column"};
    width: 100%;
    position: relative;
    button {
        padding: 5px;
    }
`
const IconBox = styled.div`
    display: flex;
    position: relative;
    height: auto;
    img {
        width: 28px;
        padding: 6px;
        border: 1px solid transparent;
        height: auto;
    }
    span {
        color: white;
        text-align: center;
        position: absolute;
        min-width: 16px;
        height: 16px;
        background: red;
        border-radius: 50%;
        font-size: 13px;
        font-weight: 700;
        right: -6px;
    }
`
const IconButtonContainer = styled.button<{ state: boolean, state2?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: ${props => props.state ? "auto" : "100%"};
    background: transparent;
    color: #8D8D8D;
    border: transparent;
    box-shadow: ${props => props.state2 && props.state2 === true ? props.theme.basicBoxShadow : "none"};
    //div {
    //    margin-right: 10px;
    //}
`
const IconButton = styled.button`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 700;
    width: 80%;
    background: transparent;
    border: transparent;
    color: #8D8D8D;
`
const TopNav = styled.div`
    display: flex;
    position: sticky;
    background: white;
    top: 0;
    width: 100%; 
    z-index: 1;
    margin-bottom: 10px;
    gap: 20px;
    a {
        text-decoration: none;
    }
`
const ImgButtonCross = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: white;
    border-radius: 50%;
    border: 1px solid transparent;
`
const CalendarButton = styled(Button)`
    display: flex;
    align-items: center;
    background: ${props => props.theme.langooRed};
    padding: 20px;
    width: auto;
    height: 10px;
    cursor: pointer;
`
const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
`
const MessageBase = styled.div`
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
    margin: 0.5rem 0;
    width: fit-content;
    ::before, ::after {
        bottom: -0.1rem;
        content: "";
        height: 1rem;
        position: absolute;
    }
`
const FromMe = styled(MessageBase) <{ last?: boolean }>`
    align-self: flex-end;
    background-color: ${props => props.theme.langooBlue};
    color: #fff;
    margin: 0.25rem 0 0;
    margin-bottom: ${props => props.last === true ? "0.5rem" : "0"};
    ::before {
        display: ${props => props.last === true ? "block" : "none"};
        border-bottom-left-radius: 0.8rem 0.7rem;
        border-right: 1rem solid ${props => props.theme.langooBlue};
        right: -0.35rem;
        transform: translate(0, -0.1rem);
    };
    ::after {
        background-color: #fff;
        border-bottom-left-radius: 0.5rem;
        right: -40px;
        transform:translate(-30px, -2px);
        width: 10px;
    };
`
const FromLangoo = styled(MessageBase) <{ last?: boolean }>`
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
    margin: 0.25rem 0 0;
    margin-bottom: ${props => props.last === true ? "0.5rem" : "0"};
    ::before {
        display: ${props => props.last === true ? "block" : "none"};
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid #e5e5ea;
        left: -0.35rem;
        transform: translate(0, -0.1rem);
    };
    ::after {
        background-color: #fff;
        border-bottom-right-radius: 0.5rem;
        left: 20px;
        transform: translate(-30px, -2px);
        width: 10px;
    };
`
const InputContainer = styled.div`
    align-items: center;
    display: flex;
    position: sticky;
    bottom: 0;
    width: 100%; 
    z-index: 1;
    gap: 20px;
`
const ChatInput = styled.input`
    width: 85%;
    height: auto;
    resize: none;
    border-radius: 10px;
    font-family: ${props => props.theme.fontFamily};
    padding: 5px;
    font-size: 16px;
`
const ImgButtonArrow = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${props => props.theme.langooBlue};
    border-radius: 50%;
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
`
const ImgArrow = styled.img`
    transform: rotate(-90deg);
`
const NotificationCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: ${props => props.theme.defaultBackgroundColor};
    border-radius: 10px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
`
const ProfileMenu = styled.div<{ state: boolean, responsiveState: boolean }>`
    display: ${props => props.state ? "block" : "none"};
    position: absolute;
    border: 0.5px solid #B6B6B6;
    border-radius: 8px;
    background: white;
    min-width: 150px;
    top: ${props => props.responsiveState ? "60px" : "1%"};
    left: ${props => props.responsiveState ? "-100px" : "200px"};
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 5px;
    button {
        background: transparent;
        border: transparent;
        font-size: 17px;
        padding: 5px;
        font-weight: 1000;
    }
    div {
        align-self: center;
        border: transparent;
        border-bottom: 3px solid #F5F5F5;
        min-width: 95%;
        max-width: 120px;
        min-height: 5px;
        margin-left: 2.5px;
    }
`
const ProfileSubMenu = styled.div<{ state: boolean, responsiveState: boolean }>`
    display: ${props => props.state ? "block" : "none"};
    position: absolute;
    border: 0.5px solid #B6B6B6;
    border-radius: 8px;
    background: white;
    min-width: 150px;
    top: ${props => props.responsiveState ? "110px" : "0%"};
    left: ${props => props.responsiveState ? "normal" : "365px"};
    right: ${props => props.responsiveState ? "170px" : "normal"};
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 5px;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: transparent;
        font-size: 17px;
        padding: 5px;
        font-weight: 1000;
        gap: 10px;
    }
    div {
        align-self: center;
        border: transparent;
        border-bottom: 3px solid #F5F5F5;
        min-width: 95%;
        max-width: 120px;
        min-height: 5px;
        margin-left: 2.5px;
    }
`

export default MainNavBar