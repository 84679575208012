//* External Imports
import React from "react";
import styled from "styled-components";
import parse from "html-react-parser"

type Props = {
    text: string
    width?: string
    height?: string
}

/** Generic Feedback JSX Component */
const Feedback = (props: Props) => (
    <FeedbackContainer width={props.width ? props.width : "100%"}>
        <FeedBackTitle>Feedback</FeedBackTitle>
        <InteriorDiv width={"100%"}>
            {parse(props.text)}
        </InteriorDiv>
    </FeedbackContainer>
)

export default Feedback

const FeedbackContainer = styled.div<{ width: string | number }>`
    width: ${props => props.width};;
    height: 10%;
    margin-top: 20px;
    text-align: left;
`
const FeedBackTitle = styled.span`
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
`
const InteriorDiv = styled.div<{ width: string | number }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    background-color: white;
    width: ${props => props.width};
    height: fit-content;
    line-height: 1.70;
    overflow-y: auto;
    border-radius: 30px;
    margin-top: 10px;
    padding: 20px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    box-sizing: border-box;
    p {
        text-align: left;
        margin: 5px;
    }
`