//* External Imports
import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

// Type Imports

//? Internal Imports
//? JSX Components
//? Hooks
//? Methods
//? Utils
//? Styled-Components

//! Images
import dashboardBlack from "../../../assets/icons/dashboardBlack.svg"
import dashboardWhite from "../../../assets/icons/dashboardWhite.svg"
import classroomBlack from "../../../assets/icons/classroomBlack.svg"
import classroomWhite from "../../../assets/icons/classroomWhite.svg"
import proficiencyTestBlack from "../../../assets/icons/proficiencyTestBlack.svg"
import proficiencyTestWhite from "../../../assets/icons/proficiencyTestWhite.svg"
import mockExamBlack from "../../../assets/icons/mockExamBlack.svg"
import mockExamWhite from "../../../assets/icons/mockExamWhite.svg"
import studyMaterialBlack from "../../../assets/icons/studyMaterialBlack.svg"
import studyMaterialWhite from "../../../assets/icons/studyMaterialWhite.svg"

type Props = {
    userId: number,
    userName: string
    screenStates: boolean[]
    noProfiency: boolean
    onClickScreenButton: (index: number) => void
    setByby: React.Dispatch<React.SetStateAction<boolean>>
}

const ResponsiveNavBar = (props: Props) => (<MainDiv>
    {!props.noProfiency &&
        <Link to="/dashboard" style={{ color: "inherit", textDecoration: "none", width: "100%", height: "100%" }} onClick={() => props.onClickScreenButton(0)}>
            <SingleButtonWrapper state={props.screenStates[0]}>
                <SelectButton>
                    <SelectImg src={props.screenStates[0] ? dashboardWhite : dashboardBlack} />
                </SelectButton>
            </SingleButtonWrapper>
        </Link>
    }
    <Link to={!props.noProfiency ? "/proficiencyresults" : "/proficiencytest"} style={{ color: "inherit", textDecoration: "none", width: "100%", height: "100%" }} onClick={() => props.onClickScreenButton(1)}>
        <SingleButtonWrapper state={props.screenStates[1]}>
            <SelectButton>
                <SelectImg src={props.screenStates[1] ? proficiencyTestWhite : proficiencyTestBlack} />
            </SelectButton>
        </SingleButtonWrapper>
    </Link>
    {!props.noProfiency &&
        <>
            <Link to="/classroom" style={{ color: "inherit", textDecoration: "none", width: "100%", height: "100%" }} onClick={() => props.onClickScreenButton(2)}>
                <SingleButtonWrapper state={props.screenStates[2]}>
                    <SelectButton>
                        <SelectImg src={props.screenStates[2] ? classroomWhite : classroomBlack} />
                    </SelectButton>
                </SingleButtonWrapper>
            </Link>
            <Link to="/mockexam" style={{ color: "inherit", textDecoration: "none", width: "100%", height: "100%" }} onClick={() => props.onClickScreenButton(3)}>
                <SingleButtonWrapper state={props.screenStates[3]}>
                    <SelectButton >
                        <SelectImg src={props.screenStates[3] ? mockExamWhite : mockExamBlack} />
                    </SelectButton>
                </SingleButtonWrapper>
            </Link>
            <Link to="/studymaterial" style={{ color: "inherit", textDecoration: "none", width: "100%", height: "100%" }} onClick={() => props.onClickScreenButton(4)}>
                <SingleButtonWrapper state={props.screenStates[4]}>
                    <SelectButton>
                        <SelectImg src={props.screenStates[4] ? studyMaterialWhite : studyMaterialBlack} />
                    </SelectButton>
                </SingleButtonWrapper>
            </Link>
        </>
    }
</MainDiv>)

export default ResponsiveNavBar

const MainDiv = styled.div`
    display: flex;
    position: relative;
    z-index: 10;
    background: white;
    flex-direction: row;
    //padding: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    z-index: 5;
`

const SingleButtonWrapper = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${props => props.state ? props.theme.langooBlue : "transparent"};
    button {
        color: ${props => props.state ? "white" : "black"};
    }
`
const SelectButton = styled.button`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    height: 20%;
    width: 100%;
    background: transparent;
    border: transparent;
`
const SelectImg = styled.img`
    width: 35px;
    cursor: pointer;
`