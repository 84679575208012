//* External imports
import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
// *Hooks
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
//* Methods
import { format, millisecondsToMinutes } from "date-fns";

// Types Imports
import { CompletedExam, UserType } from "../../types/UserTypes";
import { ExamType } from "../../types/GeneralTypes";

//? Internal Imports
//? JSX Components
import SimpleBarChart from "../charts/barCharts/SimpleBarChart";
import DonutChartSimple from "../charts/pieCharts/DonutChartSimple";
import Loader from "../loader";
import Feedback from "../feedback";
//? Hooks
import { setMainStates } from "../../redux/hook/useReducer";
//? Utils
import { changeBooleanArrayValueByIndex } from "../../utils/changeBooleanArrayValueByIndex";
//? Styled-Components
import { SimpleTitle } from "../../styles/SimpleTitle";
import { WhiteCard } from "../../styles/WhiteCard";
import { sortedCompetencyColors } from "../../utils/sortedCompetencyColors";
import { objectToArrayByCertificactionAndLevel } from "../../utils/objectToArrayByCertificationAndLevel";
import { isMobile } from "../../responsive/mediaQueriesStates";
import { Desktop, Mobile, Tablet } from "../../responsive/mediaQueriesComponents";
import { globalErrorHandler } from "../../axios/globalErrorHandler";
import { useNavigate } from "react-router-dom";

//? Subscreen for proficiencyTest.tsx. Shows competence test stadistics.

type Props = {
    userData: UserType;
    exam: ExamType
    completedExam?: CompletedExam;
    time?: number;
    text?: string;
    audio?: string;
}

/** Proficiency Test Report */
const ProficiencyTestResult = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileState = isMobile()
    const { t } = useTranslation("translation", { keyPrefix: "proficiencyTestResult" })
    const [exam, setExam] = useState<{ competency: string[], data: CompletedExam }>()
    const [teacher, setTeacher] = useState<{ name: string }>({ name: "" })
    const [loading, setLoading] = useState<boolean>(true)
    const colorArr = sortedCompetencyColors(props.userData.certification, props.userData.certification_level)
    useEffect(() => {
        dispatch(setMainStates(changeBooleanArrayValueByIndex(new Array(7).fill(false), 1)))
        axios.get<{ competency: string[], data: CompletedExam }>(`${process.env.REACT_APP_API_URL}/exams/completed/${props.exam.id_exam}/${props.exam.exam_type}/${props.userData.id}`).then((response) => {
            setExam(response.data)
            axios.get<{ name: string }>(`${process.env.REACT_APP_API_URL}/teacher/${props.userData.id}`).then((response) => {
                setTeacher(response.data)
                setLoading(false)
            }).catch(() => globalErrorHandler(dispatch, navigate))
        })
    }, [])
    if (loading) {
        return <Loader loading={loading} />
    }
    return (
        <MainDiv state={isMobileState}>
            <UpperDiv>
                <TextDiv>
                    <SimpleTitle>{t("Proficiency Test Results")}</SimpleTitle>
                    <div style={{ fontWeight: "700", width: "100%" }}>
                        {exam && exam.data && exam.data.corrected ?
                            <div>
                                {t("Here are your competency test results! Thanks to them, we have created a personalized learning plan just for you, which will guide you on the platform so that you can learn everything you need and achieve your goals before the exam date")} <br/><br/>
                                {t("Remember that you can start practicing from your Learning Path, where you'll find all the tasks to begin your practice. You can access it by clicking the 'Practice' button or by returning to the main menu if you click on our Langoo logo. It's time to start!")}
                            </div>
                            :
                            `${t("Your teacher")} ${teacher.name} ${t("is reviewing the tasks. Your results and learning path will be uploaded soon.")}`
                        }

                    </div>
                </TextDiv>
            </UpperDiv>
            <MiddleSpan>
                <InfoCard style={{ borderLeft: "transparent" }}>
                    <UpperInfoCard>
                        {t("Test date")}
                    </UpperInfoCard>
                    <DownInfoCard>
                        {exam && exam.data && exam.data.end_date && format(new Date(exam.data.end_date), "dd/MM/yyyy")}
                    </DownInfoCard>
                </InfoCard>
                <InfoCard>
                    <UpperInfoCard>
                        {t("Your time")}
                    </UpperInfoCard>
                    <DownInfoCard>
                        {exam && exam.data && exam.data.completed ?
                            millisecondsToMinutes((new Date(exam.data.end_date).getTime() - new Date(exam.data.start_date).getTime()))
                            : (props.time ? props.time : 0)
                        } {t("minutes")}
                    </DownInfoCard>
                </InfoCard>
                <InfoCard>
                    <UpperInfoCard>
                        {t("Current level")}
                    </UpperInfoCard>
                    <DownInfoCard>
                        {exam && exam.data && exam.data.corrected ? props.userData.certification_level : "?"}
                    </DownInfoCard>
                </InfoCard>
            </MiddleSpan>
            {exam && exam.data && exam.data.corrected &&
                <ChartsContainer state={isMobileState}>
                    <ChartCard width={isMobileState ? "100%" : "35%"}>
                        <div>{t("Total Score")}</div>
                        <DonutChartSimple
                            colorScale={["#D9D9D9", "#0B8EAB"]}
                            data={[
                                { x: 2, y: 100 - (exam.data.score_reading + exam.data.score_speaking + exam.data.score_writing + exam.data.score_listening) / exam.competency.length },
                                { x: 1, y: (exam.data.score_reading + exam.data.score_speaking + exam.data.score_writing + exam.data.score_listening) / exam.competency.length },
                            ]}
                            text={`${(exam.data.score_reading + exam.data.score_speaking + exam.data.score_writing + exam.data.score_listening) / exam.competency.length}%`}
                            textCompetency={props.userData.certification === "DELE" ? exam.data.score : exam.data.score === "APTO" ? "PASS" : "NO PASS"}
                        />
                        <div>{`${t("Minimun score to pass")} 40%`}</div>
                    </ChartCard>
                    <Mobile>
                        <NoMarginMiddleSpan>
                            <LeyendaChart>
                                {exam.competency.map((elem, index) => <span><Leyenda color={colorArr[index]} /> {elem}</span>)}
                            </LeyendaChart>
                        </NoMarginMiddleSpan>
                    </Mobile>
                    <ChartCard width={isMobileState ? "100%" : "60%"}>
                        <h4 style={{ marginBottom: '10px', marginLeft: '20px', textAlign: 'left' }}>{t("Proficiency Test Report")}</h4>
                        <Desktop>
                            <LeyendaChart>
                                {exam.competency.map((elem, index) => <span><Leyenda color={colorArr[index]} /> {elem}</span>)}
                            </LeyendaChart>
                        </Desktop>
                        <Tablet>
                            <LeyendaChart>
                                {exam.competency.map((elem, index) => <span><Leyenda color={colorArr[index]} /> {elem}</span>)}
                            </LeyendaChart>
                        </Tablet>
                        <SimpleBarChart
                            data={
                                objectToArrayByCertificactionAndLevel(
                                    {
                                        reading: exam.data.score_reading,
                                        speaking: exam.data.score_speaking,
                                        writing: exam.data.score_writing,
                                        listening: exam.data.score_listening
                                    },
                                    props.userData.certification,
                                    props.userData.certification_level
                                ) as number[]
                            }
                            categories={exam.competency}
                            colors={colorArr}
                        />
                    </ChartCard>
                </ChartsContainer>
            }
            {(props.text || props.audio) &&
                <ResponseSpan>
                    <TextResultDiv >
                        {t("Writing test response:")} <br />
                        {props.text}
                    </TextResultDiv>
                    <TextResultDiv>
                        {t("Speaking test response:")} <br /><br />
                        {props.audio &&
                            <audio controls controlsList="noplaybackrate nodownload" src={props.audio} />
                        }
                    </TextResultDiv>
                </ResponseSpan>
            }
            {exam && exam.data && exam.data.corrected &&
                <Feedback text={exam.data.notes} />
            }

        </MainDiv>
    )
}

export default ProficiencyTestResult;

const MainDiv = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "95%" : "80%"};
    background: #F5F5F5;
    box-sizing: border-box;
    padding-bottom: 5%;
`
const UpperDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 25%;
    margin-bottom: 40px;
    box-sizing: border-box;
    `
const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: left;
    gap: 10px;
`
const MiddleSpan = styled(WhiteCard)`
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 10%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 50px;
    box-sizing: border-box;
`
const NoMarginMiddleSpan = styled(WhiteCard)`
`
const InfoCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 33.3%;
    height: 100%;
    border-left: 0.5px solid #D3CFCF;
`
const UpperInfoCard = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.theme.langooBlue};
    margin-bottom: 4px;
    margin-top: 10px;
`
const DownInfoCard = styled.div`
    font-weight: 600;
    margin-bottom: 10px;
`
const ChartsContainer = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    align-content: center;
    justify-content: space-between;
    gap: ${props => props.state ? "20px" : "0px"};
    width: 100%;
    height: auto;
    margin-bottom: 5%;
`
const ChartCard = styled(WhiteCard) <{ width: string | number }>`
    flex-direction: column;
    justify-content: flex-start;
    width: ${props => props.width};
    height: auto;
    box-sizing: border-box;
    padding: 10px;
`
const ResponseSpan = styled.span`
    display: flex;
    flex-direction: row;
    align-items: space-around;
    justify-content: space-around;
    width: 100%;
    height: auto;
`
const TextResultDiv = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    width: 40%;
    height: fit-content;
    margin-bottom: 30px;
    padding:10px;
`
const Leyenda = styled.div<{ color: string }>`
    background-color: ${props => props.color};
    border:none;
    margin: 5px;
    width: 10px;
    height:10px;
`
const LeyendaChart = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    padding: 10px;
    span {
        display: flex;
    }
`