//* External Import
import React, { useEffect, useState } from "react"
import styled from "styled-components";
import axios from "axios";
//* Hooks
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

//Type Imports
import { FlashCard } from "../types/GeneralTypes";

//? Internal Imports
//? JSX Components
import Loader from "../components/loader";
import { Desktop, Mobile } from "../responsive/mediaQueriesComponents";
//? Hooks
//? Methods
import { isMobile, isTablet } from "../responsive/mediaQueriesStates";
import { setMainStates } from "../redux/hook/useReducer";
//? Utils
import { changeBooleanArrayValueByIndex } from "../utils/changeBooleanArrayValueByIndex";
//? Styled-Components
import { SimpleTitle } from "../styles/SimpleTitle";

//! Images

type Props = {
    idUser: number
}

type InputsType = {
    titulo?: string | undefined,
    clase?: string | undefined,
    dificultad?: string | undefined,
    formato?: string | undefined
    nivel?: string | undefined
} | undefined

const defaultFashCard: FlashCard = {
    title: "",
    type: "",
    description: "",
    file: "",
    image: "",
    link: "",
    difficulty: "",
    format: "",
    level: ""
}

/** Flashcard search component
 *  @param {number} idUser - User id
 */
const StudyMaterial = (props: Props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("translation", {keyPrefix: "studyMaterial"})
    const [loading, setLoading] = useState<boolean>(false)
    const [cards, setCards] = useState<FlashCard[]>([defaultFashCard])
    const [inputsStates, setInputsStates] = useState<InputsType>(undefined)
    const [selectOptions, setSelectOptions] = useState<string[][]>([[]])
    const isMobileState = isMobile()
    const isTabletState = isTablet()
    useEffect(() => {
        setLoading(true)
        dispatch(setMainStates(changeBooleanArrayValueByIndex(new Array(7).fill(false), 4)))
        axios.post<FlashCard[]>(`${process.env.REACT_APP_API_URL}/flashcards/${props.idUser}`).then((response) => {
            setCards(response.data)
            setSelectOptions([
                Array.from(new Set(response.data.map((elem) => elem.difficulty))),
                Array.from(new Set(response.data.map((elem) => elem.format))),
                Array.from(new Set(response.data.map((elem) => elem.type))),
                Array.from(new Set(response.data.map((elem) => elem.level)))
            ])
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            axios.post<FlashCard[]>(`${process.env.REACT_APP_API_URL}/flashcards/${props.idUser}`, inputsStates).then((response) => {
                setCards(response.data)
            })
        }, 1000)
    }, [inputsStates])

    return (
        <MainDiv state={isMobileState}>
            {loading && <Loader loading={loading}/>}
            <UpperTextDiv>
                <SimpleTitle>{t("Flashcards")}</SimpleTitle>
                <div style={{ fontSize: "20px", fontWeight: 'bold'}}>{t("Your study materials")}</div>
            </UpperTextDiv>
                <SelectSpan state={isMobileState}>
                    <InputStyle placeholder={t("Title") ?? "Title"} onChange={(e) => setTimeout(() => setInputsStates({ ...inputsStates, titulo: e.target.value }), 1000)} />
                    <SelectStyle onChange={(e) => setInputsStates({ ...inputsStates, dificultad: e.target.value })}>
                        <option value="default">{t("Difficulty")}</option>
                        {selectOptions[0] && selectOptions[0].map((elem) => <option value={elem}>{elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase().replace("_", " ")}</option>)}

                    </SelectStyle>
                    <SelectStyle onChange={(e) => setInputsStates({ ...inputsStates, formato: e.target.value })}>
                        <option value="default">{t("Format")}</option>
                        {selectOptions[1] && selectOptions[1].map((elem) => <option value={elem}>{elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase().replace("_", " ")}</option>)}
                    </SelectStyle>
                    <SelectStyle onChange={(e) => setInputsStates({ ...inputsStates, clase: e.target.value })}>
                        <option value="default">{t("Type")}</option>
                        {selectOptions[2] && selectOptions[2].map((elem) => <option value={elem}>{elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase().replace("_", " ")}</option>)}
                    </SelectStyle>
                    <SelectStyle onChange={(e) => setInputsStates({ ...inputsStates, nivel: e.target.value })}>
                        <option value="default">{t("Level")}</option>
                        {selectOptions[3] && selectOptions[3].map((elem) => <option value={elem}>{elem}</option>)}
                    </SelectStyle>
                </SelectSpan>
            <CardDisplay state={isMobileState} state2={isTabletState}>
                {cards.map((elem) => <a href={elem.link} target="_blank"><Card src={`${process.env.REACT_APP_FLASHPREFIX}/${elem.image}`} alt={elem.title} /></a>)}
            </CardDisplay>
        </MainDiv>
    )
}

export default StudyMaterial;

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "95%" : "80%"};
    background: #F5F5F5;
`
const UpperTextDiv = styled.div`
    width: 100%;
    padding-bottom: 0;
    box-sizing: border-box;
    text-align: left;
    h4 {
        color: ${props => props.theme.langooBlue}
    }
`
const SelectStyle = styled.select`
    background: white;
    font-weight: normal;
    border: none;
    border-bottom: 3px solid ${props => props.theme.langooBlue};
    border-radius: 5px;
    option {
        cursor: pointer;
        font-size: 17px;
    }
`
const SelectSpan = styled.span<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    flex-wrap: wrap;
    //justify-content: ${props => props.state ? "flex-start" : ""};
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    margin: 5% 0;
    gap: ${props => props.state ? "20px" : "10px"};
    ${SelectStyle} {
        height: 35px;
        width: 300px;
        padding-left: 14px;
        font-size: 17px;
    }
`
const InputStyle = styled.input`
    background: white;
    font-weight: normal;
    border: none;
    border-bottom: 3px solid ${props => props.theme.langooBlue};
    font-size: 17px;
    border-radius: 5px;
    padding-left: 15px;
    height: 35px;
    width: 300px;
`
const Card = styled.img`
    border-radius: 10px;
    text-shadow: ${props => props.theme.basicBoxShadow};
    width: 100%;
`
const CardDisplay = styled.span<{state: boolean, state2: boolean}>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${props => props.state ? "center" : "none"};
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    gap: 20px;
    ${Card} {
        max-width: ${props => props.state2 ? "300px" : props.state ? "100%" : "300px"};
    }
`

const InputRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
`