/** 
 * Returns an array with competencies names order depending of the certification & level 
 * @param {string} certification - User certification name
 * @param {string} level - User certification level name
*/
export const sortedCompetencies = (certification: string, level: string): string[] => {
    const reading = "Reading"
    const writing = "Writing"
    const speaking = "Speaking"
    const listening = "Listening"
    switch(certification) {
        case "DELE":
            switch (level){
                case "A1": 
                    return [
                        reading,
                        speaking,
                        writing,
                        listening
                    ]
                    
                default:
                    return [
                        reading,
                        listening,
                        writing,
                        speaking
                    ]
                    
            }
        case "CAMBRIDGE":
                switch(level){
                    case "A2":
                        return [
                            reading,
                            speaking,
                            listening
                        ]
                        
                    case "B2":
                        return [
                            reading,
                            listening,
                            writing,
                            speaking
                        ]
                        
                    case "C1":
                        return [
                            reading,
                            writing,
                            listening,
                            speaking
                        ]
                        
                    default:
                        return [
                            reading,
                            listening,
                            writing,
                            speaking
                        ]
                        
                }
                
        default:
            console.log("Error")
            return [
                reading,
                listening,
                writing,
                speaking
            ]
    }
}