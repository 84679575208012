//* External Imports
//* Hooks
import { useState, useEffect, useRef, useMemo } from "react";

// Type Imports
import { Recorder, AudioTrack, MediaRecorderEvent } from "./types/recorder";

//? Internal Imports
//? Methods
import { startRecording, saveRecording } from "./handlers/recorder-controls";

const initialState: Recorder = {
	recordingMinutes: 0,
	recordingSeconds: 0,
	initRecording: false,
	mediaStream: null,
	mediaRecorder: null,
	rawBlob: null,
	audio: null,
};

const useRecorder = () => {
	const [recorderState, setRecorderState] = useState<Recorder>(initialState);
	const timerWorker: Worker = useMemo(() => new Worker(new URL("../../webWorkers/timerCounter", import.meta.url)), [])

	useEffect(() => {
		if(window.Worker){
			const MAX_RECORDER_TIME = 25;
			if(recorderState.initRecording){
				timerWorker.postMessage("startTimer")
				timerWorker.onmessage = (e: MessageEvent<string>) => {
					setRecorderState((prevState: Recorder) => {
						if(prevState.recordingMinutes === MAX_RECORDER_TIME && prevState.recordingSeconds === 0){
							timerWorker.postMessage("stopTimer")
							return prevState
						} 
						if(prevState.recordingSeconds >=0 && prevState.recordingSeconds < 59){
							return {
								...prevState,
								recordingSeconds: prevState.recordingSeconds + Number(e.data)
							}
						}else if(prevState.recordingSeconds === 59){
							return {
								...prevState,
								recordingMinutes: prevState.recordingMinutes + Number(e.data),
								recordingSeconds: 0
							}
						}else{
							return prevState;
						}
					})
					
				}
			}else{
				timerWorker.postMessage("stopTimer")
			}
		}
	}, [recorderState.initRecording])

	useEffect(() => {
		setRecorderState((prevState) => {
			if (prevState.mediaStream) {
				//Change sample rate to 16Mhz --> new MediaRecorder(prevState.mediaStream, {audioBitsPerSecond: 16000}) 
				return {
					...prevState,
					mediaRecorder: new MediaRecorder(prevState.mediaStream, {mimeType: MediaRecorder.isTypeSupported("audio/mp4") ? "audio/mp4" : "audio/webm;codecs=opus",}),
				};
			} else {
				return prevState
			}
		});
	}, [recorderState.mediaStream]);

	useEffect(() => {
		const recorder = recorderState.mediaRecorder;
		let chunks: Blob[] = [];
		if (recorder && recorder.state === "inactive") {
			recorder.start();
			recorder.ondataavailable = (e: MediaRecorderEvent) => {
				chunks.push(e.data);
			};

			recorder.onstop = () => {
				const blob = new Blob(chunks, { type: MediaRecorder.isTypeSupported("audio/mp4") ? "audio/mp4" : "audio/webm;codecs=opus" });
				chunks = [];
				setRecorderState((prevState: Recorder) => {
					if (prevState.mediaRecorder) {
						return {
							...initialState,
							audio: window.URL.createObjectURL(blob),
							rawBlob: blob
						};
					} else {
						return initialState
					}
				});
			};
		}

		return () => {
			if (recorder) {
				recorder.stream.getAudioTracks().forEach((track: AudioTrack) => track.stop())
			}
		};
	}, [recorderState.mediaRecorder]);

	return {
		recorderState,
		startRecording: () => startRecording(setRecorderState),
		cancelRecording: () => setRecorderState(initialState),
		saveRecording: () => saveRecording(recorderState.mediaRecorder),
	};
}

export default useRecorder;