//* External Imports
import React from "react"
import styled from "styled-components";

export const WhiteCard = styled.div`
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    box-shadow: ${props => props.theme.basicBoxShadow};
    border-radius: 20px;
`
