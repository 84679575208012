/** 
 * Returns an array with competencies colors order depending of the certification & level 
 * @param {string} certification - User certification name
 * @param {string} level - User certification level name
 * */
export const sortedCompetencyColors = (certification: string, level: string): string[] => {
    const langooBlue = "#0B8EAB"
    const langooRed = "#F93E63"
    const langooYellow = "#FFC152"
    const langooLightBlue = "#A2D9E7"
    switch(certification) {
        case "DELE":
            switch (level){
                case "A1": 
                    return [
                        langooBlue,
                        langooYellow,
                        langooRed,
                        langooLightBlue
                    ]
                    
                default:
                    return [
                        langooBlue,
                        langooLightBlue,
                        langooRed,
                        langooYellow
                    ]
                    
            }
        case "CAMBRIDGE":
                switch(level){
                    case "A2":
                        return [
                            langooBlue,
                            langooYellow,
                            langooLightBlue
                        ]
                        
                    case "B2":
                        return [
                            langooBlue,
                            langooLightBlue,
                            langooRed,
                            langooYellow
                        ]
                        
                    case "C1":
                        return [
                            langooBlue,
                            langooRed,
                            langooLightBlue,
                            langooYellow
                        ]
                        
                    default:
                        return [
                            langooBlue,
                            langooLightBlue,
                            langooRed,
                            langooYellow
                        ]
                        
                }
                
        default:
            console.log("Error")
            return [
                langooBlue,
                langooLightBlue,
                langooRed,
                langooYellow
            ]
    }
}