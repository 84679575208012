//* External imports
import React from "react";

type Props = {
    data: Array<number>
    pointsArray: Array<number>
    xText: Array<string>
    levels: Array<string>
}

const MockCambridge = (props: Props) => (
        <svg viewBox="0 0 600 300">
            <g transform="translate(50, 30)">
                <line x1={0} y1={210} x2={510} y2={210} stroke="black"/>
                <g className="shadowElem">
                    <rect x="30" y="0" rx="5" ry="5" width="30" height="200" fill="white"/>
                </g>
                {props.pointsArray.map((elem, index) => <text x="33" y={20+(29*index)} fontSize={13} key={index}>
                        {elem}
                    </text>        
                )}
                <text x="-10" y="30"   fill="#4DA679" fontSize={16}>{props.levels[0]}</text>
                <text x="-10" y="82.5" fill="#479FF8" fontSize={16}>{props.levels[1]}</text>
                <text x="-10" y="133.5" fill="#DE546F" fontSize={16}>{props.levels[2]}</text>
                {props.xText.map((elem,index)=> {
                    const indexValue = 90+(80*index)
                    return <>
                            <text x={indexValue} y="230">{elem}</text>
                            <rect x={indexValue} y="140" width="60" height="60" fill="#DCDCDC"/>
                            <rect x={indexValue} y="89" width="60" height="51" fill="#7A7A7A"/>
                            <rect x={indexValue} y="56" width="60" height="33" fill="#CEF1B8"/>
                            <rect x={indexValue} y="37" width="60" height="19" fill="#96D86C"/>
                            <rect x={indexValue} y="2"  width="60" height="35" fill="#4C8B54"/>
                    </>
                })}
                <g transform="translate(0,-90)">
                    <line x1={-15} y1={127.5} x2={470} y2={127.5} stroke="#4DA679" strokeDasharray={5}/>
                    <line x1={-15} y1={180} x2={470} y2={180} stroke="#479FF8" strokeDasharray={5}/>
                    <line x1={-15} y1={231} x2={470} y2={231} stroke="#DE546F" strokeDasharray={5}/>
                </g>
                {props.data.map((elem, index) => {
                    console.log(props.data)
                    const indexValue = 99+(80*index)
                    const value = elem < props.pointsArray[props.pointsArray.length-1] ? props.pointsArray[props.pointsArray.length-1] : elem
                    return (
                        <>
                            <svg x={indexValue} y={5+((props.pointsArray[0]-value)*3)}>
                                <path 
                                    fillRule="evenodd" 
                                    clipRule="evenodd"
                                    fill="white"
                                    d="M 9.72558 1.2 
                                       C 10.2585 0.827412 10.9495 0.525002 11.7438 0.316718
                                       C 12.5382 0.108435 13.4142 0 14.3023 0
                                       H 35.2791
                                       C 36.7964 0 38.2515 0.316071 39.3244 0.87868
                                       C 40.3973 1.44129 41 2.20435 41 3
                                       V 15
                                       C 41 15.7956 40.3973 16.5587 39.3244 17.1213
                                       C 38.2515 17.6839 36.7964 18 35.2791 18
                                       H 14.3023C13.4142 18 12.5382 17.8916 11.7438 17.6833 
                                       C 10.9495 17.475 10.2585 17.1726 9.72558 16.8
                                       L 1.14419 10.8
                                       C 0.401482 10.2807 0 9.64911 0 9
                                       C 0 8.35089 0.401483 7.71929 1.14419 7.2
                                       L 9.72558 1.2
                                       Z" 
                                />
                            </svg>  
                            <text x={indexValue+13} y={18+((props.pointsArray[0]-value)*3)} fontSize={11}>{value}</text>
                        </>
                    )
                })}
            </g>
        </svg>
    )

export default MockCambridge;