//* External imports
import React, { useState, useEffect } from "react";
import styled from "styled-components"
import parse from "html-react-parser";
import axios from "axios";

//Types imports
import { CompletedExercise } from "../../types/UserTypes";
import { Answer, ExerciseType, CustomQuestionSize, QuestionsType } from "../../types/GeneralTypes";

//? Internal Imports
//? JSX Components
import TaskExam from "../taskExam";
import TitleComponent from "../TitleComponent";
import { Desktop } from "../../responsive/mediaQueriesComponents";
//? Hooks
import { useCurrentPath } from "../../hooks/routes/useCurrentPath";
//? Methods
import { isMobile } from "../../responsive/mediaQueriesStates";
//? Styled-Components
import { WhiteCard } from "../../styles/WhiteCard";
import { Button } from "../../styles/buttons/ButtonRound";

//! Images
import crossImg from "../../assets/others/taskReport/cross.png"
import checkImg from "../../assets/others/taskReport/check.png"
import spBubble from "../../assets/others/taskReport/spBubble.png"
import eyeIcon from "../../assets/icons/eye-regular.svg"
import cross from "../../assets/icons/cross.svg"

import { useTranslation } from "react-i18next";
import { globalErrorHandler } from "../../axios/globalErrorHandler";
import LoaderNoOverlay from "../LoaderNoOverlay";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type LocalAnswer = {
    results: Array<{
        questions: QuestionsType;
        answers: Array<{
            text: string,
            userResult: boolean | string,
            type: string,
            correct: boolean | undefined
        }>
    }>
}

type CorrectAnswer = {
    texto: string,
    correcta_rellenar: string
}

type AxiosCorrectAnswers = {
    results: Array<CorrectAnswer>
}

type Props = {
    completedExercise: CompletedExercise;
    id_user: number;
    prevSolution?: boolean[];
    inputValue?: (boolean[] | string)[];
    questions?: CustomQuestionSize;
    answers?: Answer[][];
    goBack: boolean;
    setGoBack: React.Dispatch<React.SetStateAction<boolean>>;
    setToExam: React.Dispatch<React.SetStateAction<boolean>>;
    setStudentExe?: React.Dispatch<React.SetStateAction<CompletedExercise[] | undefined>>
}

const defaultExercise: ExerciseType = {
    id_exercise: 0,
    statement: "",
    content: "",
    exercise_type: "",
    order: 0,
    max_words: 0,
    min_words: 0,
    task_part: 0,
    task_type: 0
}

//! NEED REFACTOR
/** Single exercise Report - Listening & Reading  */
const TaskReport = (props: Props): JSX.Element => {
    const isMobileState = isMobile()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation("translation", { keyPrefix: "rlReport" })
    const [exercise, setExercise] = useState<ExerciseType>(defaultExercise)
    const currentPath = useCurrentPath([{ path: "/landing" }])
    const [mainColor, setMainColor] = useState<string>("")
    const [questions, setQuestions] = useState<CustomQuestionSize | undefined>(props.questions ? { ...props.questions, questions: props.questions.questions.sort((a, b) => a.order - b.order) } : undefined)
    const [answers, setAnswers] = useState<Answer[][] | undefined>(props.answers ? props.answers : undefined)
    const [inputValue, setInputValue] = useState<(boolean[] | string)[]>(props.inputValue ? props.inputValue : [[true], "hola"])
    const [checkboxColors, setCheckboxColors] = useState<{ src: string | undefined, colors: string[] }[]>(new Array({ src: "", colors: new Array<string>(2).fill("transparent") }))
    const [returnToExam, setReturnToExam] = useState<boolean>(false)
    const [correctAnswers, setCorrectAnswers] = useState<(CorrectAnswer | undefined)[][]>([])
    const [openCorrectAnswer, setOpenCorrectAnswer] = useState<boolean[]>([])
    const [loadingAnswer, setLoadingAnswer] = useState<boolean[]>([])

    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get<ExerciseType>(`${process.env.REACT_APP_API_URL}/exercise/${props.completedExercise.id_exercise}`).then((response) => {
            const responseData = response.data
            const exerciseCompetency = responseData.exercise_type
            setExercise(responseData)
            setMainColor(
                exerciseCompetency === "Reading" ? "#0B8EAB"
                    : (exerciseCompetency === "Listening" ? "#A2D9E7"
                        : (exerciseCompetency === "Writing") ? "#F93E63 " : "#FFC152")
            )
            axios.get<LocalAnswer>(`${process.env.REACT_APP_API_URL}/completed/answers/${props.id_user}/${props.completedExercise.id_exercise}`).then((response) => {
                setQuestions({
                    answerSize: response.data.results.map((elem) => elem.answers.length),
                    questions: response.data.results.map((elem) => elem.questions).sort((a, b) => a.order - b.order)
                })
                setAnswers(response.data.results.map((elem) => elem.answers.map((elem2) => ({
                    text: elem2.text,
                    type: elem2.type
                }))))
                setInputValue(response.data.results.map((elem) => {
                    if (elem.answers.length === 1) {
                        return elem.answers[0].userResult as string
                    } else {
                        return elem.answers.map((elem2) => elem2.userResult) as boolean[]
                    }
                }))
                setOpenCorrectAnswer(new Array(response.data.results.length).fill(false))
                setLoadingAnswer(new Array(response.data.results.length).fill(false))
                setCorrectAnswers(response.data.results.map(() => [undefined]))
                setCheckboxColors(response.data.results.map((elem) => {
                    const findCorrect = elem.answers.find((elem2) => elem2.correct !== undefined)
                    return {
                        src: findCorrect ? (findCorrect.correct ? checkImg : crossImg) : "",
                        colors: new Array(elem.answers.length).fill("transparent")
                    }
                }))
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])

    const hadleOnClickCorrectAnswer = async (idQuestion: number, index: number) => {
        if (correctAnswers[index][0] === undefined) {
            setLoadingAnswer(loadingAnswer.map((elem, i) => i === index ? true : elem))
            const correctAnswer = await axios.get<AxiosCorrectAnswers>(`${process.env.REACT_APP_API_URL}/correctAnswers/${idQuestion}`)
            setCorrectAnswers(correctAnswers.map((elem, i) => i === index ? correctAnswer.data.results : elem))
            setOpenCorrectAnswer(openCorrectAnswer.map((_, i) => i === index ? true : false))
            setLoadingAnswer(loadingAnswer.map((elem, i) => i === index ? false : elem))
        } else {
            setOpenCorrectAnswer(openCorrectAnswer.map((_, i) => i === index ? true : false))
        }
    }

    return (
        <>
            {!returnToExam ?
                <MainDiv state={isMobileState}>
                    <Prueba>
                        <CompetencyTop>
                            <TitleComponent style={{ width: "50%" }} setGoBack={props.setGoBack} goBack={props.goBack} text={`${t(`${exercise.exercise_type}`)} ${t("Report")}`} />
                        </CompetencyTop>
                        <TopRightDiv>
                            <Desktop>
                                <ImgContainer>
                                    <span>{t("Keep the hard work!")}</span>
                                    <TopImg src={spBubble} alt="bubble" />
                                </ImgContainer>
                            </Desktop>
                        </TopRightDiv>
                    </Prueba>
                    <Wrapper state={isMobileState}>
                        <LeftDiv>
                            <ScoreBar>
                                <span>
                                    {t("SCORE")}:
                                    <InfoBarDiv padding="0px 20px 0px 20px">
                                        {(props.completedExercise.hits !== null && props.completedExercise.failed !== null) &&
                                            (props.completedExercise.failed === 0 ? `${props.completedExercise.hits}/${props.completedExercise.hits}` :
                                                props.completedExercise.hits === 0 ? `${0}/${(props.completedExercise.hits + props.completedExercise.failed)}` : `${props.completedExercise.hits}/${(props.completedExercise.hits + props.completedExercise.failed)}`)
                                        }
                                    </InfoBarDiv>
                                </span>
                                <InfoBarDiv padding="0px 10px 0px 10px">
                                    {props.completedExercise.score}
                                </InfoBarDiv>
                            </ScoreBar>
                            <ExamCard>
                                <div>
                                    {exercise.statement}
                                </div>
                                <DecorationLine />
                                <div>
                                    {parse(exercise.content)}
                                    {exercise.file && exercise.file.split(";").filter((elem) => elem.length > 0).map((elem) =>
                                        <audio controls controlsList="noplaybackrate nodownload" src={elem} />
                                    )}
                                </div>
                            </ExamCard>
                        </LeftDiv>
                        <RightDiv>

                            {questions && answers &&
                                <TasksContainers>
                                    {questions.questions.map((elem, i) => <TaskCardDiv key={i}>
                                        <InfoCardDiv color={mainColor}>
                                            <InfoCardTop>
                                                <strong>{elem.text}</strong>
                                                {loadingAnswer[i] ? <LoaderNoOverlay loading={loadingAnswer[i]}/> 
                                                    : <img src={eyeIcon} alt="a" style={{width: "20px", cursor: "pointer"}} onClick={() => hadleOnClickCorrectAnswer(elem.id_question, i)}/>}
                                                {correctAnswers[i] && correctAnswers[i][0] !== undefined && openCorrectAnswer[i] && <DisplayCorrect state={openCorrectAnswer[i]}>
                                                    <img src={cross} style={{cursor: "pointer"}} onClick={() => setOpenCorrectAnswer(new Array(questions.questions.length).fill(false))}/>
                                                    {correctAnswers[i].map((elem) => <div>{(elem?.correcta_rellenar && elem?.correcta_rellenar.length > 0) ? elem?.correcta_rellenar : answers[i][0].type === "img" ? <img style={{maxWidth: "100%"}} src={elem?.texto}/> : elem?.texto}</div>)}
                                                </DisplayCorrect>}
                                                {checkboxColors[i] && checkboxColors[i].src && <img src={checkboxColors[i].src} style={{width: "30px"}} />}
                                            </InfoCardTop>
                                            <fieldset style={{ border: "none" }}>
                                                {answers[i].map((elem2, j) => elem2.type === "fill" ?
                                                    <InfoCardInput type="text" defaultValue={inputValue[i] as string} />
                                                    :
                                                    <Label background={"transparent"}>
                                                        <LabelRadio color={mainColor}><CircularInputCheckbox type="radio" defaultChecked={inputValue[i][j] as boolean} disabled/></LabelRadio>
                                                        {elem2.type === "opt" ? elem2.text : (elem2.type === "img" ? <img src={elem2.text} alt={`img_${i}_${j}`} style={{maxWidth: "100%"}} /> : inputValue[i])}
                                                    </Label>
                                                )}
                                            </fieldset>
                                        </InfoCardDiv>
                                    </TaskCardDiv>)}
                                    <div style={{ marginLeft: '5%' }}>
                                        {props.setStudentExe && !currentPath ?
                                            <>
                                                {/**<NextButton background="white" onClick={questionCorrection}>Solve</NextButton> */}
                                                <NextButton background="#F93E63" color="white" onClick={() => setReturnToExam(true)}>{t("Try Again")}</NextButton>
                                                <NextButton background="transparent" onClick={() => props.setToExam(false)}>{t("Back")}</NextButton>
                                            </>
                                            :
                                            <NextButton background="#F93E63" color="white" onClick={() => props.setToExam(false)}>{t("Done")}</NextButton>
                                        }
                                    </div>
                                </TasksContainers>
                            }
                        </RightDiv>
                    </Wrapper>
                </MainDiv>
                :
                <>
                    {props.setStudentExe && exercise.exercise_type !== "Speaking" && exercise.exercise_type !== "Writing" &&
                        <TaskExam exercise={exercise} mainColor={mainColor} questions={questions} answers={answers} setToExam={props.setToExam} id_user={props.id_user} setStudentExe={props.setStudentExe} />
                    }
                </>
            }
        </>
    )
}

export default TaskReport

const MainDiv = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state ? "95%" : "80%"};
    max-width: 80%;
    background: #F5F5F5;
    padding-bottom: 5px;
`
const LeftDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`
const RightDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`
const InfoCardInput = styled.input`
    background: #eeeded;
    border: 1px solid transparent;
    height: 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: ${props => props.theme.basicBoxShadow};
    outline: none;
`
const Wrapper = styled.div<{ state: boolean }>`
    display: flex;
    flex-direction: ${props => props.state ? "column" : "row"};
    gap: ${props => props.state ? "20px" : "0px"};
    justify-content: space-between;
    width: 100%;
    height: 100%;
    ${LeftDiv} {
        width: ${props => props.state ? "100%" : "55%"};
    }
    ${RightDiv} {
        width: ${props => props.state ? "100%" : "40%"};
    }
`
const TopRightDiv = styled.div`
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    margin-top: 20px;
`
const Prueba = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`
const CompetencyTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: auto;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
`
const ScoreBar = styled(WhiteCard)`
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    border: 1px solid #F5F5F5;
    margin-bottom: 30px;
    padding: 5px;
    font-weight: 700;
    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 10%;
    }
`
const InfoBarDiv = styled.div<{ padding?: string }>`
    width: auto;
    border: 3px solid ${props => props.theme.langooBlue};
    border-radius: 4px;
    padding: ${props => props.padding ? props.padding : "0px 0px 0xp 0px"};
    margin-left: 5px;
    margin-right: 10%;
`

const ImgContainer = styled.div`
    width: fit-content;
    position: relative;
    span {
        color: ${props => props.theme.langooBlue};
        border-bottom: 3px solid ${props => props.theme.langooYellow};
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 2px;
        position: absolute;
        top: 20%;
        left: 12%;
        right: auto;
        bottom: auto;
    }
`

const TopImg = styled.img`
    display: flex;
    align-self: center;
`
const DecorationLine = styled.div`
    border-bottom: 1.5px solid #2122225a;
    width: 100%;
    margin: 3%;
    text-decoration: underline;
`
const NextButton = styled(Button) <{ background: string, color?: string }>`
    border: 1px solid ${props => props.background};
    background: ${props => props.disabled ? "white" : props.background};
    color: ${props => props.disabled ? props.background : (props.color ? props.color : props.theme.fontColor)};;
    width: 300px;
    height: 43px;
    font-size: 20px;
    border-radius: 8px;
    margin-top: 10px;
`
const ExamCard = styled(WhiteCard)`
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    min-height: fit-content;
    text-align: justify;
    padding: 3%;
    padding-left: 5%;
    padding-right: 5%;
    margin-right: 5%;
    img {
        max-width: 100%;
    }
    p {
        width: 100%;
        word-break: break-word;
        word-wrap: normal;
        img {
            width: 100%;
        }
        span {
            width: 100%;
        }
        hr {
            background: transparent;
        }
        textarea {
            resize: none;
            width: 100%;
            min-height: 200px;
        }
    }
`
const TasksContainers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
`
const TaskCardDiv = styled(WhiteCard)`
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    border-radius: 20px;
    border: white;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding-right: 5%;
    padding-top: 3%;
    padding-left: 5%;
    padding-bottom: 3%;
`
const InfoCardTop = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    strong {
        margin-bottom: 3%;
        margin-left: 10px;
        width: 75%;
    }
`
const DisplayCorrect = styled.div<{state: boolean}>`
    display: ${props => props.state ? "block" : "none"};
    position: absolute;
    border: 0.5px solid #B6B6B6;
    border-radius: 8px;
    background: white;
    width: 100%;
    height: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 5px;
`
const InfoCardDiv = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding-left: 20px;
    border-left: 10px solid ${props => props.color};
    strong {
        margin-bottom: 3%;
    }
`
const CircularInputCheckbox = styled.input`
    border-radius: 999px;
`
const LabelRadio = styled.label<{ color: string }>`
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    height: 50%;
    margin-top: 1%;
    input[type=radio] {
        -webkit-appearance: none;
        appearance: none;
        background: #FFFFFF;
        border: 0.15em solid #AAAAAA;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin: 0;
        width: 1.15em;
        height: 1.15em;
        display: grid;
        place-content: center;
        transform: translateY(-0.075em);
        ::before {
            content: "";
            width: 0.45em;
            height: 0.45em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1.40em 1em #FFFFFF;
            background: ${props => props.color};
            border: 0.15em solid ${props => props.color};
        }
        :checked::before{
            transform: scale(1.40);
        }
    }
`
const Label = styled.label<{ background: string }>`
    display:flex;
    flex-direction: row;
    background: ${props => props.background};
    margin-bottom: 10px;
    padding: 2px;
`