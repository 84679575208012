type InputType = {
    reading: string | number | {[key: string]: any},
    speaking: string | number | {[key: string]: any},
    writing: string | number | {[key: string]: any},
    listening: string | number | {[key: string]: any}
}

/** 
 * Take a object and change the order depending of certification & level 
 * @param {InputType} object - Object with reading, speaking, writing and listening keys storing info of each competency
 * @param {string} certification - User certification name
 * @param {string} level - User certification level name
 * */
export const objectToArrayByCertificactionAndLevel = (object: InputType, certification: string, level: string) => {
    switch(certification) {
        case "DELE":
            switch (level){
                case "A1": 
                    return [
                        object.reading,
                        object.speaking,
                        object.writing,
                        object.listening
                    ]
                    
                default:
                    return [
                        object.reading,
                        object.listening,
                        object.writing,
                        object.speaking
                    ]
                    
            }
        case "CAMBRIDGE":
                switch(level){
                    case "A2":
                        return [
                            object.reading,
                            object.speaking,
                            object.listening
                        ]
                        
                    case "B2":
                        return [
                            object.reading,
                            object.listening,
                            object.writing,
                            object.speaking
                        ]
                        
                    case "C1":
                        return [
                            object.reading,
                            object.writing,
                            object.listening,
                            object.speaking
                        ]
                        
                    default:
                        return [
                            object.reading,
                            object.listening,
                            object.writing,
                            object.speaking
                        ]
                        
                }
                
        default:
            console.log("Error")
            return [
                object.reading,
                object.listening,
                object.writing,
                object.speaking
            ]
    }
}