//* External Imports
import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
//* Hooks
import { useNavigate, useParams } from "react-router-dom";

//? Internal Imports
//? Styled-Components
import { ButtonTransition } from "../styles/buttons/ButtonTransition";

//! Images
import mainlogo1 from "../assets/mainlogo/Logo_Langoo_01.png"

/** Password reset component. */
const ResetPassword = () => {
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const { token } = useParams()
    const navegate = useNavigate()
    const onReset = async () => {
        if (password.length > 0 && confirmPassword.length > 0 && password === confirmPassword) {
            await axios.post(`${process.env.REACT_APP_API_URL}/resetPassword/${token}`, {
                newPassword: confirmPassword
            })
            navegate("/")
        }
    }
    return (
        <MainDiv>
            <Img src={mainlogo1}/>
            <InputWrapper>
                <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <Input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                <Button onClick={onReset}>Reset</Button>
            </InputWrapper>
        </MainDiv>
    )
}

const MainDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
`
const Img = styled.img`
    width: 10%;
    padding: 2px;
`
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 25%;
    height: 100%;
`
const Input = styled.input`
    width: 100%;
    min-height: 35px;
    height: 35px;
    border: 1px solid black;
    font-size: 17px;
    border-radius: 5px;
    padding-left: 10px;
`
const Button = styled(ButtonTransition)`
    background: ${props => props.theme.langooRed};
    border: 1px solid ${props => props.theme.langooRed};
    color: white;
    width: auto;
    &:hover{
        background: white;
        color: ${props => props.theme.langooRed}
    }
`

export default ResetPassword