//* External Imports
import React from "react"
//* Hooks
import { useTranslation } from "react-i18next"

// Type Imports
import { MediaType } from "../../mediaPermissions/types/mediaTypes"

const ExplanationDialog = (props: {media?: MediaType}) => {
    const { t } = useTranslation()
    const mediaTranslation = t(props.media ? props.media : "camera and microphone")
    return (
        <div>
            <div>{t("Allow App to use your ")}{mediaTranslation}</div>
            <div>{t("App needs access to your ")}{mediaTranslation} {t("to take the exam")}</div>
        </div>

    )
}
export default ExplanationDialog