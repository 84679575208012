//* External imports
import React, { useEffect, useState } from "react";
import styled from "styled-components";
//* Hooks
import { useTranslation } from "react-i18next";

// Type Imports
import { UseRecorder } from "../hooks/VoiceRecorder/types/recorder";

//? Internal Imports
//? JSX Components
import CheckAudioPermissions from "./mediaPermissions/CheckAudioPermissions";
//? Hooks
import useRecorder from "../hooks/VoiceRecorder/use-recorder";
//? Styled-components
import { Button } from "../styles/buttons/Button";
import { ButtonCircle } from "../styles/buttons/ButtonCircle";

//! Images
import microphoneIconWhite from "../assets/icons/microphoneWhite.svg"
import MediaPlayer from "./mediaPlayer/MediaPlayer";

type Props = {
    setModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const AudioButton = (props: Props) => {
    const [userPermissions, setUserPermissions] = useState<boolean>(false)
    const [buttonActive, setButtonActive] = useState<boolean>(false)
    const [microphoneNotActive, setMicrophoneNotActive] = useState<boolean>(false)
    const {t} = useTranslation()
    const { recorderState, ...handlers }: UseRecorder = useRecorder();
    const { startRecording, saveRecording, cancelRecording } = handlers;
    const { recordingSeconds } = recorderState;
    const { audio } = recorderState
    const numSeconds = 5

    const checkUserPermissions = () => {
        const reqPermissions = navigator.mediaDevices.getUserMedia({ video: false, audio: true })
        reqPermissions.then(() => {
            setUserPermissions(true)
        })
    }

    useEffect(() => {
        checkUserPermissions()
    }, [])

    useEffect(() => {
        if (recordingSeconds === numSeconds) {
            saveRecording()
            setMicrophoneNotActive(true)
        }
    }, [recordingSeconds])

    const onAudioClick = () => {
        cancelRecording()
        startRecording()
        setButtonActive(false)
    }
    const handleContinue = () => {
        if (props.setModal) {
            props.setModal(false)
        }
    }
    return (
        <>
            {!userPermissions ?
                <CheckAudioPermissions setUserPermission={setUserPermissions} />
                :
                <MainDiv state={audio !== null}>
                    <Title> {audio === null ? t("Click the button and speak until time counter is at 0.") : t("Please listen to the audio. If you hear yourself press “Continue”. If not, check if your device has a microphone and try again.")}  </Title>
                    <AudioTimer>
                        <PulseAudioButton onClick={onAudioClick} state={recordingSeconds > 0} disabled={recordingSeconds > 0 || microphoneNotActive} state2={microphoneNotActive}>
                            <img src={ microphoneIconWhite } />
                        </PulseAudioButton>
                        <div>{`00:00:0${numSeconds - recordingSeconds < numSeconds ? `${numSeconds - recordingSeconds}` : numSeconds - recordingSeconds}`}</div>
                    </AudioTimer>
                    {audio && <AudioTimer>
                        <MediaPlayer src={audio} onFinish={() => [setButtonActive(true), setMicrophoneNotActive(false)]}/>
                        <Button onClick={handleContinue} disabled={buttonActive === false} state={buttonActive === false}>Continue</Button>
                    </AudioTimer>
                    }
                </MainDiv>
            }
        </>
    )
}

export default AudioButton

const MainDiv = styled.div<{state: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${props => props.state ? "space-between" : "normal"};
    gap: 50px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
`
const Title = styled.span`
    font-size: 20px;
    font-weight: 700;
    color: ${props => props.theme.langooBlue};
`
const AudioTimer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    div {
        font-size: 20px;
        font-weight: 700;
        color: ${props => props.theme.langooBlue};
    }
`
const AudioButtonC = styled(ButtonCircle)<{state: boolean}>`
    background: ${props => props.state ? props.theme.buttonDisabled : props.theme.langooBlue};
`
const PulseAudioButton = styled(ButtonCircle)<{state: boolean, state2: boolean}>`
    background: ${props => props.state2 ? props.theme.buttonDisabled : props.theme.langooBlue};
    animation: ${props => props.state ? "pulse 1.5s infinite" : "none"};
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0px rgba(11, 142, 171, 0.2);
        }
        100% {
            box-shadow: 0 0 0 20px rgba(11, 142, 171, 0)
        }
    }
    
`