//* External Imports
import React from "react"

// Type Imports
import { DialogType } from "../types/mediaTypes";

const checkForExplanationDialog = (mediaRef: (React.MutableRefObject<any> | React.MutableRefObject<any>[]), dialogRef: React.MutableRefObject<any>) => {
    if(Array.isArray(mediaRef)){
        if(mediaRef.find((elem) => !elem.current) && dialogRef.current === null){
            return DialogType.explanation
        }
    }else{
        if(!mediaRef.current && dialogRef.current === null){
            return DialogType.explanation
        }
    }
};

export default checkForExplanationDialog