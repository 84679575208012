//* External Imports
import React, { useEffect, useRef, useState } from "react"
import Bowser from "bowser"

// Type Imports
import { DialogType } from "../../mediaPermissions/types/mediaTypes"

//? Internal Imports
//? JSX Components
import ExplanationDialog from "./ExplanationDialog"
import TryAgain from "./TryAgain"
import SystemDeniedDialog from "./SystemDeniedDialog"
import UserDeniedDialog from "./UserDeniedDialog"
import TrackErrorDialog from "./TrackErrorDialog"

//? Methods
import { checkMediaPermissions } from "../../mediaPermissions/handlers/checkMediaPermissions"
import checkForExplanationDialog from "../../mediaPermissions/handlers/checkExplanationDialog"

//! Images

type Props = {
    setUserPermission?: React.Dispatch<React.SetStateAction<boolean>>
}

const browser = Bowser.getParser(window.navigator.userAgent);

const CheckAudioPermissions = (props: Props) => {
    const [showDialog, setShowDialog] = useState<DialogType | null>(null)
    const [audioAllowed, setAudioAllowed] = useState<boolean>(false)
    const showDialogRef = useRef<any>(showDialog)
    showDialogRef.current = showDialog;
	const audioAllowedRef = useRef<any>(audioAllowed);
	audioAllowedRef.current = audioAllowed;
    useEffect(() => {
        checkMediaPermissions("audio").then((response) => {
            if(response){
                if(typeof response === "boolean"){
                    setAudioAllowed(true)
                    if(props.setUserPermission){
                        props.setUserPermission(true)
                    }
                    setShowDialog(null)
                }else{
                    setShowDialog(response)
                }
            }
        })
    }, [])

    setTimeout(() => {
        const result = checkForExplanationDialog(audioAllowedRef, showDialogRef)
        if(result) {
            setShowDialog(DialogType.explanation)
        }
    }, 500)

    const onClickTryAgain = () => {
        if (browser.getBrowserName() === 'Safari') {
            // If on Safari, rechecking permissions results in glitches so just refresh the page
            window.location.reload();
        } else {
            checkMediaPermissions("audio").then((response) => {
                if(response){
                    if(typeof response === "boolean"){
                        setAudioAllowed(true)
                        if(props.setUserPermission){
                            props.setUserPermission(true)
                        }
                        setShowDialog(null)
                    }else{
                        setShowDialog(response)
                    }
                }
            });
        }
    }

    const dialogContent = () => {
        switch(showDialog) {
            case DialogType.explanation:
                return <div>
                    <ExplanationDialog media="audio"/>
                    <TryAgain onClick={onClickTryAgain}/>
                </div>
            case DialogType.systemDenied:
                return <div>
                    <SystemDeniedDialog/>
                    <TryAgain onClick={onClickTryAgain}/>
                </div>
            case DialogType.userDenied:
                return <div style={{width:"100%"}}>
                    <UserDeniedDialog media="audio"/>
                    <TryAgain onClick={onClickTryAgain}/>
                </div>
            case DialogType.trackError:
                return <div>
                    <TrackErrorDialog/>
                    <TryAgain onClick={onClickTryAgain}/>
                </div>
            default: 
                return <div>
                    <UserDeniedDialog media="audio"/>
                    <TryAgain onClick={onClickTryAgain}/>
                </div>
        }
    }
    return (
        <div>
            {showDialog && dialogContent()}
        </div>
    )
    
}

export default CheckAudioPermissions