//* External Importd
import React from "react"
import { WritableDraft } from "immer/dist/internal"
import { PayloadAction } from "@reduxjs/toolkit"

// Type Imports
import { LogInPayload, StorageInfo } from "./types/ReduxTypes"

const reducers = {
    logIn: (state: WritableDraft<StorageInfo>, action: PayloadAction<LogInPayload>) => {
        state.mainStates = [...action.payload.mainStates]
        state.authToken = action.payload.authToken ? action.payload.authToken : undefined
        state.logInfo = {...action.payload.logInfo}
    },
    logOut: (state: WritableDraft<StorageInfo>) => {
        state.mainStates = []
        state.logInfo = {
            stayLogged: false,
            isLogged: false
        }
    },
    setMainStates: (state: WritableDraft<StorageInfo>, action: PayloadAction<boolean[]>) => {
        state.mainStates = action.payload

    },
    setErrorStatus: (state: WritableDraft<StorageInfo>, action: PayloadAction<boolean>) => {
        state.loadError = action.payload
    },
    sendError: (state: WritableDraft<StorageInfo>) => {
        state.loadError = false
        state.sendError = true
    },
    closeError: (state: WritableDraft<StorageInfo>) => {
        state.loadError = false
        state.sendError = false
    }
}

export default reducers