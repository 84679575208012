//* External imports
import React from "react";
import {VictoryAxis, VictoryBar, VictoryChart} from "victory";

//? Bar chart template. Simple with only one bar for each x-axis value.

type Props = {
    data: Array<number>
    categories: Array<string>
    colors?: Array<string>
}

const SimpleBarChart = (props: Props): JSX.Element => (
        <svg viewBox="0 0 600 300" style={{fontFamily: "'Familjen Grotesk', sans-serif"}}>
            <VictoryChart
                standalone={false}
                height={300}
                width={600}
                domainPadding={{x: 30}}
            >
                <VictoryAxis
                    dependentAxis
                    domain={[10, 100]}
                    tickValues={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    tickFormat={(elem) => `${elem}%`}
                />
                <VictoryAxis
                    domain={[1,4]}
                    style={{
                        tickLabels: {fill: "transparent"}
                    }}                
                />
                <VictoryBar
                    data={[
                        {x: 1, y: props.data[0], fill: props.colors ? props.colors[0] : "#0B8EAB"},
                        {x: 2, y: props.data[1], fill: props.colors ? props.colors[1] : "#FFC152"},
                        {x: 3, y: props.data[2], fill: props.colors ? props.colors[2] : "#F93E63"},
                        {x: 4, y: props.data[3], fill: props.colors ? props.colors[3] : "#A2D9E7"}
                    ]}
                    style={{
                        data: {
                            fill: ({datum}) => datum.fill
                        }
                    }}
                />
            </VictoryChart>
        </svg>
    )

export default SimpleBarChart