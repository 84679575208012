//* External Imports
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
//* Hooks
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Types Imports
import { UserType } from "../types/UserTypes";
import { CompletedExercise } from "../types/UserTypes";
import { ExercisesType } from "../types/GeneralTypes";

//? Internal Imports. 
//? JSX Components
import LineChart from "../components/charts/line/LineChart";
import DonutCharSimple from "../components/charts/pieCharts/DonutChartSimple";
import CompetencyReport from "../components/reports/competencyReport";
import MultiLineChart from "../components/charts/custom/multiLineChart";
//? Hooks
import { setMainStates } from "../redux/hook/useReducer";
//? Methods
import { isMobile, isTablet } from "../responsive/mediaQueriesStates";
//? Utils
import { changeBooleanArrayValueByIndex } from "../utils/changeBooleanArrayValueByIndex";
//? Styled-Componentes
import { SimpleTitle } from "../styles/SimpleTitle";
import { WhiteCard } from "../styles/WhiteCard";

//! Images
import arrowBlack from "../assets/icons/arrowBlack.png"
import Loader from "../components/loader";
import { objectToArrayByCertificactionAndLevel } from "../utils/objectToArrayByCertificationAndLevel";
import { sortedCompetencies } from "../utils/sortedCompetencies";
import { sortedCompetencyColors } from "../utils/sortedCompetencyColors";
import { globalErrorHandler } from "../axios/globalErrorHandler";
import { useNavigate } from "react-router-dom";

//? Main Dashboard component. Main logic.

type Props = {
    user: UserType;
    studenExercise: CompletedExercise[];
    exercises: ExercisesType;
    toCompetencyR: boolean
    stateScreen: boolean[]
    competencyType?: string
    buttonColorScale?: Array<string>
    resetDashboard?: boolean
}

type ChartInfo = {
    reading: number,
    speaking: number,
    listening: number,
    writing: number
}

type LineChartInfo = {
    multiLine: Array<Array<{
        x: number,
        y: number
    }>>
    general: Array<{
        x: number,
        y: number
    }>
}

type ExerciseInfo = {
    totalCompleted: number,
    total: number
}

const defaultChartInfo: ChartInfo = {
    reading: 0,
    speaking: 0,
    listening: 0,
    writing: 0
}

const Dashboard = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation("translation", { keyPrefix: "dashboard" })
    //Screen States
    //Aux state vairables
    const isMobileState = isMobile()
    const isTabletState = isTablet()
    const [competencyHours, setCompetencyHours] = useState<ChartInfo>(defaultChartInfo)
    const [competencyReportsData, setCompetencyReportsData] = useState<{
        competencyType: string,
        buttonColorScale: string[],
        chartColorScale: string[],
        transparencyScale: string[]
    }>({ competencyType: props.competencyType ? props.competencyType : "", buttonColorScale: props.buttonColorScale ? props.buttonColorScale : [], chartColorScale: [], transparencyScale: [] })
    //Go back variable
    const [goBack, setGoBack] = useState<boolean>(false)
    const [chartInfo, setChartInfo] = useState<ChartInfo>(defaultChartInfo)
    const [lineChartInfo, setLineChartInfo] = useState<LineChartInfo>({
        multiLine: [[{ x: 0, y: 0 }]],
        general: [{ x: 0, y: 0 }]
    })
    const [totalMeanPercent, setTotalMeanPercent] = useState<number>(0)
    const [toCompetencyReport, setToCompetencyReport] = useState<boolean>(false)
    const [exerciseInfo, setExerciseInfo] = useState<ExerciseInfo>({ totalCompleted: props.studenExercise.length, total: props.exercises.flat(1).length })
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        dispatch(setMainStates(changeBooleanArrayValueByIndex(props.stateScreen, 0)))
        axios.get(`${process.env.REACT_APP_API_URL}/donutSimple/${props.user.id}`).then((response) => {
            setChartInfo(response.data)
            axios.get(`${process.env.REACT_APP_API_URL}/globalSuccess/${props.user.id}`).then((response) => {
                setLineChartInfo(response.data)
                axios.get<{ mean: number }>(`${process.env.REACT_APP_API_URL}/meanPercent/${props.user.id}`).then((response) => {
                    setTotalMeanPercent(response.data.mean)
                    axios.get(`${process.env.REACT_APP_API_URL}/numCompleted/${props.user.id}`).then((response) => {
                        setExerciseInfo({ ...exerciseInfo, totalCompleted: response.data.total })
                        axios.get<ChartInfo>(`${process.env.REACT_APP_API_URL}/numHours/${props.user.id}`).then((response) => {
                            setCompetencyHours(response.data)
                            setLoading(false)
                        }).catch(() => globalErrorHandler(dispatch, navigate))
                    }).catch(() => globalErrorHandler(dispatch, navigate))
                }).catch(() => globalErrorHandler(dispatch, navigate))
            }).catch(() => globalErrorHandler(dispatch, navigate))
        }).catch(() => globalErrorHandler(dispatch, navigate))
    }, [])

    useEffect(() => {
        setToCompetencyReport(false)
    }, [props.resetDashboard, goBack])

    /** Change states necesary to open competency report */
    const onButtonImg = (competencyType: string, chartColorScale: string[], buttonColorScale: string[], transparencyScale: string[]) => {
        setToCompetencyReport(true)
        setCompetencyReportsData({
            competencyType: competencyType,
            chartColorScale: chartColorScale,
            buttonColorScale: buttonColorScale,
            transparencyScale: transparencyScale
        })
    }

    return (
        <MainDiv state={toCompetencyReport} state2={isMobileState}>
            {loading && <Loader loading={loading} />}
            {!toCompetencyReport ?
                <>
                    <UpperTextDiv>
                        <SimpleTitle>{t("Global Progress")}</SimpleTitle>
                        <LastConnectionDiv>{t("See your overall progress on Langoo")}</LastConnectionDiv>
                    </UpperTextDiv>
                    <MiddleSpan>
                        <InfoCardG style={{ border: "transparent" }}>
                            <h3>{t("Average score")}</h3>
                            <h4>{`${Math.round(totalMeanPercent)}%`}</h4>
                        </InfoCardG>
                        <InfoCardG>
                            <h3>{t("Task Completed")}</h3>
                            <h4>{`${exerciseInfo.totalCompleted}/${exerciseInfo.total}`}</h4>
                        </InfoCardG>
                    </MiddleSpan>
                    <GeneralMainDiv state={isMobileState} state2={isTabletState}>
                        {
                            (objectToArrayByCertificactionAndLevel({
                                reading: {
                                    color: "#0B8EAB",
                                    info: chartInfo.reading,
                                    name: "Reading",
                                    numHours: competencyHours.reading,
                                    buttonColorScale: ["#EAF6FF", "#C8E7FF", "#A0D6FF", "#73BFF9", "#479FF8", "#3274B5", "#1E4C7C", "#142E49"],
                                    transparencySclae: ["#0B8EAB66", "#0B8EAB4D ", "#0B8EAB33", "#0B8EAB1A"]
                                },
                                speaking: {
                                    color: "#FFC152",
                                    info: chartInfo.speaking,
                                    name: "Speaking",
                                    numHours: competencyHours.speaking,
                                    buttonColorScale: ["#FFEFDF", "#FDDAB1", "#F7C787", "#ECB561", "#DDA43F", "#945A21", "#6C3A15", "#421E0B"],
                                    transparencySclae: ["#FFC15266", "#FFC1524D", "#FFC15233", "#FFC1521A"]
                                },
                                writing: {
                                    color: "#F93E63",
                                    info: chartInfo.writing,
                                    name: "Writing",
                                    numHours: competencyHours.writing,
                                    buttonColorScale: ["#F3B9DC", "#E89BC1", "#D97FA3", "#C76884", "#984253", "#7C3341", "#5F2530", "#41171F"],
                                    transparencySclae: ["#F1899D66", "#F1899D4D ", "#F1899D33", "#F1899D1A"]
                                },
                                listening: {
                                    color: "#A2D9E7",
                                    info: chartInfo.listening,
                                    name: "Listening",
                                    numHours: competencyHours.listening,
                                    buttonColorScale: ["#E7FFF3", "#C7FFE3", "#7EE7B2", "#5ECC95", "#57A980", "#377E5B", "#25553D", "#142D20"],
                                    transparencySclae: ["#A2D9E766", "#A2D9E74D ", "#A2D9E733", "#A2D9E71A"]
                                }
                            }, props.user.certification, props.user.certification_level) as { [key: string]: any }).map((elem: any, i: number) =>
                                <CardChar key={i}>
                                    <DonutCharSimple
                                        colorScale={["#D9D9D9", elem.color]}
                                        data={[
                                            { x: "Part#1", y: (100 - elem.info) },
                                            { x: "Part#2", y: elem.info },
                                        ]}
                                        textCompetency={t(elem.name) ?? elem.name}
                                        text2={t("Time spent") ?? "Time spent"}
                                        text3={`${elem.numHours} ${t("hours")}`}
                                    />
                                    <ButtonImg state={isMobileState} onClick={() => onButtonImg(
                                        t(elem.name),
                                        ["#D9D9D9", elem.color],
                                        elem.buttonColorScale,
                                        elem.transparencyScale
                                    )}
                                    >
                                        <img src={arrowBlack} alt="arrow" />
                                    </ButtonImg>
                                </CardChar>
                            )
                        }
                    </GeneralMainDiv>
                    <BigCharContainer>
                        <MultiLineChart
                            data={lineChartInfo.multiLine}
                            textArr={["Show all", ...sortedCompetencies(props.user.certification, props.user.certification_level).map((elem) => t(elem))]}
                            colorScale={["#454545", ...sortedCompetencyColors(props.user.certification, props.user.certification_level)]}
                        />
                    </BigCharContainer>
                    <BigCharContainer>
                        <BigCharCard>
                            <LineChart
                                data={lineChartInfo.general}
                                color={"#0B8EAB"}
                                timeSelector={true}
                            />
                        </BigCharCard>
                    </BigCharContainer>
                </>
                :
                <CompetencyReport competency={competencyReportsData.competencyType} studentExe={props.studenExercise} exercises={props.exercises}
                    colorScale={competencyReportsData.chartColorScale} buttonColorScale={competencyReportsData.buttonColorScale}
                    standardColor={competencyReportsData.chartColorScale[1]} setGoBack={setGoBack} goBack={goBack} transparencyScale={competencyReportsData.transparencyScale}
                    id_user={props.user.id}
                />
            }
        </MainDiv>
    )
}

export default Dashboard;

const MainDiv = styled.div<{ state: boolean, state2: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${props => props.state && props.state === true ? "100%" : props.state2 ? "95%" : "80%"};
    background: #F5F5F5;
    padding-bottom: 30px;
    gap: 40px;
`
//General & Competency Report
const CardChar = styled(WhiteCard)`
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 20px;
`
const GeneralMainDiv = styled.div<{ state: boolean, state2: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: ${props => props.state || props.state2 ? "wrap" : "normal"};
    align-self: flex-start;
    width: 100%;
    height: 100%;
    gap: ${props => props.state ? "5px" : "20px"};
    ${CardChar} {
        width: ${props => props.state ? "37%" : props.state2 ? "39%" : "20%"};
    }
`

//General Tab
const LastConnectionDiv = styled.div`
    font-size: 18px;
    font-weight: 700;
`
const MiddleSpan = styled(WhiteCard)`
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 25%;
    padding-top: 10px;
    padding-bottom: 10px;
`
//Global Progress
const InfoCardG = styled.div`
    width: 100%;
    border-left: 0.5px solid #D3CFCF;
    h3 {
        color: ${props => props.theme.langooBlue};
    }
`
const BigCharContainer = styled(WhiteCard)`
    width: 100%;
    height: 30%;
`
const BigCharCard = styled.div`
    width: 100%;
    height: 100%;
`
const ButtonImg = styled.button<{state: boolean}>`
    align-self: flex-end;
    margin-right: 9%;
    margin-bottom: 5%;
    background: transparent;
    border: transparent;
    img {
        width: ${props => props.state ? "10px" : "auto"};
    }
`
const UpperTextDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    margin-bottom: 0%;
    h4 {
        color: ${props => props.theme.langooBlue}
    }
`