//* External imports
import React, { useEffect, useState } from "react";
import { VictoryBar, VictoryChart, VictoryLabel, VictoryStack } from "victory";

type Props = {
    color: string;
    data: Array<{
        x: number,
        y: number
    }>
    textColor?: string;
    categories?: {
        x: Array<string>
        y: Array<string>
    }
    categoriesX: Array<string>
    domainPadding?: {
        x?: number
        y?: number
    } | number
}


const StackBarLabelResponsiveChart = (props: Props) => {
    const categories = props.categories ? props.categories : {
        x: props.categoriesX.map((elem) => elem.length >= 17 ? elem.split(" ").map((elem2, index, array) => ((index + 1 / 2) === (array.length / 2) ? `${elem2}\n` : elem2)).join(" ") : elem),
        y: new Array(25).fill(1).map((_, index) => `${index + 1}`)
    }
    const [width, setWidth] = useState<number>(window.innerWidth)
    const updateWidth = (ev: any) => {
        setWidth(ev.target.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', updateWidth)
        return () => {
            window.removeEventListener('resize', updateWidth)
        }
    }, [])

    return (
        <svg viewBox={`0 0 ${width} 550`}>
            <VictoryChart
                categories={props.categories ? props.categories : categories}
                standalone={false}
                domain={{ x: [1, props.categoriesX.length], y: [1, 25] }}
                domainPadding={{ x: 25 }}
                width={width}
                height={525}
            >
                <VictoryStack
                    colorScale={["#D9D9D9", props.color]}
                >
                    <VictoryBar //colored data
                        barWidth={40}
                        padding={{ top: 20, left: 200, right: 0 }}
                        style={{
                            data: {
                                fill: ({ datum }) => datum.fill
                            },
                            labels: {
                                fill: props.textColor ? props.textColor : "white"
                            }
                        }}
                        labels={({ datum }) => datum.y + 'pts'}
                        labelComponent={<VictoryLabel dy={20} dx={0} angle={0} />}
                        data={
                            props.data.map((elem) => (
                                { x: elem.x, y: elem.y, fill: props.color }
                            ))
                        }
                    />
                    <VictoryBar //left to end bar
                        barWidth={40}
                        style={{
                            data: {
                                fill: ({ datum }) => datum.fill
                            }
                        }}
                        data={
                            props.data.map((elem) => (
                                { x: elem.x, y: Number(categories.y[categories.y.length - 1]) - elem.y, fill: "#D9D9D9" }
                            ))
                        }
                    />
                </VictoryStack>
            </VictoryChart>
        </svg>
    )
}

export default StackBarLabelResponsiveChart
