import { minutesToSeconds, secondsToMinutes } from "date-fns";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("body");

type Props = {
    totalTimeCounted: number;
    maxTime: number;
}

const ModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        height: "auto",
        width: "auto",
        padding: "40px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        borderRadius: "40px 0px 40px 0px",
        maxWidth: "50%",
        gap: "10px"
    },
    overlay: {
        zIndex: 1000
    }
}

/** Warning Modal with time left 
 * @param {number} totalTimeCounted - Total time counted in seconds
 * @param {number} maxTime - Max time timer counter can go in minutes
 */
const LangooTimerWarning = (props: Props) => {
    const [modal, setModal] = useState<boolean>(false)
    const openModal = () => {
        setModal(true)
    }
    const closeModal = () => {
        setModal(false)
    }
    useEffect(() => {
        //if half the max time has passed or there are 10 minutes left open modal
        if((((minutesToSeconds(props.maxTime))/2) === props.totalTimeCounted) || ((secondsToMinutes(props.totalTimeCounted)) === (props.maxTime-10))){
            openModal()
        }
    }, [props.totalTimeCounted])

    return (
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={ModalStyle}
            contentLabel="Timer warning"
        >
            <div>
                {(((minutesToSeconds(props.maxTime))/2) === props.totalTimeCounted) && <div>Half of the time left</div>}
                {((secondsToMinutes(props.totalTimeCounted)) === (props.maxTime-10)) && <div>10 minutes left</div>}
            </div>
        </Modal>
    )
}

export default LangooTimerWarning;
